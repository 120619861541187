import React, {useState } from 'react';
import * as apiUser from '../../services/user';
import * as apiHistorique from '../../services/historique';
import * as apiSession from '../../services/session';
import moment from "moment";
import {
	Select,
} from "@chakra-ui/core";
import MaterialTable from 'material-table';
import {
	ButtonSearch,
	SearchContainer,
	ButtonText,
	ErrorContainer,
} from'./styles';
import {
	Grid,
	Card,
	CardContent,
	CardActionArea,
	Typography
} from '@material-ui/core';
import {
	ErrorText,
	CustomTextField,
	useStyles
} from'../Login/styles';
import DonutChart from '../../components/DonutChart';
import SelectDate from '../../components/SelectDate';
import {optionsDonut} from '../Dashboard/options';
const gameNames=["keep","scope","battle","power","goloto","gocash","golucky","pop","loco","land","go50","go1000","mojowin","viking","chess","newyear","jungle","fairground"];

function CardInfoMojo(props) {
    const classes = useStyles();

    return (
        <Card className={classes.card}>
            <CardActionArea>
                <CardContent className={classes.content}>
                    <Typography variant="body2" color="textSecondary" component="p">
                    {props.title}
                    </Typography>
                    <Typography gutterBottom variant="h5"  component="h2">
                    {props.value}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    )
}
function UserDetail(){
	// const history = useHistory();
	let tableRef = React.createRef();
	const classes = useStyles();
	const [username, setUsername] = useState("")
	const [userId, setUserId] = useState(null);
	const [error, setError] = useState(null);
	const [infoGame, setInfoGame] = useState(null);
	const [infoGamePlay, setInfoGamePlay] = useState(null);
	const [donutGame, setDonutGame] = useState(null);
	const [donutSession, setDonutSession] = useState(null);
	const [sessionUser, setSessionUser] = useState(null);
	const [nbAverageGameSession, setNbAverageGameSession] = useState(0);
	const [startDate, setStartDate] = useState(null);
  	const [endDate, setEndDate] = useState(null);
	const [gameName,setGameName] = useState("");
	const onSelectGame = (e) => setGameName(e.target.value);
	const [tableData] = useState({
		columns: [
			{
				title: 'Nom',
				field: 'name',
			},
			{
				title: 'Balles',
				field: 'credit',
			},
			{
				title: 'Euros',
				field: 'solde',
			},
			{
				title: 'Mise',
				field: 'mise',
			},
			{
				title: 'Joué',
				field: 'status',
				type: 'boolean'
			},
			{
				title: "Date joué",
				field: 'createdAt',
				render: rowData=><p>{convertDate(rowData.createdAt,"DD/MM/YYYY")}</p>
			},
		]
	});
	
	const [infoStatsUser,setInfoStatsUser] = useState(null);
    const getUserDetail = async ()=>{
    	if (username.length === 0) {
    		setError("Le champs username est vide.");
    		return;
    	} else if(!startDate || !endDate){
    		setError("Vous devez choisir une date de début et de fin.");
    		return;
    	} else if (startDate.getTime() >= endDate.getTime()){
    		setError("Vous devez choisir une date de début supérieur à celle de fin.");
    		return;
    	}
        try{
            const response = await apiUser.getUser(username);
            setUserId(response.user._id);
            if (tableRef.current) {
				tableRef.current.onQueryChange();
			}
			getSessionUserInfo(response.user._id);
			getUserSessionInfoGame(response.user._id);
			getInfoGameUser(response.user._id);
			getInfoStatsUser(response.user._id);
			setError(null);
        } catch (e){
            console.log(e)
            setError(e.message);
        }
    }

    const getInfoStatsUser = async (userId) => {
    	try {
    		let res = await apiUser.getInfoStatsUser(userId,startDate,endDate);
    		let newInfoStatsUser = {nbVideoPlay:0,nbGainsChest:0,parrainCredit:0};
    		newInfoStatsUser.parrainCredit = res.parrainCredit;
    		if (res.chestInfo) {
    			newInfoStatsUser.nbVideoPlay = res.chestInfo.nbVideoPlay;
    			newInfoStatsUser.nbGainsChest = res.chestInfo.creditVideo;
    		}
    		setInfoStatsUser(newInfoStatsUser);
    	} catch(e) {
    		console.log(e);
    	}
    }
    const getUserGameDetail = async (resolve,query)=>{
        try{
        	let sort={field:"createdAt",value:1};
        	if (query.orderBy) {
        		sort.field = query.orderBy.field
        		sort.value = (query.orderDirection === "asc")?1:-1;
        	}
        	let data = {userId:userId,start:startDate,end:endDate,gameNames:(gameName.length===0)?gameNames:[gameName],nbPage:query.page,pageSize:query.pageSize,fieldSort:sort.field,valueSort:sort.value};
            const response = await apiHistorique.getUserDetail(data);
            // console.log(response.historiqueData);
            
			resolve({
				data: response.historiqueData,
				page:response.page,
				totalCount: response.total,
            });
        } catch (e){
            console.log(e)
            setError(e.message);
        }
    }
    
    const getInfoGameUser = async (userId) => {
    	try{
        	let data = {userId:userId,start:startDate,end:endDate,gameNames:(gameName.length===0)?gameNames:[gameName]};
            const response = await apiHistorique.getUserInfoGame(data);
			setInfoGame(response.stats);
			if(gameName.length===0){
				let series = [];
				let optionsGamePlay = JSON.parse(optionsDonut);
				optionsGamePlay.labels = [];
				for (var i = 0; i < response.gamePlay.length; i++) {
					optionsGamePlay.labels.push(response.gamePlay[i].name);
					series.push(response.gamePlay[i].total);
				}
				optionsGamePlay.colors= ["#673ab7", "#00bcd4","#009688","#4caf50","#ffc107","#9e9e9e","#607d8b","#f44336","#ffeb3b"];
				optionsGamePlay.title.text = "Game play";
				setInfoGamePlay({options:optionsGamePlay,series:series});
			}

			let optionsGame = JSON.parse(optionsDonut);
			optionsGame.title.text = "Parties jouées";
			optionsGame.labels= ['Victoire', 'Défaite'];
        	optionsGame.colors= ["#4299E1", "#E53E3E"];
        	setDonutGame({options:optionsGame,series:[response.stats.nbWin,response.stats.nbLoose]})

        } catch (e){
            console.log(e)
            setError(e.message);
        }
    }
    const arrayDevice = ["browser","ios","android","mobile"];
    const getSessionUserInfo = async (userId) => {
    	try{
        	let data = {userId:userId,start:startDate,end:endDate};
            const response = await apiSession.getSessionUserInfo(data);
			setSessionUser(response);
			let optionsSession = JSON.parse(optionsDonut);
			optionsSession.title.text = "Connections";
			optionsSession.labels= ['Browser', 'iOS','Android','Mobile'];
        	optionsSession.colors= ["#8bc34a", "#651fff","#4299E1", "#E53E3E"];
        	let series = [0,0,0,0];
        	for (const key in response.deviceSession) {
        		if (key === "tablet") {
        			series[arrayDevice.indexOf("mobile")] = response.deviceSession[key];
        		}
        		if (!arrayDevice.includes(key)) {
        			series[arrayDevice.indexOf("browser")] = response.deviceSession[key];	
        		}
        		series[arrayDevice.indexOf(key)] = (response.deviceSession[key] === null)?0:response.deviceSession[key];
        	}
        	setDonutSession({options:optionsSession,series:series})
        } catch (e){
            console.log(e)
            setError(e.message);
        }
    }

    const getUserSessionInfoGame = async (userId) => {
    	try{
        	let data = {userId:userId,start:startDate,end:endDate};
            const response = await apiHistorique.getUserSessionInfoGame(data);
			setNbAverageGameSession(response.averageGameBySession);
        } catch (e){
            console.log(e)
            setError(e.message);
        }
    }
    
    const convertDate = (date, format) => {
		if (date === null) {return;}
		moment.locale("fr");
		return moment(date).format(format);
	}

	const Datediff = (diff) => {
		let days = Math.trunc(diff / (24 * 3600));
		diff -= Math.trunc(days * 24 * 3600);
		let hours = Math.trunc(diff / 3600);
		diff -= Math.trunc(hours * 3600);
		let minutes = Math.trunc(diff / 60);
		diff -= Math.trunc(minutes * 60);
		let seconds = Math.trunc(diff);
		if (days) {
			return `${days}j ${hours}h ${minutes}m ${seconds}s`;
		} else if(hours){
			return `${hours}h ${minutes}m ${seconds}s`;
		} else {
			return `${minutes}m ${seconds}s`;
		}
	}
	const capitalize = (s) => {
	  	if (typeof s !== 'string') return ''
	  	return s.charAt(0).toUpperCase() + s.slice(1)
	}
    const handleUsername = (e) => setUsername(e.target.value);
	return(
		<div>
			<div>
				<ErrorContainer>
				{(error && typeof(error) === "string") && (
					<ErrorText>
						{error}
					</ErrorText>
				)}
				</ErrorContainer>
				<SearchContainer>
					<CustomTextField
						InputProps={{ className: classes.textInput }}
						variant="outlined"
						margin="normal"
						required
						name="username"
						label="Username"
						type="text"
						id="username"
						onChange={handleUsername}
						error={(error && !!error.username)}
						helperText={(error && !!error.username) ? error.username : ''}
					/>
					<SelectDate startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate}/>
					<div style={{width:200}}>
						<Select placeholder="Select game" onChange={onSelectGame} style={{marginTop:20,borderRadius:15}}>
							{gameNames.map((gameName,i)=>{
								return(
						  			<option value={gameName} key={i}>{capitalize(gameName)}</option>
								);
							})}
						</Select>
					</div>
					<ButtonSearch onClick={() => getUserDetail()}>
						<ButtonText>Search</ButtonText>
					</ButtonSearch>
				</SearchContainer>
			</div>
			{sessionUser && (
				<Grid container style={{justifyContent:'space-evenly'}}>
		            <CardInfoMojo title="Partie par session (moyenne)" value={nbAverageGameSession} />
		            <CardInfoMojo title="Durée totale" value={Datediff(sessionUser.timeSpendTotal)} />
		            <CardInfoMojo title="Durée moyenne" value={Datediff(sessionUser.timeSpendAverage)} />
		        </Grid>
			)}
			{infoGame && (
				<Grid container style={{justifyContent:'space-evenly'}}>
		            <CardInfoMojo title="Balles jouées" value={infoGame.mise} />
		            <CardInfoMojo title="Balles gagnées" value={infoGame.credit} />
		            <CardInfoMojo title="Euros gagnés" value={`${infoGame.solde}€`} />
		        </Grid>
			)}
			{infoStatsUser && (
				<Grid container style={{justifyContent:'space-evenly'}}>
		            <CardInfoMojo title="Vidéos regardées" value={infoStatsUser.nbVideoPlay} />
		            <CardInfoMojo title="Gains coffres" value={infoStatsUser.nbGainsChest} />
		            <CardInfoMojo title="Gains parrainages" value={infoStatsUser.parrainCredit} />
		        </Grid>
			)}
			<div style={{display:"flex", justifyContent:'space-evenly'}}>
			{infoGamePlay && (
				<div style={{width:350, marginTop:20}}>
		            <DonutChart options={infoGamePlay.options} series={infoGamePlay.series}/>
		        </div>
			)}
			{donutGame && (
		        <div style={{width:350, marginTop:20}}>
		            <DonutChart options={donutGame.options} series={donutGame.series}/>
		        </div>
	        )}
	        {donutSession && (
		        <div style={{width:350, marginTop:20}}>
		            <DonutChart options={donutSession.options} series={donutSession.series}/>
		        </div>
	        )}
	        </div>
			{userId && (
				<div style={{overflow:"none",margin:20}}>
					<MaterialTable
						tableRef={tableRef}
						title="Historique Game"
						columns={tableData.columns}
						data={query =>
				          	new Promise((resolve, reject) => {
				            	getUserGameDetail(resolve,query);
				          	})
				        }
				        options={{
				          	search: false,
				          	pageSize:10,
				          	pageSizeOptions:[5,10,25,50,100]
				        }}
					/>
				</div>
			)}
		</div>
	);
}

export default UserDetail;