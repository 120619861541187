import React, { useState,useEffect } from 'react';
import Iban from "./iban";
import Waitcashout from "./waitcashout";
import Redcheck from "./redcheck";
import * as apiCash from '../../services/waitcashout';
function Cashout(){
    const [showIban, setShowIban] = useState(false);
	const [showRedcheck, setShowRedcheck] = useState(true);
    const [average, setAverage] = useState(0);
    const getWaitCashoutAverage = async ()=>{
        try{
			const response = await apiCash.getAverage();
        	setAverage(response.average.toFixed(2));
        } catch (e){
            console.log(e)
        }
    }

    useEffect(()=> {
    	getWaitCashoutAverage();
    },[]);
	return(
		<div>
			<div style={{margin:"10px auto",width:300,display:"flex",justifyContent:"space-between"}}>
				<input style={{textDecoration:(showIban && !showRedcheck)?"underline":null, textTransform: (showIban && !showRedcheck)?"uppercase":null}} onClick={()=> {setShowIban(true); setShowRedcheck(false);}} value="Iban" type="button"/>
				<input style={{textDecoration:(!showIban && !showRedcheck)?"underline":null, textTransform: (!showIban && !showRedcheck)?"uppercase":null}} onClick={()=> {setShowIban(false); setShowRedcheck(false);}} value="Cashout" type="button"/>
				<input style={{textDecoration:(!showIban && showRedcheck)?"underline":null, textTransform: (!showIban && showRedcheck)?"uppercase":null}} onClick={()=> {setShowRedcheck(true); setShowIban(false);}} value="Redcheck" type="button"/>
			</div>
			<div style={{margin:"auto",marginTop:20,maxWidth:400,display:"flex",}}>
				<p>Moyenne du montant pour le cashout : {average}€</p>
			</div>
			<div style={{overflow:"none",margin:50}}>
				{ showRedcheck ? (
					<Redcheck/>
				) : showIban ? (
					<Iban/>
				) : (
					<Waitcashout/>
				)}
			</div>
		</div>
	);
}

export default Cashout;