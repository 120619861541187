import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from  "react-datepicker";
import fr from 'date-fns/locale/fr';
import {
	ContainerTime,
	TextTimer,
	ButtonTime
} from './DateStyles';
registerLocale('fr', fr)
const SelectDate = ({startDate,setStartDate,endDate,setEndDate}) => {
	const DateCustomInput = ({ value, onClick }) => (
    	<ButtonTime onClick={onClick}>
      		{value}
    	</ButtonTime>
  	);
	return (
		<>
		<ContainerTime>
			<TextTimer>Date de début</TextTimer>
			<DatePicker
				locale='fr'
				selected={startDate}
				onChange={date => setStartDate(date)}
				showTimeInput
				timeFormat="HH:mm"
				timeIntervals={15}
				showMonthDropdown
			    showYearDropdown
			    dropdownMode="select"
				dateFormat="MMMM d, yyyy h:mm aa"
				customInput={<DateCustomInput />}
			/>
		</ContainerTime>
		<ContainerTime>
			<TextTimer>Date de fin</TextTimer>
			<DatePicker
				locale='fr'
				selected={endDate}
				onChange={date => setEndDate(date)}
				showTimeInput
				timeFormat="HH:mm"
				timeIntervals={15}
				showMonthDropdown
			    showYearDropdown
			    dropdownMode="select"
				dateFormat="MMMM d, yyyy h:mm aa"
				customInput={<DateCustomInput />}
			/>
		</ContainerTime>
		</>
	);
}

export const SelectOneDate = ({startDate,setStartDate,}) => {
	const DateCustomInput = ({ value, onClick }) => (
    	<ButtonTime onClick={onClick}>
      		{value}
    	</ButtonTime>
  	);
	return (
		<>
		<ContainerTime>
			<TextTimer>Date extract</TextTimer>
			<DatePicker
				locale='fr'
				selected={startDate}
				onChange={date => setStartDate(date)}
				timeFormat="HH:mm"
				timeIntervals={15}
				showMonthDropdown
			    showYearDropdown
			    dropdownMode="select"
				dateFormat="MMMM d, yyyy"
				customInput={<DateCustomInput />}
			/>
		</ContainerTime>
		</>
	);
}

export default SelectDate;