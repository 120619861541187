export const optionsProgress = JSON.stringify({
    chart: {
        type: 'bar',
        stacked: true,
        sparkline: {
            enabled: true
        }
    },
    plotOptions: {
        bar: {
            horizontal: true,
            barHeight: '10%',
            colors: {
                backgroundBarColors: ['#40475D']
            }
        },
    },
    colors: ['#f02fc2'],
    stroke: {
        width: 0,
    },
    title: {
        floating: true,
        offsetX: 0,
        offsetY: 0,
        text: ''
    },
    subtitle: {
        floating: true,
        align: 'right',
        offsetY: 0,
        text: '',
        style: {
            fontSize: '20px'
        }
    },
    tooltip: {
        enabled: false
    },
    yaxis: {
        max: 100
    },
    fill: {
        type: 'gradient',
        gradient: {
            inverseColors: false,
            gradientToColors: ['#6078ea']
        }
    },
});

export const optionsDonut = JSON.stringify({
    title: {
        align: 'left'
    },
    subtitle: {
        align: 'left',
        style: {
            fontSize: '12px'
        }
    },
    chart: {
        type: 'donut',
        height:227,
    },
    dataLabels: {
        enabled: true,
        formatter: function (val) {
            return val.toFixed(0) + "%"
        },
    },
    plotOptions:{
        pie: {
            donut: {
                size:"65%",
                background:'transparent',
                labels: {
                    show: true,
                    total: {
                        show: true,
                        showAlways: false,
                        label: 'Total',
                        fontSize: '18px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 600,
                        color: '#373d3f',
                        formatter: function (w) {
                            return w.globals.seriesTotals.reduce((a, b) => {
                                return a + b
                            }, 0)
                        }
                    }
                }
            }
        }
    }
});

export const optionsArea = JSON.stringify({
    title: {
        text: '',
        align: 'left',
        style: {
            fontSize: '20px'
        }
    },
    subtitle: {
        text: '',
        align: 'left',
        style: {
            fontSize: '12px'
        }
    },
    chart: {
        height:100,
        type:"area",
        sparkline: {
            enabled: true
        },
    },
    dataLabels: {
        enabled: false
    },
    colors:["#2E93fA"],
    fill:{
        colors:["#E0E0E0"],
        opacity: 0.9,
        type: 'solid',
    },
    stroke: {
        width: 4,
        curve: 'straight'
    },
});