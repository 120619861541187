import axios from 'axios';

import * as c from './constant';
import {  handler } from './handler';

export const getUserDetail = async (data) => {
	try {
		const res = await axios.post(`${c.HISTORIQUE}/userDetail`,data);
		return res.data
	} catch (e) {
		throw handler(e);
	}
}

export const getGameDetail = async (data) => {
	try {
		const res = await axios.post(`${c.HISTORIQUE}/gameDetail`,data);
		return res.data
	} catch (e) {
		throw handler(e);
	}
}

export const getGameHistorique = async (data) => {
	try {
		const res = await axios.post(`${c.HISTORIQUE}/gameHistorique`,data);
		return res.data
	} catch (e) {
		throw handler(e);
	}
}

export const getInfoGamePlay = async () => {
	try {
		const res = await axios.get(`${c.HISTORIQUE}/infoGamePlay`,);
		return res.data
	} catch (e) {
		throw handler(e);
	}
}

export const getUserInfoGame = async (data) => {
	try {
		const res = await axios.post(`${c.HISTORIQUE}/infoGamePlay/byUser`,data);
		return res.data
	} catch (e) {
		throw handler(e);
	}
}

export const getInfoGameStats = async () => {
	try {
		const res = await axios.get(`${c.HISTORIQUE}/infoGameStats`,);
		return res.data
	} catch (e) {
		throw handler(e);
	}
}

export const getUserSessionInfoGame = async (data) => {
	try {
		const res = await axios.post(`${c.HISTORIQUE}/infoGamePlay/session`,data);
		return res.data
	} catch (e) {
		throw handler(e);
	}
}