import React, { useState, useEffect } from "react";
import {
	BrowserRouter as Router,
	Switch,
	Route,
	useHistory
} from "react-router-dom";

import Home from './screens/Home';
import Login from './screens/Login'
import AuthProvider, { useAuth } from "./contextes/provider";
import NotFound from './screens/NotFound';
import PrivateRoute from './PrivateRoute';

function Routing() {
	const history = useHistory();
	const { getAuthState } = useAuth();

	
	const [isLoad,setIsLoad] = useState(false);

	const initialize = async () => {
		try {

			await getAuthState();
			setIsLoad(true);
		} catch (e) {
			history.push('/login');
		}
	}

	useEffect(() => {
		initialize();
	}, []);

	if (isLoad) {
		return (
			<Switch>
				<Route path="/login">
					<Login />
				</Route>			
			
				<PrivateRoute exact path={["/","/Home"]} component={Home} />
				<Route path="*">
					<NotFound />
				</Route>
			</Switch>
			
		)
	} else {
		return null;
	}
}

export default function App() {
	return (
		<AuthProvider>
			<Router>
				<Routing/>
			</Router>
		</AuthProvider>
	);
}