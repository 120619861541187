import axios from 'axios';

import * as c from './constant';
import { authHandler, handler } from './handler';

export const login = async (data) => {
	try {
		const res = await axios.post(c.LOGIN, data);
		return res.data
	} catch (e) {
		throw authHandler(e);
	}
}

export async function logout(data) {
	try {
		let res = await axios.post(c.LOGOUT, data);
		return res.data;
	} catch (e) {
		throw handler(e);
	}
}

export const loginjwt = async (data) => {
	try {
		const res = await axios.post(c.LOGIN_JWT, data);
		return res.data
	} catch (e) {
		throw handler(e);
	}
}

export const getLinkVerified = async (email) => {
	try {
		const res = await axios.get(`${c.GENERATE_LINK}/${email}`);
		return res.data
	} catch (e) {
		throw handler(e);
	}
}