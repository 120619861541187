import styled from 'styled-components';
import {
	Box,
	Text
} from "@chakra-ui/core";

/* STYLED COMPONENTS */
export const ButtonSearch = styled(Box)`
	padding: 10px 10px;
	margin-top:17px;
	margin-left:10px;
	border-radius:20px;
	cursor:pointer;
	background-image: linear-gradient(135deg, #0093FF 0%, #00DEFF 100%);
	height:40px;
`;
export const ButtonText = styled(Text)`
	font-family: WorkSans;
	text-transform: uppercase;
	color:#fff;
	align-items:center;
`;
export const SearchContainer = styled(Box)`
	width: 50%;
	margin:auto;
	display:flex;
	justify-content:space-evenly;
`;

export const ErrorContainer = styled(Box)`
	width: 25%;
	margin:auto;
	display:flex;
`;

export const GameInfo = styled(Box)`
	width: 30%;
	display:flex;
	padding:10px;
	flex-direction:column;
	background-color: #D8D8D8;
	border-radius:20px;
	margin-top:20px;
`;

export const ContainerInfo = styled(Box)`
	display: flex;
    flex-wrap: wrap;
    justify-content:space-between;
    width:90%;
    margin:auto;
`;

export const TitleGame = styled(Text)`
	font-size:20px;
	text-transform:uppercase;
	text-align:center;
	color:#0e47a1;
`;

export const ContentGame = styled(Text)`
	font-size:15px;
	color:#fff;
`;
