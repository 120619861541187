import React from 'react';
import { Button, Input, Select } from '@chakra-ui/core';

import DatePicker from '../DatePick';

import { correspondance } from '../../index';

export default function Card({
  add,
  setCode,
  setLink,
  setLogo,
  setCategory,
  logo,
  code,
  link,
  setText,
  setTitle,
  setModalImage,
  title,
  text,
  modaleImage,
  expirationDate,
  setExpirationDate,
  setCodeLink,
  codeLink,
}) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '33vw', marginBottom: '30px' }}>
      <div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Select placeholder="Choisir une catégorie" 
          onChange={(e) => setCategory(e.target.value)}
          >
            {Object.keys(correspondance).map((category, idx) => <option key={idx} value={correspondance[category].category}>{correspondance[category].label}</option>)}
          </Select>
        </div>

        {setCodeLink && (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Input
              value={code}
              style={{ width: '100%' }}
              onChange={(e) => {
                setCode(String(e.target.value));
              }}
              placeholder="CODE"
            />
          </div>
        )}
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Input
            value={setCodeLink ? codeLink : link}
            style={{ width: '100%' }}
            onChange={(e) => {
              setCodeLink ? setCodeLink(e.target.value) : setLink(e.target.value);
            }}
            placeholder={setCodeLink ? 'LIEN CODE' : 'LIEN'}
          />
        </div>

        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Input
            value={logo}
            style={{ width: '100%' }}
            onChange={(e) => {
              setLogo(e.target.value);
            }}
            placeholder="logo"
          />
        </div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Input
            value={modaleImage}
            style={{ width: '100%' }}
            onChange={(e) => {
              setModalImage(e.target.value);
            }}
            placeholder="Image modale"
          />
        </div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Input
            value={title}
            style={{ width: '100%' }}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            placeholder="Titre"
          />
        </div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Input
            value={text}
            style={{ width: '100%' }}
            onChange={(e) => {
              setText(e.target.value);
            }}
            placeholder="Text"
          />
        </div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              fontFamily: 'WSMedium',
            }}
          >
            Date de fin :
          </div>
          <div style={{ flex: 1, display: 'flex' }}>
            <DatePicker expirationDate={expirationDate} setExpirationDate={setExpirationDate} />
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
            marginTop: '10px',
          }}
        >
          <Button onClick={add} variantColor="teal" variant="outline">
            AJOUTER
          </Button>
        </div>
      </div>
    </div>
  );
}
