import React, {useEffect,useState } from 'react';
import moment from "moment";
import * as apiUser from '../../services/user';
import * as apiStat from '../../services/globalgamestat';
import { makeStyles } from '@material-ui/core/styles';
import {
    Card,
    CardActionArea,
    CardContent,
    Typography,
    Grid
} from '@material-ui/core';
import {
    ContainerChild,
    ContainerDonut,
    ButtonSearch,
    ButtonText,
    ContainerSearch
} from "./styles";
import LineChart from '../../components/LineChart';
import BarChart from '../../components/BarChart';
import {optionsProgress} from './options';
import SelectDate from '../../components/SelectDate';
import {gameNames} from "../Home/index";
const useStyles = makeStyles({
    card: {
        width: 200,
        marginTop: 10,
        marginRight: 20,
        backgroundColor: '#fff'
    },
    content: {
        color: '#22577A'
    },
    btn: {
        color: '#80ED99'
    },
});

function CardHomeMojo(props) {
    const classes = useStyles();

    return (
        <Card className={classes.card}>
            <CardActionArea>
                <CardContent className={classes.content}>
                    <Typography variant="body2" color="textSecondary" component="p">
                    {props.title}
                    </Typography>
                    <Typography gutterBottom variant="h5"  component="h2">
                    {props.value}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    )
}
let customUpdate = false;
const Dashboard = (props) => {
    const [globalStats, setGlobalStats] = useState({credit:0,solde:0});
    const [nbUsersActif, setNbUsersActif] = useState(0);
    const [progressGraph, setProgessGraph] = useState(null);
    const [infoGameGen,setInfoGameGen] = useState(null);
    const [donutLooseWin,setDonutLooseWin] = useState(null);
    const [globalStatsGraph,setGlobalStatsGraph] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const convertDate = (date, format) => {
        if (date === null) {return;}
        return moment(date).format(format);
    }

    const getGlobalStats = async ()=>{
        try{
            let dateStart =  new Date();
            dateStart.setMinutes(0,0,0);
            let dateEnd = new Date();
            const response = await apiUser.getUsersGlobalStatsDay(dateStart,dateEnd);
            setGlobalStats(response.stats);
            createProgessData(response.stats);
            updateGlobalStats(response.globalStatDay);
        } catch (e){
            console.log(e)
        }
    }

    const getIndicesOfGames = (labels,gameName) => {
        for (var i = 0; i < labels.length; i++) {
            if(labels[i] === gameName)
                return i;
        }
    }
    const updateStatsGeneralDay = async ()=>{
        try {
            const statsType = ["mise","credit","solde","total","nbWin","nbLoose"];
            let dateStart =  new Date();
            dateStart.setMinutes(0,0,0);
            let dateEnd = new Date();
            const response = await apiStat.getStatsGameGeneralDay(dateStart,dateEnd);
            
            let statGameDay = response.globalGameDayStat;
            let newInfoGameGen = JSON.parse(props.infoGameGen);
            if(customUpdate || statGameDay.includes(null)) {
                setInfoGameGen(newInfoGameGen);
                return;
            }
            for (var i = 0; i < statsType.length; i++) {
                if (statsType[i] === "solde") {
                    newInfoGameGen[i].options.plotOptions.pie.donut.labels.total.formatter = function(w) {
                        return numberWithCommas(w.globals.seriesTotals.reduce((a, b) => {
                            return Number((a + b).toFixed(2))
                        }, 0))
                    };
                } else {
                    newInfoGameGen[i].options.plotOptions.pie.donut.labels.total.formatter = function(w) {
                        return numberWithCommas(w.globals.seriesTotals.reduce((a, b) => {
                            return a + b
                        }, 0))
                    }
                }
                
                for (var j = 0; j < statGameDay.length; j++) {
                    let indiceGames = getIndicesOfGames(newInfoGameGen[i].options.labels,gameNames[statGameDay[j].gameName]);
                    if (statsType[i] === "solde") {
                        newInfoGameGen[i].series[indiceGames] = Number((newInfoGameGen[i].series[indiceGames]+statGameDay[j][statsType[i]]).toFixed(2));
                    } else {
                        newInfoGameGen[i].series[indiceGames] += statGameDay[j][statsType[i]];
                    }
                }
            }
            setInfoGameGen(newInfoGameGen);
        } catch (e){
            console.log(e)
        }
    }

    const Datediff = (diff) => {
        let days = Math.trunc(diff / (24 * 3600));
        diff -= Math.trunc(days * 24 * 3600);
        let hours = Math.trunc(diff / 3600);
        diff -= Math.trunc(hours * 3600);
        let minutes = Math.trunc(diff / 60);
        diff -= Math.trunc(minutes * 60);
        if (days) {
            return `${days}j ${hours}h`;
        } else if(hours){
            return `${hours}h`;
        } else {
            return `${minutes}m`;
        }
    }
    const getValuePlayerStats = (playerStats) => {
        let total = 0;
        for (var i = 0; i < playerStats.length; i++) {
            total += playerStats[i].nbInscrit;
        }
        return total;
    }
    const getOldValue = (statsGraph,name) => {
        for (var i = 0; i < statsGraph.length; i++) {
            if(statsGraph[i].name === name)
                return i;
        }
        return null;
    }

    const updateGlobalStats = (statsDay) => {
        if (props.globalStatsGraph) {
            let newStatsGraph = JSON.parse(props.globalStatsGraph);
            for (var i = 0; i < newStatsGraph.length; i++) {

                newStatsGraph[i].options.title.text -= newStatsGraph[i].series[0].data[newStatsGraph[i].series[0].data.length-1];

                if (['playerStatsVerified'].includes(newStatsGraph[i].name)) {
                    let total = getValuePlayerStats(statsDay[newStatsGraph[i].name]);
                    newStatsGraph[i].options.title.text += total;
                    newStatsGraph[i].series[0].data[newStatsGraph[i].series[0].data.length-1] = total;
                } else {
                    newStatsGraph[i].options.title.text += statsDay[newStatsGraph[i].name];
                    if (newStatsGraph[i].name === "solde") {
                        newStatsGraph[i].options.title.text = Number(newStatsGraph[i].options.title.text.toFixed(2));
                    }
                    // if("durationSessionAvg" === newStatsGraph[i].name){
                    //     newStatsGraph[i].options.title.text = Datediff(newStatsGraph[i].options.title.text);
                    // } else if (newStatsGraph[i].name === "durationSession"){
                    //     newStatsGraph[i].options.title.text = "Total";
                    // }  else if (newStatsGraph[i].name === "playerActif"){
                    //     newStatsGraph[i].options.title.text = (newStatsGraph[i].options.title.text/newStatsGraph[i].series[0].data.length).toFixed(0)
                    // }
                    newStatsGraph[i].series[0].data[newStatsGraph[i].series[0].data.length-1] = statsDay[newStatsGraph[i].name];
                }
                newStatsGraph[i].options.title.text = numberWithCommas(newStatsGraph[i].options.title.text);
            }
            setGlobalStatsGraph(newStatsGraph);
            let oldWinIndex = getOldValue(newStatsGraph,"nbWin");
            let oldLooseIndex = getOldValue(newStatsGraph,"nbLoose");
            if (oldWinIndex !== null) {
                statsDay.nbWin -= newStatsGraph[oldWinIndex].series[0].data[newStatsGraph[oldWinIndex].series[0].data.length-1];
            }
            if (oldLooseIndex !== null) {
                statsDay.nbLoose -= newStatsGraph[oldLooseIndex].series[0].data[newStatsGraph[oldLooseIndex].series[0].data.length-1];
            }
            updateInfoGamePlay(statsDay.nbWin,statsDay.nbLoose);
        }
    }

    const newGlobalStats = (newStats) => {
        let newStatsGraph = JSON.parse(props.globalStatsGraph);
        let statsType = JSON.parse(props.statsTypeStruct);
        let labels = [];
        for (var i = 0; i < newStats.length; i++) {
            for (const property in newStats[i]) {
                if (statsType[property]) {
                    if (['playerStatsVerified'].includes(property)) {
                        let total = getValuePlayerStats(newStats[i][property]);
                        statsType[property].total += total;
                        statsType[property].series.push(total);
                    } else {
                        statsType[property].total += newStats[i][property];
                        statsType[property].series.push(newStats[i][property]);
                    }
                }
            }
            labels.push(convertDate(newStats[i].day,"DD MMM"));
        }
        if (labels.length > 31) {
            labels = labels.slice(labels.length-31);
        }
        for (i = 0; i < newStatsGraph.length; i++) {
            
            newStatsGraph[i].options.title.text = statsType[newStatsGraph[i].name].total;
            
            newStatsGraph[i].options.labels = labels;
            if (newStatsGraph[i].name === "solde") {
                newStatsGraph[i].options.title.text = Number(statsType[newStatsGraph[i].name].total).toFixed(2);
            }
            // if("durationSessionAvg" === newStatsGraph[i].name){
            //     newStatsGraph[i].options.title.text = Datediff(statsType[newStatsGraph[i].name].total);
            // } else if (newStatsGraph[i].name === "durationSession"){
            //     newStatsGraph[i].options.title.text = "Total";
            // } else if (newStatsGraph[i].name === "playerActif"){
            //     newStatsGraph[i].options.title.text = (statsType[newStatsGraph[i].name].total/newStats.length).toFixed(0)
            // }
            newStatsGraph[i].series[0].data = statsType[newStatsGraph[i].name].series;
            newStatsGraph[i].options.title.text = numberWithCommas(newStatsGraph[i].options.title.text);
        }
        setGlobalStatsGraph(newStatsGraph);
        newInfoGamePlay(statsType.nbWin.total,statsType.nbLoose.total);
        
    }
    function numberWithCommas(x) {
        return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    }

    const updateInfoGamePlay = (nbWin,nbLoose) => {
        let newLooseWin = JSON.parse(props.donutLooseWin);
        newLooseWin.series[0] += nbWin;
        newLooseWin.series[1] += nbLoose;
        newLooseWin.options.plotOptions.pie.donut.labels.total.formatter = function(w) {
            return numberWithCommas(w.globals.seriesTotals.reduce((a, b) => {
                return a + b
            }, 0))
        }
        setDonutLooseWin(newLooseWin);
    }

    const newInfoGamePlay = (nbWin,nbLoose) => {
        let newLooseWin = JSON.parse(props.donutLooseWin);
        newLooseWin.series[0] = nbWin;
        newLooseWin.series[1] = nbLoose;
        newLooseWin.options.plotOptions.pie.donut.labels.total.formatter = function(w) {
            return numberWithCommas(w.globals.seriesTotals.reduce((a, b) => {
                return a + b
            }, 0))
        }
        setDonutLooseWin(newLooseWin);
    }

    const createProgessData = (stats) => {
        let statPercent = [];
        statPercent.push({colorStart:"#eeff41",colorEnd:"#ef6c00",name:"Son activé",value:Number(stats.sound /stats.total *100).toFixed(0)});
        // statPercent.push({colorStart:"#17ead9",colorEnd:"#6078ea",name:"Notifs activées",value:Number(stats.notif /stats.total *100).toFixed(0)});
        statPercent.push({colorStart:"#f02fc2",colorEnd:"#6094ea",name:"Notifs activées",value:Number(stats.notifIOS /stats.total *100).toFixed(0)});
        
        let progressData = [];
        for (var i = 0; i < statPercent.length; i++) {
            let options = JSON.parse(optionsProgress);
            options.title.text = statPercent[i].name;
            options.subtitle.text = `${statPercent[i].value}%`;
            options.colors = [statPercent[i].colorStart];
            options.fill.gradient.gradientToColors = [statPercent[i].colorEnd];
            progressData.push({options,series:{name:statPercent[i].name,data:[statPercent[i].value]}});
        }
        setProgessGraph(progressData);
    }

    const updateAllStat = async (start,end) => {
        try {
            if (!start && !end) {
                return;
            }
            customUpdate = true;
            const response = await apiUser.getUsersGlobalStats(start,end);
            newGlobalStats(response.globalStat);

            const stats = await apiStat.getStatsGameGeneralDate(start,end);
            props.newStatsGameGeneral(stats.globalGameStatGeneral)
        } catch(e) {
            console.log(e);
        }
    }

    useEffect(()=> {
        return () => {
            customUpdate = false;
        }
    },[]);

    useEffect(()=> {
        if (props.infoGameGen) {
            updateStatsGeneralDay();
        }
    },[props.infoGameGen]);

    useEffect(()=> {
        if (props.globalStatsGraph) {
            getGlobalStats();
        }
    },[props.globalStatsGraph])
    return (
        <div>
            <ContainerSearch>
                <SelectDate startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate}/>
                <ButtonSearch onClick={() => updateAllStat(startDate,endDate)}>
                    <ButtonText>Search</ButtonText>
                </ButtonSearch>
            </ContainerSearch>
            <Grid container>
                <CardHomeMojo title="Total balles" value={numberWithCommas(globalStats.credit)} />
                <CardHomeMojo title="Total euros" value={globalStats.solde.toFixed(2)} />
            </Grid>
            <Grid container>
                {globalStatsGraph && (
                    <React.Fragment>
                    {globalStatsGraph.map((statsGraph,key)=> {
                        return (
                            <ContainerChild key={key}>
                                <LineChart dataChart={statsGraph}/>
                            </ContainerChild>
                        );
                    })}
                    </React.Fragment>
                )}
            </Grid>
            <Grid container>
                {infoGameGen && (
                    <React.Fragment>
                    {infoGameGen.map((statsGraph,key)=> {
                        return (
                            <ContainerDonut key={key}>
                                <LineChart dataChart={statsGraph}/>
                            </ContainerDonut>
                        );
                    })}
                    </React.Fragment>
                )}
                <ContainerDonut>
                    <LineChart dataChart={donutLooseWin}/>
                </ContainerDonut>
                <ContainerDonut>
                    <BarChart dataChart={progressGraph} height={70}/>
                </ContainerDonut>
            </Grid>
            
        </div>
    );
}
export default Dashboard;