import React from "react";
import styled from 'styled-components';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from  "react-datepicker";
import fr from 'date-fns/locale/fr';
import {
  Button,
} from "@chakra-ui/core";

registerLocale('fr', fr)

export const ButtonTime = styled(Button)`
	width:300px;
`;
const SelectDate = ({expirationDate,setExpirationDate,endDate,setEndDate}) => {
	const DateCustomInput = ({ value, onClick }) => (
    	<ButtonTime onClick={onClick}>
      		{value}
    	</ButtonTime>
  	);
	return (
		<>
			<DatePicker
				locale='fr'
				selected={expirationDate}
				onChange={date => setExpirationDate(date)}
				showMonthDropdown
			    showYearDropdown
			    dropdownMode="select"
				dateFormat="MMMM d, yyyy h:mm aa"
				customInput={<DateCustomInput />}
			/>
		</>
	);
}

export default SelectDate;