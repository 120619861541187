import React, { useState } from 'react';
import { useAuth } from "../../contextes/provider";
import MaterialTable from 'material-table';
import * as apiUser from '../../services/user';
import moment from "moment";
import exportToCsv from '../../utils/exportcsv';

const fieldUpdate=["credit","isVerified","isAdmin"];
function Users(){
	// const history = useHistory();
	const { state } = useAuth();
	const user = state.user;
	let tableRef = React.createRef();
	const [tableData] = useState({
		columns: [
			{
				title: 'Username',
				field: 'username',
				editable: 'never'
			},
			{
				title: 'id',
				field: '_id',
				editable: 'never'
			},
			{
				title: 'Balles',
				field: 'credit',
				type: 'numeric'
			},
			{
				title: 'Euros',
				field: 'solde',
				editable: 'never',
				type: 'numeric'
			},
			{
				title: 'Pubs regardées',
				field: 'nbVideoPlay',
				editable: 'never',
				type: 'numeric'
			},
			{
				title: 'Vérifié',
				field: 'isVerified',
				type: 'boolean'
			},
			{
				title: 'Email',
				field: 'email',
				editable: 'never',
			},
			{
				title: "Dernière connexion",
				field: 'lastSession',
				editable: 'never',
				render: rowData=><p>{convertDate(rowData.lastSession,"DD/MM/YYYY HH:mm")}</p>
			},
			{
				title: "Date d'inscription",
				field: 'createdAt',
				editable: 'never',
				render: rowData=><p>{convertDate(rowData.createdAt,"DD/MM/YYYY")}</p>
			},
		]
	});

	const getUsers = async (resolve,query)=>{
        try{
        	let sort={field:"createdAt",value:1};
        	if (query.orderBy) {
        		sort.field = query.orderBy.field;
        		sort.value = (query.orderDirection === "asc")?1:-1;
        	}
        	const data = {nbPage:query.page,pageSize:query.pageSize,fieldSort:sort.field,valueSort:sort.value,search:query.search}
			const response = await apiUser.getUsersByPage(data);
			
        	resolve({
				data: response.users,
				page:response.page,
				totalCount: response.total,
            });
        } catch (e){
            console.log(e)
        }
    }

    const getExctractData = async (event, rowData)=>{
        try{
			const response = await apiUser.getExtractDataJoueur(rowData._id);
        	exportToCsv(`${rowData.username}.csv`,response);
        } catch (e){
            console.log(e)
        }
    }

    const convertDate = (date, format) => {
		if (!date) {return;}
		moment.locale("fr");
		return moment(date).format(format);
	}

    const updateData = async (newData,oldData)=>{
    	try{
    		let data={};
    		for (const keys in newData) {
    			if (!fieldUpdate.includes(keys)) {continue;}
			  	if (newData[keys] !== oldData[keys]) {
			  		data[keys] = newData[keys];
			  	}
			}
    		if (Object.keys(data).length === 0) {return;}
    		data.userId = newData._id;
    		await apiUser.updateUser(user._id,data);
    		tableRef.current.onQueryChange();
    	}catch(e){
    		console.log(e);
    	}
    }
    
	return(
		<div style={{overflow:"none",margin:20}}>
			<MaterialTable
				tableRef={tableRef}
				title="Utilisateurs"
				columns={tableData.columns}
				data={query =>
		          	new Promise((resolve, reject) => {
		            	getUsers(resolve,query);
		          	})
		        }
		        actions={[
			        {
			          icon: 'save',
			          tooltip: 'Extract User',
			          onClick: getExctractData
			        },
			    ]}
				editable={(user.role === "members")?null:{onRowUpdate:updateData}}
				options={{
		          	search: true,
		          	pageSize:50,
		          	pageSizeOptions:[5,10,25,50,100,200]
		        }}
			/>
		</div>
	);
}

export default Users;