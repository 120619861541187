
import React, { useState } from 'react';
import {
	ErrorText,
	CustomTextField
} from'../Login/styles';
import {
	useStyles,
	SubmitButton,
	Container,
	ErrorContainer,
} from'./styles';
import { CircularProgress } from "@chakra-ui/core";
import { deleteUser } from '../../services/admin';

function Alert(){
	const classes = useStyles();
	const [error, setError] = useState(null);
	const [loading,setLoading] = useState(false);
	const [emailToDelete,setEmailToDelete] = useState("");
	
    const deleteToUser = async () => {
        setLoading(true);
		try {
    		await deleteUser({email:emailToDelete});
            setLoading(false);
            setEmailToDelete("");
    		alert('Joueur supprimé');
    	} catch(e) {
            setLoading(false);
    		console.log(e)
            setError(e.message)
    	}
	}
    
	return(
		<div style={{overflow:"none",margin:20}}>
			<ErrorContainer>
			{(error && typeof(error) === "string") && (
				<ErrorText>
					{error}
				</ErrorText>
			)}
			</ErrorContainer>
			<Container>
				<CustomTextField
					InputProps={{ className: classes.textInput }}
					variant="outlined"
					margin="normal"
					value={emailToDelete}
					name={"email"}
					label={"Email"}
					id={"email"}
					onChange={(data)=>setEmailToDelete(data.target.value)}
				/>				
				<SubmitButton onClick={deleteToUser}>
					{
						loading ? (
							<CircularProgress isIndeterminate color="blue" size='2rem'/>
						) : (
							'Supprimer'
						)
					}
				</SubmitButton>
			</Container>
		</div>
	);
}

export default Alert;