import styled from 'styled-components';
import {
	Box,
	Text,
	Button
} from "@chakra-ui/core";

/* STYLED COMPONENTS */
export const ContainerTime = styled(Box)`
	margin-top:0px;
`;

export const ButtonTime = styled(Button)`
	width:200px;
`;

export const TextTimer = styled(Text)`
	font-family: WorkSans;
`;