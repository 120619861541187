import axios from 'axios';

import * as c from './constant';
import {  handler } from './handler';

export const getStatsGame = async (data) => {
	try {
		const res = await axios.post(`${c.GLOBALGAMESTAT}/gameHistorique`,data);
		return res.data
	} catch (e) {
		throw handler(e);
	}
}

export const getStatsGameGeneral = async () => {
	try {
		const res = await axios.get(`${c.GLOBALGAMESTAT}/gameGeneral`);
		return res.data
	} catch (e) {
		throw handler(e);
	}
}

export const getStatsGameGeneralDay = async (start) => {
	try {
		const res = await axios.get(`${c.GLOBALGAMESTAT}/gameGeneralDay/${start}`);
		return res.data
	} catch (e) {
		throw handler(e);
	}
}

export const getStatsGameGeneralDate = async (start,end) => {
	try {
		const res = await axios.get(`${c.GLOBALGAMESTAT}/gameGeneral/${start}/${end}`);
		return res.data
	} catch (e) {
		throw handler(e);
	}
}


export const getDataTRJGen = async (body) => {
	try {
		const res = await axios.post(`${c.GLOBALGAMESTAT}/dataTRJGen`,body);
		return res.data
	} catch (e) {
		throw handler(e);
	}
}


export const extractDataGen = async (body) => {
	try {
		const res = await axios.post(`${c.GLOBALGAMESTAT}/extractTRJGen`,body);
		return res.data
	} catch (e) {
		throw handler(e);
	}
}
