import React, { useState } from 'react';
import MaterialTable from 'material-table';
import * as apiBan from '../../services/ban';
import moment from "moment";

function UsersBan(){
	let tableRef = React.createRef();
	const [tableData] = useState({
		columns: [
			{
				title: 'id',
				field: '_id',
				editable: 'never'
			},
			{
				title: 'Email',
				field: 'email',
				editable: 'never',
			},
			{
				title: 'Adresse IP',
				field: 'ipAddress',
				editable: 'never',
			},
			{
				title: "Date de bannissement",
				field: 'updatedAt',
				editable: 'never',
				render: rowData=><p>{convertDate(rowData.createdAt,"DD/MM/YYYY HH:mm")}</p>
			},
		]
	});

	const getUsersBan = async (resolve,query)=>{
        try{
        	let sort={field:"createdAt",value:1};
        	if (query.orderBy) {
        		sort.field = query.orderBy.field;
        		sort.value = (query.orderDirection === "asc")?1:-1;
        	}
			const response = await apiBan.getUsersBanByPage(query.page,query.pageSize,sort.field,sort.value);
			
        	resolve({
				data: response.usersBan,
				page:response.page,
				totalCount: response.total,
            });
        } catch (e){
            console.log(e)
        }
    }

    const removeBan = async (event, rowData)=>{
        try{
			await apiBan.removeBanUser(rowData._id);
        	tableRef.current.onQueryChange();
        } catch (e){
            console.log(e)
        }
    }

    const convertDate = (date, format) => {
		if (date === null) {return;}
		moment.locale("fr");
		return moment(date).format(format);
	}

    
	return(
		<div style={{overflow:"none",margin:20}}>
			<MaterialTable
				tableRef={tableRef}
				title="Utilisateurs Banni"
				columns={tableData.columns}
				data={query =>
		          	new Promise((resolve, reject) => {
		            	getUsersBan(resolve,query);
		          	})
		        }
		        actions={[
			        {
			          icon: 'delete',
			          tooltip: 'Remove ban',
			          onClick: removeBan
			        },
			    ]}
				options={{
		          	search: false,
		          	pageSize:10,
		          	pageSizeOptions:[5,10,25,50,100,200]
		        }}
			/>
		</div>
	);
}

export default UsersBan;