import React, { useState } from "react";
import MaterialTable from "material-table";
import { getAllJailedUserSignaled } from "../../services/admin";
import moment from "moment";
const JailedUser = () => {
  const tableRef = React.createRef();
  const [tableData] = useState({
    columns: [
      {
				title: 'UserId',
				field: 'userId',
				editable: 'never'
			},
      {
        title: "Date alerte",
        field: "createdAt",
        render: (rowData) => (
          <p>{convertDate(rowData.createdAt, "DD/MM/YYYY")}</p>
        ),
      },
      {
        title: "Pseudo",
        field: "username",
        editable: "never",
      },
      {
        title: "Email",
        field: "email",
        editable: "never",
      },
      {
        title: "Raison",
        field: "reason",
        editable: "never",
      },
    ],
  });
  const convertDate = (date, format) => {
    if (date === null) {
      return;
    }
    moment.locale("fr");
    return moment(date).format(format);
  };
  const initSignalementJailedList = async (resolve, query) => {
    let sort = { field: "createdAt", value: -1 };
    if (query.orderBy) {
      sort.field = query.orderBy.field;
      sort.value = query.orderDirection === "asc" ? 1 : -1;
    }
    const data = {
      nbPage: query.page,
      pageSize: query.pageSize,
      fieldSort: sort.field,
      valueSort: sort.value,
      search: query.search,
    };
    const res = await getAllJailedUserSignaled(data);
    const newInfo = [];
    if (res.data && res.data.userSignaled) {
      for (let i = 0; i < res.data.userSignaled.length; i++) {
        if (!res.data.userSignaled[i]?.userId) continue;
        newInfo.push({
          email: res.data.userSignaled[i].userId.email,
          username: res.data.userSignaled[i].userId.username,
          createdAt: res.data.userSignaled[i].createdAt,
          reason: res.data.userSignaled[i].reason,
          userId: res.data.userSignaled[i].userId._id,
        });
      }
    }
    resolve({
      data: newInfo,
      page: res.data.page,
      totalCount: res.data.total,
    });
  };
  return (
    <MaterialTable
      tableRef={tableRef}
      title="Utilisateur jailed"
      columns={tableData.columns}
      data={(query) =>
        new Promise((resolve, reject) => {
          initSignalementJailedList(resolve, query);
        })
      }
      options={{
        search: true,
        pageSize: 10,
        pageSizeOptions: [5, 10, 25, 50, 100, 200],
      }}
    />
  );
};
export default JailedUser;
