import React, { useState } from 'react';
import MaterialTable from 'material-table';
import { sendWaitCashout, getAllWaitCashout } from '../../services/waitcashout';
import {  getExtractDataAllCashout } from '../../services/admin';
import moment from "moment";
import exportToCsv from '../../utils/exportcsv';
import PhotoIcon from '@material-ui/icons/Photo';
function WaitCashout(){
	// const history = useHistory();
	let tableRef = React.createRef();
	const [tableData] = useState({
		columns: [
			{
				title: 'Pseudo',
				field: 'username',
			},
			{
				title: 'Email',
				field: 'email',
			},
			{
				title: 'Montant',
				field: 'solde',
			},
			{
				title: 'Moyen de Retrait',
				field: 'paiment',
			},
			{
				title: 'IBAN',
				field: 'iban',
			},
			{
				title: 'RIB',
				field: 'ribURL',
				editable: 'never',
				render:rowData =>(rowData.ribURL)? <a href={rowData.ribURL} target="_blank" rel="noopener noreferrer" title="nouvelle fenêtre"><PhotoIcon/></a>:null
			},
			{
				title: 'PI Recto',
				field: 'cardIdentityURL',
				editable: 'never',
				render:rowData =>(rowData.cardIdentityURL)? <a href={rowData.cardIdentityURL} target="_blank" rel="noopener noreferrer" title="nouvelle fenêtre"><PhotoIcon/></a>:null
			},
			{
				title: 'PI Verso',
				field: 'cardIdentityVersoURL',
				editable: 'never',
				render:rowData =>(rowData.cardIdentityVersoURL)? <a href={rowData.cardIdentityVersoURL} target="_blank" rel="noopener noreferrer" title="nouvelle fenêtre"><PhotoIcon/></a>:null
			},
			{
				title: "Date d'enregistrement",
				field: 'createdAt',
				editable: 'never',
				render: rowData=><p>{convertDate(rowData.createdAt,"DD/MM/YYYY")}</p>
			},
		]
	});

	const getWaitCashout = async (resolve,query)=>{
        try{
        	// console.log(query);
        	let sort={field:"createdAt",value:1};
        	if (query.orderBy) {
        		sort.field = query.orderBy.field;
        		sort.value = (query.orderDirection === "asc")?1:-1;
        	}
			const response = await getAllWaitCashout(query.page,query.pageSize,sort.field,sort.value);
        	resolve({
				data: response.waitcashout,
				page:response.page,
				totalCount: response.total,
            });
        } catch (e){
            console.log(e)
        }
    }

    const convertDate = (date, format) => {
		if (date === null) {return;}
		moment.locale("fr");
		return moment(date).format(format);
	}
	const getCSVCashout = async (event, rowData) => {
		try {
			let response = await getExtractDataAllCashout();
			exportToCsv(`cashout-wait.csv`,response);
			tableRef.current.onQueryChange();
		} catch(e) {
			console.log(e);
		}
	}

	const sendCashout = async (event, rowData) => {
		try {
			console.log(rowData);
			await sendWaitCashout({idsSend:[rowData._id]});
			tableRef.current.onQueryChange();
		} catch(e) {
			console.log(e);
		}
	}
	return(	
		<>
			<button onClick={getCSVCashout} style={{backgroundColor:"#0D47A1", color:"#fff"}}>Extrait toutes les demandes en attente</button>
			<MaterialTable
				tableRef={tableRef}
				title="Cashout"
				columns={tableData.columns}
				data={query =>
					new Promise((resolve, reject) => {
						getWaitCashout(resolve,query);
					})
				}
				actions={[
					{
					icon: 'send',
					tooltip: 'Send Cashout',
					onClick: sendCashout
					},
				]}
				options={{
					search: false,
					pageSize:10,
					pageSizeOptions:[5,10,25,50,100,200]
				}}
			/>
		</>
	);
}

export default WaitCashout;