import React, { useState } from 'react';
import MaterialTable from 'material-table';
import * as apiIban from '../../services/iban';
import moment from "moment";
import PhotoIcon from '@material-ui/icons/Photo';
const fieldUpdate=["statusRib","statusIban","statusCardIdentity","statusCardIdentityVerso", 'firstname', 'lastname'];
function Iban(){
	// const history = useHistory();
	const [validate, setValidate] = useState(false);
	let tableRef = React.createRef();
	const [tableData] = useState({
		columns: [
			{
				title: 'ID',
				field: 'userId._id',
				editable: 'never'
			},
			{
				title: 'Email',
				field: 'userId.email',
				editable: 'never'
			},
			{
				title: 'Nom',
				field: 'firstname'
			},
			{
				title: 'Prénom',
				field: 'lastname'
			},
			{
				title: 'IBAN',
				field: 'iban',
				editable: 'never'
			},
			{
				title: 'Statut IBAN',
				field: 'statusIban',
				lookup: { "new": 'en attente', "validate": 'Validée',"notValidate":"Non validée" },
			},
			{
				title: 'RIB',
				field: 'ribURL',
				editable: 'never',
				render:rowData =>(rowData.ribURL)? <a href={rowData.ribURL} target="_blank" rel="noopener noreferrer" title="nouvelle fenêtre"><PhotoIcon/></a>:null
			},
			{
				title: 'Statut RIB',
				field: 'statusRib',
				lookup: { "new": 'en attente', "validate": 'Validée',"notValidate":"Non validée" },
			},
			{
				title: 'PI Recto',
				field: 'cardIdentityURL',
				editable: 'never',
				render:rowData =>(rowData.cardIdentityURL)? <a href={rowData.cardIdentityURL} target="_blank" rel="noopener noreferrer" title="nouvelle fenêtre"><PhotoIcon/></a>:null
			},
			{
				title: 'Statut PI Recto',
				field: 'statusCardIdentity',
				lookup: { "new": 'en attente', "validate": 'Validée',"notValidate":"Non validée" },
			},
			{
				title: 'PI Verso',
				field: 'cardIdentityVersoURL',
				editable: 'never',
				render:rowData =>(rowData.cardIdentityVersoURL)? <a href={rowData.cardIdentityVersoURL} target="_blank" rel="noopener noreferrer" title="nouvelle fenêtre"><PhotoIcon/></a>:null
			},
			{
				title: 'Statut PI Verso',
				field: 'statusCardIdentityVerso',
				lookup: { "new": 'en attente', "validate": 'Validée',"notValidate":"Non validée" },
			},
			{
				title: "Date d'enregistrement",
				field: 'createdAt',
				editable: 'never',
				render: rowData=><p>{convertDate(rowData.createdAt,"DD/MM/YYYY")}</p>
			},
			{
				title: "Date de modification",
				field: 'userUpdatedAt',
				editable: 'never',
				render: rowData=><p>{convertDate(rowData.userUpdatedAt,"DD/MM/YYYY")}</p>
			},
		]
	});

	const getIbans = async (resolve,query)=>{
        try{
        	let sort={field:"updatedAt",value:-1};
        	if (query.orderBy) {
        		sort.field = query.orderBy.field;
        		sort.value = (query.orderDirection === "asc")?1:-1;
        	}
			const data = {nbPage:query.page,pageSize:query.pageSize,fieldSort:sort.field,valueSort:sort.value,search:query.search,validate}
			const response = await apiIban.getAllIBANWithFilter(data);
        	resolve({
				data: response.ibans,
				page:response.page,
				totalCount: response.total,
            });
        } catch (e){
            console.log(e)
        }
    }

    const convertDate = (date, format) => {
		if (date === null) {return;}
		moment.locale("fr");
		return moment(date).format(format);
	}

	const changeIbanStatus = (validateParams) => {
		setValidate(validateParams);
		tableRef.current.onQueryChange();
	}

    const updateData = async (newData,oldData) => {
    	try{
    		let data={};
    		for (const keys in newData) {
    			if (!fieldUpdate.includes(keys)) {continue;}
			  	if (newData[keys] !== oldData[keys]) {
			  		data[keys] = newData[keys];
			  	}
			}
    		if (Object.keys(data).length === 0) {return;}
    		await apiIban.updateStatus(oldData._id,data);
    		tableRef.current.onQueryChange();
    	} catch(e){
    		console.log(e);
    	}
    }
	return(
		<div>
			<div style={{display:'flex', flexDirection: 'row', width: '100%', justifyContent:'space-around', marginBottom: 20}}>
				<button style={{backgroundColor:'#C8C8C8', padding: 20, borderRadius: 20, opacity: validate ? 1 : 0.5}} disabled={validate} onClick={()=>changeIbanStatus(true)}>Validée</button>
				<button style={{backgroundColor:'#C8C8C8', padding: 20, borderRadius: 20, opacity: !validate ? 1 : 0.5}} disabled={!validate} onClick={()=>changeIbanStatus(false)}>En attente</button>
			</div>
			<MaterialTable
				tableRef={tableRef}
				title="Iban"
				columns={tableData.columns}
				data={query =>
					new Promise((resolve, reject) => {
						getIbans(resolve,query);
					})
				}
				editable={{onRowUpdate:updateData}}
				options={{
					search: true,
					pageSize:10,
					pageSizeOptions:[5,10,25,50,100,200]
				}}
			/>
		</div>
	);
}

export default Iban;