import axios from 'axios';

import * as c from './constant';
import {  handler } from './handler';

const options = {
    headers: {
        "Content-Type": "application/json"
    }
};

export const getUsersGlobalStats = async (start,end) => {
	try {
		const res = await axios.get(`${c.USER}/globalStat/${start}/${end}`);
		return res.data
	} catch (e) {
		throw handler(e);
	}
}

export const getUsersGlobalStatsDay = async (start,end) => {
	try {
		const res = await axios.get(`${c.USER}/globalStatDay/${start}/${end}`);
		return res.data
	} catch (e) {
		throw handler(e);
	}
}

export const getGlobalInscritStats = async (start,end) => {
	try {
		const res = await axios.get(`${c.USER}/globalInscritStat/${start}/${end}`);
		return res.data
	} catch (e) {
		throw handler(e);
	}
}

export const getUsersByPage = async (data) => {
	try {
		const res = await axios.post(`${c.USER}/userPage`,data);
		return res.data
	} catch (e) {
		throw handler(e);
	}
}

export const getUsersAdminByPage = async (data) => {
	try {
		const res = await axios.post(`${c.USER}/userAdminPage`,data);
		return res.data
	} catch (e) {
		throw handler(e);
	}
}

export const getUsersActifs = async (userId) => {
	try {
		const res = await axios.get(`${c.USER}/nbUserActif`);
		return res.data
	} catch (e) {
		throw handler(e);
	}
}

export const getUser = async (username) => {
	try {
		const res = await axios.get(`${c.USER}/user/${username}`);
		return res.data
	} catch (e) {
		throw handler(e);
	}
}

export const updateUser = async (id,data) => {
	try {
		const res = await axios.put(`${c.USER}/admin/${id}`,data,options);
		return res.data
	} catch (e) {
		throw handler(e);
	}
}

export const updateUserRole = async (data) => {
	try {
		const res = await axios.put(`${c.USER}/adminRole/`,data,options);
		return res.data
	} catch (e) {
		throw handler(e);
	}
}

export const addUserAdmin = async (data) => {
	try {
		const res = await axios.put(`${c.USER}/addAdminUser/`,data,options);
		return res.data
	} catch (e) {
		throw handler(e);
	}
}

export const removeUserAdmin = async (data) => {
	try {
		const res = await axios.put(`${c.USER}/removeAdminUser/`,data,options);
		return res.data
	} catch (e) {
		throw handler(e);
	}
}

export const getExtractDataJoueur = async (id) => {
	try {
		const res = await axios.get(`${c.USER}/extractData/${id}`);
		return res.data
	} catch (e) {
		throw handler(e);
	}
}

export const updateAllCGU = async () => {
	try {
		const res = await axios.post(`${c.USER}/updateAllCGU`);
		return res.data
	} catch (e) {
		throw handler(e);
	}
}

export const getInfoStatsUser = async (userId,start,end) => {
	try {
		const res = await axios.get(`${c.USER}/infoStatsUser/${userId}/${start}/${end}`);
		return res.data
	} catch (e) {
		throw handler(e);
	}
}
