import styled from 'styled-components';
import {
	Box,
	Text,
	Button
} from "@chakra-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';
/* STYLED COMPONENTS */
export const ButtonSearch = styled(Box)`
	padding: 10px 10px;
	margin-top:17px;
	margin-left:10px;
	border-radius:20px;
	cursor:pointer;
	background-image: linear-gradient(135deg, #0093FF 0%, #00DEFF 100%);
	height:40px;
`;
export const ButtonText = styled(Text)`
	font-family: WorkSans;
	text-transform: uppercase;
	color:#fff;
	align-items:center;
`;
export const SearchContainer = styled(Box)`
	width: 50%;
	margin:auto;
	display:flex;
	justify-content:space-evenly;
`;
export const Loader = styled(CircularProgress)`
	position:absolute;
	top:0;
	bottom:0;
	left:0;
	right:0;
	margin: auto;
`;

export const ContainerDonut = styled(Box)`
	width:300px;
	margin-right:20px;
	margin-top:0px;
`;

export const ContainerChild = styled(Box)`
	width:200px;
	margin-right:20px;
	margin-top:0px;
`;

export const ContainerTime = styled(Box)`
	margin-top:0px;
`;

export const ButtonTime = styled(Button)`
	width:200px;
`;

export const TextTimer = styled(Text)`
	font-family: WorkSans;
`;