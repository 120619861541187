export const optionsLine = JSON.stringify({
    chart: {
        height: 400,
        type: 'line',
        stacked: false,
        toolbar: {
            show: true
        },
    },
    stroke: {
        width: [4,4],
        curve: 'smooth'
    },
    plotOptions: {
        bar: {
            columnWidth: '25%'
        }
    },
    grid: {
        borderColor: '#9699BB',
        strokeDashArray: 10,
        position: 'back',
        xaxis: {
            lines: {
                show: false
            }
        },   
        yaxis: {
            lines: {
                show: true
            }
        }, 
        padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 50
        }, 
    },
    dataLabels: {
        enabled: true,
    },
    colors: ["#0ECD80","#FDEE00"],
    labels: [],
    xaxis: {
        type: '',
        axisBorder: {
            show: true,
            color: "#9699BB",
        },
        labels: {
            style: {
                colors: "#0D47A1",
                fontWeight:'900'
            }
        },
    },
    yaxis: {
        min: 0,
        tickAmount:5,
        axisBorder: {
            show: true,
            color: "#9699BB",
        },
        labels: {
            style: {
                colors: "#0D47A1",
                fontWeight:'900'
            }
        },
    },
    tooltip: {
        shared: true,
        intersect: false,
    }
});