import React, { useState } from 'react';
import { useAuth } from "../../contextes/provider";
import MaterialTable from 'material-table';
import * as apiUser from '../../services/user';
import {
	ErrorText,
	CustomTextField,
	useStyles
} from'../Login/styles';
import {
	ButtonSearch,
	SearchContainer,
	ButtonText,
	ErrorContainer,
} from'../UserDetail/styles';
import {
	Select,
} from "@chakra-ui/core";
const roles = ['members',"owners"];

function UsersAdmin(){
	const { state } = useAuth();
	const user = state.user;
	const classes = useStyles();
	const [error, setError] = useState(null);
	const [username, setUsername] = useState("")
	const [role, setRole] = useState("")
	let tableRef = React.createRef();
	const handleUsername = (e) => setUsername(e.target.value);
	const onSelectRole = (e) => setRole(e.target.value);
	const [tableData] = useState({
		columns: [
			{
				title: 'Username',
				field: 'username',
				editable: 'never'
			},
			{
				title: 'id',
				field: '_id',
				editable: 'never'
			},
			
			{
				title: 'Email',
				field: 'email',
				editable: 'never'
			},
			{
				title: 'Role',
				field: 'role',
				lookup: { owners: 'Onwers',members:'Members' },
			},
		]
	});

	const getUsers = async (resolve,query)=>{
        try{
        	let sort={field:"createdAt",value:1};
        	if (query.orderBy) {
        		sort.field = query.orderBy.field;
        		sort.value = (query.orderDirection === "asc")?1:-1;
        	}
        	const data = {nbPage:query.page,pageSize:query.pageSize,fieldSort:sort.field,valueSort:sort.value,search:query.search}
			const response = await apiUser.getUsersAdminByPage(data);
			
        	resolve({
				data: response.users,
				page:response.page,
				totalCount: response.total,
            });
        } catch (e){
            console.log(e)
        }
    }

    const updateData = async (newData)=>{
    	try{
    		await apiUser.updateUserRole({role:newData.role,userId:newData._id});
    		tableRef.current.onQueryChange();
    	}catch(e){
    		console.log(e);
    	}
    }
    
    const addUserAdmin = async () => {
    	if (username.length === 0)
    		return setError("Le champs username est vide.");
    	if (role.length === 0) 
    		return setError("Vous devez choisir un role.");
    	try {
    		await apiUser.addUserAdmin({username:username,role:role});
    		tableRef.current.onQueryChange();
    		setUsername("");
    		setRole("");
    		setError(null);
    	} catch(e) {
    		setError(e.message)
    		console.log(e);
    	}
    }

    const removeAdmin = async (event,rowData) => {
    	if (window.confirm('Are you sure you wish to delete this item?'))
    	{
	    	try {
	    		await apiUser.removeUserAdmin({userId:rowData._id});
	    		tableRef.current.onQueryChange();
	    	} catch(e) {
	    		console.log(e);
	    	}
    	}
    }
    const capitalize = (s) => {
	  	if (typeof s !== 'string') return ''
	  	return s.charAt(0).toUpperCase() + s.slice(1)
	}
	return(
		<div style={{overflow:"none",margin:20}}>
			<ErrorContainer>
				{(error && typeof(error) === "string") && (
					<ErrorText>
						{error}
					</ErrorText>
				)}
			</ErrorContainer>
			<SearchContainer>
				<CustomTextField
					InputProps={{ className: classes.textInput }}
					variant="outlined"
					margin="normal"
					required
					name="username"
					label="Username"
					type="text"
					id="username"
					value={username}
					onChange={handleUsername}
					error={(error && !!error.username)}
					helperText={(error && !!error.username) ? error.username : ''}
				/>
				<div style={{width:200}}>
					<Select placeholder="Select role" onChange={onSelectRole} value={role} style={{marginTop:20,borderRadius:15}}>
						{roles.map((role,i)=>{
							return(
					  			<option value={role} key={i}>{capitalize(role)}</option>
							);
						})}
					</Select>
				</div>
				<ButtonSearch onClick={addUserAdmin}>
					<ButtonText>Ajouter un admin</ButtonText>
				</ButtonSearch>
			</SearchContainer>
			<MaterialTable
				tableRef={tableRef}
				title="Administrateur"
				columns={tableData.columns}
				data={query =>
		          	new Promise((resolve, reject) => {
		            	getUsers(resolve,query);
		          	})
		        }
		        actions={[
			        {
			          icon: 'delete',
			          tooltip: 'Remove admin',
			          onClick: removeAdmin
			        },
			    ]}
		        editable={(user.role === "members")?null:{onRowUpdate:updateData}}
				options={{
		          	search: true,
		          	pageSize:25,
		          	pageSizeOptions:[5,10,25,50,100,200]
		        }}
			/>
		</div>
	);
}

export default UsersAdmin;