import React, { useState } from 'react';
import {
  Container,
  SubmitButton
} from'./styles';
import { CircularProgress } from "@chakra-ui/core";
import exportToCsv from '../../utils/exportcsv';
import { getExtractSegmentDay } from '../../services/admin';
import {extractDataGen, getDataTRJGen } from '../../services/globalgamestat';
import SelectDate,{SelectOneDate} from '../../components/SelectDate';
import "./style.css";
function Extract(){
  const [loadingSegment,setLoadingSegment] = useState(false);
  const [day, setDay] = useState(null);
  const [loadingTRJ,setLoadingTRJ] = useState(false);
  const [isGeneral,setIsGeneral] = useState(false);
  const [startTRJ, setStartTRJ] = useState(null);
  const [endTRJ, setEndTRJ] = useState(null);
  const [dataGen, setDataGen] = useState(null);
  const segmentDay = async () => {
    if(!day) return alert("Veuillez choisir une date.");
    setLoadingSegment(true);
    try {
      const response = await getExtractSegmentDay(day);
      exportToCsv('segmentDay.csv', response);
    } catch (e) {
      console.error(e);
    }
    setLoadingSegment(false);
  }

  const trjGenData = async () => {
    if(!isGeneral && (!startTRJ || !endTRJ)) return alert("Veuillez choisir une date.");
    setLoadingTRJ(true);
    try {
      const body = {"isGen":isGeneral, "start":startTRJ,"end":endTRJ};
      const response = await getDataTRJGen(body);
      setDataGen(response.allStats);
      // exportToCsv('segmentDay.csv', response);
    } catch (e) {
      console.error(e);
    }
    setLoadingTRJ(false);
  }

  const trjGenStats = async () => {
    if(!isGeneral && (!startTRJ || !endTRJ)) return alert("Veuillez choisir une date.");
    setLoadingTRJ(true);
    try {
      const body = {"isGen":isGeneral, "start":startTRJ,"end":endTRJ};
      const response = await extractDataGen(body);
      exportToCsv('segmentDay.csv', response);
    } catch (e) {
      console.error(e);
    }
    setLoadingTRJ(false);
  }

  

  return(
		<div style={{overflow:"none",margin:20}}>
      <Container>
        <h2>Data Segment (DAU/PUB/INSCRIT)</h2>
      </Container>
			<Container>
      <SelectOneDate startDate={day} setStartDate={setDay}/>
				<SubmitButton onClick={() => loadingSegment ? '' : segmentDay()}>
					{
						loadingSegment ? (
							<CircularProgress isIndeterminate color="blue" size='2rem'/>
						) : (
							'Segment Data'
						)
					}
				</SubmitButton>
			</Container>
      <Container>
        <h2>TRJ GEN </h2>
      </Container>
      <Container style={{justifyContent:"space-evenly", alignItems:"center"}}>
        <div>
          <input type="checkbox" id="isGeneral" onClick={()=>setIsGeneral(!isGeneral)} checked={isGeneral}/>
          <label for="isGeneral" style={{marginLeft:10}}>Toute la période</label>
        </div>
        <SelectDate startDate={startTRJ} setStartDate={setStartTRJ} endDate={endTRJ} setEndDate={setEndTRJ}/>
			</Container>
      <Container>
        <SubmitButton onClick={() => loadingTRJ ? '' : trjGenData()}>
            {
              loadingTRJ ? (
                <CircularProgress isIndeterminate color="blue" size='2rem'/>
              ) : (
                'Data'
              )
            }
          </SubmitButton>
          <SubmitButton onClick={() => loadingTRJ ? '' : trjGenStats()}>
            {
              loadingTRJ ? (
                <CircularProgress isIndeterminate color="blue" size='2rem'/>
              ) : (
                'Extract'
              )
            }
          </SubmitButton>
      </Container>
      {dataGen && 
        <div style={{width:"100%",display:"flex",justifyContent:'center'}}>
        <table>
          <thead>
              <tr>
                  <th>Game</th>
                  <th>TRJ</th>
              </tr>
          </thead>
          <tbody>
          {dataGen.map((data)=> {
            return (
              <tr key={data.gameName}>
                <td>{data.gameName}</td>
                <td>{data.taux}</td>
              </tr>
            );
          })}
          </tbody>
        </table>
        </div>
      }
		</div>
	);
}

export default Extract;