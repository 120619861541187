import React, { useState, useEffect } from 'react';
import {
	ErrorText,
	CustomTextField
} from'../Login/styles';
import {
	useStyles,
	SubmitButton,
	Container,
	ErrorContainer,
	ContainerEmail
} from'./styles';
import { CircularProgress, IconButton } from "@chakra-ui/core";
import * as apiAlert from '../../services/alert';

function Alert(){
	const classes = useStyles();
	const [error, setError] = useState(null);
	const [loading,setLoading] = useState(false);
	const [email,setEmailAlert] = useState("");
	const [allEmail,setAllEmail] = useState(null);
	const getEmailAlert = async () => {
		try {
    		let response = await apiAlert.getEmailAlert();
    		setAllEmail(response.alert);
    	} catch(e) {
    		console.log(e)
    	}
	}

    const addEmailAlert = async () => {
    	if (email.length === 0) {
    		setError("Email non remplit");
    		return;
    	}
    	setLoading(true);
    	try {
    		await apiAlert.addEmailAlert({email:email});
    		setLoading(false);
    		let newEmail = allEmail;
    		newEmail.push({email:email});
    		setAllEmail([...newEmail]);
    		setEmailAlert("");
    		setError("");
    	} catch(e) {
    		setLoading(false);
    		console.log(e)
    		setError(e.message);
    	}
    }

    const removeEmailAlert = async (email) => {
		try {
    		await apiAlert.removeEmailAlert(email);
    		getEmailAlert();
    	} catch(e) {
    		console.log(e)
    	}
	}
    
    useEffect(()=> {
    	getEmailAlert();
    },[])
	return(
		<div style={{overflow:"none",margin:20}}>
			<ErrorContainer>
			{(error && typeof(error) === "string") && (
				<ErrorText>
					{error}
				</ErrorText>
			)}
			</ErrorContainer>
			<Container>
				<CustomTextField
					InputProps={{ className: classes.textInput }}
					variant="outlined"
					margin="normal"
					value={email}
					name={"email"}
					label={"Email"}
					id={"email"}
					onChange={(data)=>setEmailAlert(data.target.value)}
				/>				
				<SubmitButton onClick={() => loading ? '' : addEmailAlert()}>
					{
						loading ? (
							<CircularProgress isIndeterminate color="blue" size='2rem'/>
						) : (
							'Ajouter'
						)
					}
				</SubmitButton>
			</Container>
			<div>
				{allEmail && (
					<div>
						<h2>Email d'alerte :</h2>
						{allEmail.map((data,i)=> {
							return (
								<ContainerEmail key={i}>
									<p key={i}>{data.email}</p>
									<IconButton
									  	variantColor="red"
									  	aria-label="remove"
									  	fontSize="15px"
									  	size="15px"
									  	icon="close"
									  	style={{marginLeft:10}}
									  	onClick={()=>removeEmailAlert(data.email)}
									/>
								</ContainerEmail>
							);
						})}
					</div>
				)}
			</div>
		</div>
	);
}

export default Alert;