import React from "react";
import Chart from "react-apexcharts";

import styled from 'styled-components';

const BarChart = ({dataChart,height}) => {
	if (dataChart) {
		return (
			<ChartContainer>
				{dataChart.map((data,key)=> {
					return (
						<Chart
							options={data.options}
							series={[data.series]}
							type="bar"
							height={height}
							key={key}
						/>
					);
				})}
			</ChartContainer>
		);
	} else return null;
}

export default BarChart;

const ChartContainer = styled.div`
	width: 100%;
	padding:10px;
	margin-top:10px;
	background-color:#fff;
	box-shadow:0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
`