import React from "react";
import Chart from "react-apexcharts";

import styled from 'styled-components';

const LineChart = ({dataChart}) => {
	if (dataChart) {
		return (
			<ChartContainer>
				<Chart
					options={dataChart.options}
					series={dataChart.series}
					type={dataChart.options.chart.type}
					height={dataChart.options.chart.height}
				/>
			</ChartContainer>
		);
	} else return null;
}

export default LineChart;

const ChartContainer = styled.div`
	margin-top:10px;
	width: 100%;
	background-color:#fff;
	box-shadow:0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
`