import styled, {  } from 'styled-components';
import { TextField } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import {
	Text,
	Box,
	Image
} from "@chakra-ui/core";

/* MUI STYLES */
export const useStyles = makeStyles(theme => ({
	'@global': {
		body: {
			backgroundColor: theme.palette.common.white,
		},
	},

	root: {
		'&:hover': {
			backgroundColor: 'transparent',
		},
	},
	icon: {
		borderRadius: '3px',
		width: '15px',
		height: '15px',
		boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
		backgroundColor: '#f5f8fa',
		backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
		'$root.Mui-focusVisible &': {
			outline: '2px auto rgba(19,124,189,.6)',
			outlineOffset: 2,
		},
		'input:hover ~ &': {
			backgroundColor: '#ebf1f5',
		},
		'input:disabled ~ &': {
			boxShadow: 'none',
			background: 'rgba(206,217,224,.5)',
		},
	},
	checkedIcon: {
		backgroundColor: '#137cbd',
		backgroundImage: 'linear-gradient(135deg, #0093FF 0%, #00DEFF 100%);',
		borderRadius: '3px',
		'&:before': {
			display: 'block',
			borderRadius: '3px',
			width: '15px',
			height: '15px',
			backgroundImage:
				"url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
				" fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
				"1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
			content: '""',
		},
	},
	textInput: {
		height: "5vh",
		borderRadius: 15,
	},
	card: {
		width: 200,
	    marginTop: 10,
	    marginRight: 20,
		backgroundColor: '#fff'
    },
	content: {
		color: '#22577A'
    },
	btn: {
		color: '#80ED99'
	},
}));


/* CUSTOM TEXT FIELD */
const formStyles = {
	root: {

		'& .MuiInputBase-input' : {
			fontFamily: 'WSMedium, sans-serif'
		},

		'& .MuiFormLabel-root' : {
			fontFamily: 'WSMedium, sans-serif'
		},

		'& .MuiInputBase-root': {
			borderRadius: "15px",
			height: "45px",
			fontSize: '0.9rem'
		},

		'& label.Mui-focused': {
			color: '#CFDAEC',
		},

		'& input:valid + fieldset': {
			borderColor: '#CFDAEC',
			borderWidth: 1,
		},

		'& label' : {
			color: '#B2B2B2',

		},

		'& .MuiInput-underline:after': {
			borderBottomColor: '#00B9FF',
		},

		'& .MuiOutlinedInput-root': {
			'& fieldset': {

			},
			'&:hover fieldset': {
				borderColor: '#00B9FF',
			},

			// Focus
			'&.Mui-focused fieldset': {
				borderColor: '#00B9FF',
				borderWidth: 1,
			},
		},

		'& .MuiInputLabel-outlined': {
			transform: 'translate(14px, 16px) scale(1)',
			fontSize: '0.9rem',
		},

		'& .MuiInputLabel-shrink': {
			transform: 'translate(18px, -6px) scale(0.75)',
			color: '#B2B2B2',
		},

		'& .MuiOutlinedInput-input' : {
			padding: '8px 14px',
			height: '28px',
			color: '#0C50AB',
		},

		'& .MuiFormHelperText-root.Mui-error' : {
			// Set color error
			color: '#E6265C',

			// Height error message
			height: 'auto',

			// Align & position the message
			display: 'flex',
			alignItems: 'center',
			padding: '0rem',

			// Absolute position and zIndex to put the error container over the top 
			zIndex: 20,
			position: 'relative',
			right: 'inherit',
			left: '0',
			bottom: '0',
			margin: '0',
			backgroundColor: '#fff',
		},
	},
};

export const CustomTextField = withStyles(formStyles)(TextField);


/* STYLED COMPONENTS */

/* === CONTAINERS === */
export const MainContainer = styled(Box)`
	position: absolute;
	width: 100%;
	min-height: 100vh;
	background: radial-gradient(circle, #4CC2F1 0%, #0D47A1 100%);
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const FormContainer = styled(Box)`
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 30px;
`;

export const LoginContainer = styled(Box)`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const PasswordInputContainer = styled(Box)`
	width: 100%;
	position: relative;
`;

export const RememberMeContainer = styled(Box)`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	height: auto;
`;

export const RememberMeTextContainer = styled(Box)`
	width: 100%;
	display: flex;
	align-items: center;
`;


/* === TEXTS === */
export const LoginTitle = styled(Text)`
	&& {
		color: #0C50AB;
		font-size: 1.8rem;
		margin-bottom: 1rem;
		text-align: center;
	}
`;

export const TextForm = styled(Text)`
	&& {
		font-family: 'WSMedium';
		font-size: 0.9rem;
		color: rgba(0,0,0,0.5);
		margin-bottom: 0px;
	}
`;

export const TextFormCheckbox = styled(TextForm)`
	cursor: pointer;
`;

export const LoginText = styled(Text)`
	&& {
		font-family: 'HVD';
		font-size: 0.9rem;
		color: #FFFFFF;
	}
`;


export const ErrorText = styled(Box)`
	color: #E6265C;
	font-size: 0.9rem;
	text-align: left;
	width: 100%;
`;


/* === BUTTONS === */
export const EyePasswordButton = styled.button`
	border: none;
	position: absolute;
	right: 0px;
	width: 25px;
	height: auto;
	transform: translate(-15px, 32px);
`;

export const LoginButton = styled.button`
	height: 41px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 20px;
	margin-top: 0.5rem;
	background: linear-gradient(135deg, #0093FF 0%, #00DEFF 100%);
	font-family: 'HVD';
	font-size: 0.9rem;
	color: #fff;
`;


/* === IMAGES === */
export const GoMojoLogo = styled(Image)`
	width: auto;
	height: 150px;
	margin-top: 2.5rem;
	margin-bottom: 2.5rem;
`;

export const EyePasswordImage = styled(Image)`
	width: 25px;
	height: auto;
`;