import styled from 'styled-components';
import {
	IconButton
} from "@chakra-ui/core";

export const QuitButton = styled(IconButton)`
`;
export const Modal = styled.div`
    position: fixed; /* Stay in place */
    z-index: 40; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.8); /* Black w/ opacity */
`;

export const ModalBody = styled.div`
    background-color: #fefefe;
    position:absolute;
    margin:auto;
    top:50%;
    transform:translateY(-50%);
    left: 10px;
    right: 10px;
    max-width:600px;
    max-height:600px;
    padding: 20px;
    border-radius:20px;

    @media (max-height: 700px) {
      top: 45%;
      max-height: 500px;
    }
`;

export const ModalTitle = styled.div`
    font-family:HVD;
    color:#0D47A1;
    text-transform:uppercase;
    text-align:center;
    font-size:35px;
`;

export const ModalText = styled.div`
    color:rgba(0,0,0,0.5);
    font-size:15px;
    font-family:WSMedium;
    text-align:center;
`;