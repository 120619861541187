import React, { useState } from 'react';
import MaterialTable from 'material-table';
import { getListSponsor } from '../../services/admin';
import moment from "moment";
const Sponsor = () => {
  const tableRef = React.createRef();
  const [tableData] = useState({
		columns: [
      {
				title: "Date inscription",
				field: 'createdAt',
				render: rowData=><p>{convertDate(rowData.createdAt,"DD/MM/YYYY")}</p>
			},
      {
				title: 'UserId',
				field: 'userId',
				editable: 'never'
			},
			{
				title: 'Code',
				field: 'code',
				editable: 'never'
			},
			{
				title: 'Email',
				field: 'email',
				editable: 'never'
			},
			{
				title: 'Actif',
				field: 'actif',
				editable: 'never'
			},
			{
				title: 'ParrainId',
				field: 'parrainId',
				editable: 'never'
			},
      {
				title: 'Groupe Retrib',
				field: 'groupRetrib',
				editable: 'never'
			},
		]
	});
  const convertDate = (date, format) => {
		if (date === null) {return;}
		moment.locale("fr");
		return moment(date).format(format);
	}
  const initInfoSponsor = async (resolve,query) => {
    let sort={field:"createdAt",value:-1};
    if (query.orderBy) {
      sort.field = query.orderBy.field;
      sort.value = (query.orderDirection === "asc")?1:-1;
    }
    const data = {nbPage:query.page,pageSize:query.pageSize,fieldSort:sort.field,valueSort:sort.value,search:query.search};
    const res = await getListSponsor(data);
    if(!res.data || !res.data.sponsors) return;
    const newInfo = [];
    for(let i = 0; i < res.data.sponsors.length; i++) {
      newInfo.push({
        email: res.data.sponsors[i].userId.email,
        createdAt: res.data.sponsors[i].createdAt,
        actif: res.data.sponsors[i].actif,
        code: res.data.sponsors[i].code,
        groupRetrib: res.data.sponsors[i].groupRetrib,
        parrainId: res.data.sponsors[i].parrainId,
        userId: res.data.sponsors[i].userId._id,
      });
    }
    resolve({
      data: newInfo,
      page:res.data.page,
      totalCount: res.data.total,
    });
  }
  return (
    <MaterialTable
      tableRef={tableRef}
      title="Sponsor User"
      columns={tableData.columns}
      data={query =>
        new Promise((resolve, reject) => {
          initInfoSponsor(resolve,query);
        })
      }
      options={{
        search: true,
        pageSize:10,
        pageSizeOptions:[5,10,25,50,100,200]
      }}
    />
  );
}
export default Sponsor;