import axios from 'axios';

import * as c from './constant';
import {  handler } from './handler';

export const getAllIBAN = async (page,pageSize,field,sort) => {
	try {
		const res = await axios.get(`${c.IBAN}/admin/${page}/${pageSize}/${field}/${sort}`);
		return res.data
	} catch (e) {
		throw handler(e);
	}
}

export const updateStatus = async (id,data) => {
	try {
		const res = await axios.patch(`${c.IBAN}/update/${id}`,data);
		return res.data
	} catch (e) {
		throw handler(e);
	}
}

export const getAllIBANWithFilter = async (data) => {
	try {
		const res = await axios.post(`${c.IBAN}/admin/getAllIbanFilter`,data);
		return res.data
	} catch (e) {
		throw handler(e);
	}
}

export const getIBANWithFilter = async (data) => {
	try {
		const res = await axios.post(`${c.IBAN}/admin/getIBANWithFilter`,data);
		return res.data
	} catch (e) {
		throw handler(e);
	}
}