import axios from 'axios';

import { GOLOTO, GOCASH } from './constant';

export const retribGoloto = async (data) => {	
    const res = await axios.post(`${GOLOTO}/retribGains`,data);
    return res.data
}

export const retribGoCash = async (data) => {	
    const res = await axios.post(`${GOCASH}/retribGains`,data);
    return res.data
}
