import React, { useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import { getJailedUserSignaled, removeJailedUserSignaled, showMoreInfoUserSignaled, jailedSignaledUser, unJailedUserById } from '../../services/admin';
import PhotoIcon from '@material-ui/icons/Photo';
import moment from "moment";
const WaitingJailed = () => {
  const tableRef = React.createRef();
  const tableRef2 = React.createRef();
  const [email, setEmail] = useState('');
  const [reason, setReason] = useState('');
  const [emailJailed, setEmailJailed] = useState('');
  const [tableData] = useState({
		columns: [
      {
				title: 'UserId',
				field: 'userId',
				editable: 'never'
			},
			{
				title: 'Pseudo',
				field: 'username',
				editable: 'never'
			},
      {
				title: "Date inscription",
				field: 'createdAt',
				render: rowData=><p>{convertDate(rowData.createdAt,"DD/MM/YYYY")}</p>
			},
			{
				title: 'Nb signalement',
				field: 'nbSignaled',
				editable: 'never'
			},
			{
				title: 'Nb signalement depuis RAS',
				field: 'nbSignaledRAS',
				editable: 'never'
			},
			{
				title: `Solde Win`,
				field: 'soldeWin',
        render: rowData=><p>{(rowData.soldeWin / 100).toFixed(2)}</p>
			},
      {
				title: `Solde`,
				field: 'solde',
        render: rowData=><p>{(rowData.solde / 100).toFixed(2)}</p>
			},
      {
				title: `Credit`,
				field: 'credit',
				editable: 'never'
			},
      {
				title: `Description`,
				field: 'biography',
				editable: 'never'
			},
      {
				title: `Profile`,
				field: 'profileImage',
				editable: 'never',
        render:rowData =>(rowData.profileImage)? <a href={rowData.profileImage} target="_blank" rel="noopener noreferrer" title="nouvelle fenêtre"><PhotoIcon/></a>:null
			},
      {
				title: 'Voir plus',
				field: 'seeMore',
				render:rowData =><button onClick={() => setEmail(rowData.email)}>OK</button>
			},
			{
				title: 'RAS',
				field: 'ras',
				render:rowData =><button onClick={() => rasUser(rowData.email)}>OK</button>
			},
      {
				title: 'Jailed',
				field: 'jailed',
				render:rowData =><button onClick={() => setEmailJailed(rowData.email)}>OK</button>
			},
		]
	});

  const [tableData2] = useState({
		columns: [
			{
				title: 'Raison',
				field: 'reason',
				editable: 'never'
			},
      {
				title: "Date signalement",
				field: 'createdAt',
				render: rowData=><p>{convertDate(rowData.createdAt,"DD/MM/YYYY")}</p>
			},
			{
				title: 'Id signaleur',
				field: 'userWhoSignaledId',
				editable: 'never'
			},
		]
	});
  const convertDate = (date, format) => {
		if (date === null) {return;}
		moment.locale("fr");
		return moment(date).format(format);
	}

  const rasUser = async email => {
    await removeJailedUserSignaled({email});
    if(tableRef.current) tableRef.current.onQueryChange();
  }

  const jailedUser = async email => {
    await jailedSignaledUser({email, reason});
    setReason('');
    if(tableRef.current) tableRef.current.onQueryChange();
  }
  const initWaitList = async (resolve,query) => {
    let sort={field:"createdAt",value:-1};
    if (query.orderBy) {
      sort.field = query.orderBy.field;
      sort.value = (query.orderDirection === "asc")?1:-1;
    }
    const data = {nbPage:query.page,pageSize:query.pageSize,fieldSort:sort.field,valueSort:sort.value,search:query.search,jailed: false};
    const res = await getJailedUserSignaled(data);
    if(!res.data || !res.data.users) {
      resolve({
        data: [],
        page: 0,
        totalCount: 0,
      });
      return;
    }
    resolve({
      data: res.data.users,
      page:res.data.page,
      totalCount: res.data.total,
    });
  }

  const initShowMoreInfo = async (resolve,query) => {
    if(email.length === 0) {
      resolve({
        data: [],
        page: 0,
        totalCount: 0,
      });
      return;
    }
    let sort={field:"createdAt",value:-1};
    if (query.orderBy) {
      sort.field = query.orderBy.field;
      sort.value = (query.orderDirection === "asc")?1:-1;
    }
    const data = {nbPage:query.page,pageSize:query.pageSize,fieldSort:sort.field,valueSort:sort.value,email};
    const res = await showMoreInfoUserSignaled(data);
    if(!res.data || !res.data.users) {
      resolve({
        data: [],
        page: 0,
        totalCount: 0,
      });
      return;
    }
    resolve({
      data: res.data.users,
      page:res.data.page,
      totalCount: res.data.total,
    });
  }
  useEffect(() => {
    if(email.length > 0 && tableRef2.current) tableRef2.current.onQueryChange();
  }, [email]);

  const goBackList = () => {
    setEmail('');
  }
  const handleReasonChange = (event) => {
    setReason(event.target.value);
  }
  
  useEffect(() => {
    if(emailJailed.length > 0) jailedUser(emailJailed);
  }, [emailJailed]);

  return (
    <div>
      <div style={{display: email.length === 0 ? 'none': 'block'}}>
      <button style={{backgroundColor: '#0D47A1', padding: '10px 20px', borderRadius: 20, color:  '#fff', fontSize: 20, marginBottom: 20}} onClick={goBackList}>Affichage liste</button>
        <MaterialTable
          tableRef={tableRef2}
          title={`List Signalement ${email}`}
          columns={tableData2.columns}
          data={query =>
            new Promise((resolve, reject) => {
              initShowMoreInfo(resolve,query);
            })
          }
          options={{
            search: false,
            pageSize:10,
            pageSizeOptions:[5,10,25,50,100,200]
          }}
        />
      </div>
    <div style={{display: email.length > 0 ? 'none': 'block'}}>
      <div style={{flexDirection:'column', display: 'flex', width: 200, marginBottom: 20}}>
        <label>Raison : </label>
        <textarea value={reason} onChange={handleReasonChange} />
      </div>

      <MaterialTable
        tableRef={tableRef}
        title="Joueur signalé"
        columns={tableData.columns}
        data={query =>
          new Promise((resolve, reject) => {
            initWaitList(resolve,query);
          })
        }
        options={{
          search: true,
          pageSize:10,
          pageSizeOptions:[5,10,25,50,100,200]
        }}
        />
    </div>
    </div>
  );
}
export default WaitingJailed;