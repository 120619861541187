import React from 'react';
import {
	QuitButton,
	Modal,
	ModalBody,
	ModalTitle,
	ModalText
} from './ModalInfoStyles';

const ModalInfo = ({close,title,contenu}) => {
	return (
		<Modal zIndex="3010">
			<ModalBody zIndex="3010" borderRadius={30} padding={['0.7rem', '0.7rem', '1.5rem']} alignItems='center' marginX={['1rem', '1rem', 0]}>
				<ModalTitle>
					{title}
				</ModalTitle>
				<ModalText>
					{contenu}
				</ModalText>
			</ModalBody>
			<QuitButton
				icon="close"
				onClick={close}
				isRound
				backgroundColor='#fff'
				pos="fixed"
				right="0"
				left="-23px"
				marginLeft="50%"
				bottom="2rem"
				zIndex="1500"
				_hover={{bg: "#fff", opacity: 0.5}}
			/>
		</Modal>
	);
}

export default ModalInfo;