import styled, {  } from 'styled-components';
import {
	Box,
} from "@chakra-ui/core";


/* STYLED COMPONENTS */
export const Container = styled(Box)`
	flex-grow: 1;
    margin-left: 150px;
    background-color:#f9fafb!important;
    height:100%;
    overflow:auto;
    padding:20px;
`;
