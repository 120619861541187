import React, { useState } from 'react';
import MaterialTable from 'material-table';
import { getParrainJailed, jailedParrain, removeJailedParrain } from '../../services/admin';
import moment from "moment";
const WaitingJailed = () => {
  const tableRef = React.createRef();
  const [tableData] = useState({
		columns: [
      {
				title: 'UserId',
				field: '_id',
				editable: 'never'
			},
      {
				title: "Date alerte",
				field: 'createdAt',
				render: rowData=><p>{convertDate(rowData.createdAt,"DD/MM/YYYY")}</p>
			},
			{
				title: 'Pseudo',
				field: 'username',
				editable: 'never'
			},
			{
				title: 'Email',
				field: 'email',
				editable: 'never'
			},
			{
				title: 'Nb Filleul',
				field: 'nbFilleul',
				editable: 'never'
			},
			{
				title: `% d'activités des filleuls`,
				field: 'percent',
				editable: 'never'
			},
			{
				title: 'UnJailed',
				field: 'unjailed',
				render:rowData =><button onClick={() => unJailed(rowData.email)}>OK</button>
			},
      {
				title: 'Jailed',
				field: 'jailed',
				render:rowData =><button onClick={() => jailedUser(rowData.email)}>OK</button>
			},
		]
	});
  const convertDate = (date, format) => {
		if (date === null) {return;}
		moment.locale("fr");
		return moment(date).format(format);
	}
  const unJailed = async email => {
    await removeJailedParrain({email});
    tableRef.current.onQueryChange();
  }

  const jailedUser = async email => {
    await jailedParrain({email});
  }
  const initParrainWaitList = async (resolve,query) => {
    let sort={field:"createdAt",value:-1};
    if (query.orderBy) {
      sort.field = query.orderBy.field;
      sort.value = (query.orderDirection === "asc")?1:-1;
    }
    const data = {nbPage:query.page,pageSize:query.pageSize,fieldSort:sort.field,valueSort:sort.value,search:query.search,jailed: false};
    const res = await getParrainJailed(data);
    if(!res.data || !res.data.parrains) {
      resolve({
        data: [],
        page: 0,
        totalCount: 0,
      });
      return;
    }
    const newInfo = [];
    for(let i = 0; i < res.data.parrains.length; i++) {
      newInfo.push({
        _id: res.data.parrains[i].userId._id,
        email: res.data.parrains[i].userId.email,
        username: res.data.parrains[i].userId.username,
        createdAt: res.data.parrains[i].createdAt,
        nbFilleul: res.data.parrains[i].nbFilleul ?? 0,
        percent: !res.data.parrains[i].nbFilleul ? 0: (res.data.parrains[i].nbFilleulActif/res.data.parrains[i].nbFilleul)*100,
      });
    }
    resolve({
      data: newInfo,
      page:res.data.page,
      totalCount: res.data.total,
    });
  }
  return (
    <MaterialTable
      tableRef={tableRef}
      title="Utilisateur à vérifier"
      columns={tableData.columns}
      data={query =>
        new Promise((resolve, reject) => {
          initParrainWaitList(resolve,query);
        })
      }
      options={{
        search: true,
        pageSize:10,
        pageSizeOptions:[5,10,25,50,100,200]
      }}
    />
  );
}
export default WaitingJailed;