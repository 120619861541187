import React, { useState, useEffect } from 'react';
import {
	useStyles,
	SubmitButton,
	Container,
	ErrorContainer,
	VerifButton
} from'./styles';
import {
	ErrorText,
	CustomTextField
} from'../Login/styles';
import { CircularProgress, Select } from "@chakra-ui/core";
import moment from "moment";
import { getAllGrillNotRetrib, verifyManualRetrib } from '../../services/admin';
import { retribGoloto, retribGoCash } from '../../services/retrib';
const games = [
	{label:'Go Mojo',name:"goloto"},
	{label:'Go 50',name:"50"},
	{label:'Go 100',name:"100"},
	{label:'Go 1000',name:"1000"}
];
function Retrib(){
	const classes = useStyles();
	const [error, setError] = useState(null);
	const [loading,setLoading] = useState(false);
	const [tirageId,setTirageId] = useState("");
	const [tirageName,setTirageName] = useState("");

	const [gameRetrib,setGameRetrib] = useState(null);
	const [infoVerif,setInfoVerif] = useState(null);

	const onSelect = (e) => setTirageName(e.target.value);

	const sendRetrib = async (tirageId, tirageName) => {
		if(tirageId.length === 0) {
			setError("Vous devez choisir l'id du tirage.")
			return;
		}
		if(tirageName.length === 0){
			setError("Vous devez choisir le nom du tirage.")
			return;
		}
		setLoading(true);
		if(error) setError(null);
		try {
			if(tirageName === 'goloto') {
				await retribGoloto({"lotoId":tirageId});
			} else {
				await retribGoCash({cash:{"cashId":tirageId,"gameName":tirageName}});
			}
			setLoading(false);
			alert("Tirage retribué");
			setTirageId("");
			setTirageName("");
		} catch(e) {
			setError(e.message);
			setLoading(false);
		}
	}

	const retrieveGrillToRetrib = async () => {
		try {
			setGameRetrib(await getAllGrillNotRetrib());
		} catch(e) {
			console.error(e);
		}
	}

	const verifyRetribLoto = async (grillName,grillId) => {
		try {
			setInfoVerif(null);
			setInfoVerif(await verifyManualRetrib({grillName,grillId}));
		} catch(e) {
			console.error(e);
		}
	}
	const verifyRetribGoCash = async (grillName,cashName, grillId) => {
		try {
			setInfoVerif(null);
			setInfoVerif(await verifyManualRetrib({grillName,cashName, grillId}));
		} catch(e) {
			console.error(e);
		}
	}
	

	const convertDate = (date, format) => {
		if (date === null) {return;}
		moment.locale("fr");
		return moment(date).format(format);
	}

    useEffect(()=> {
    	retrieveGrillToRetrib();
    },[])
	return(
		<div style={{overflow:"none",margin:20}}>
			<ErrorContainer>
			{(error && typeof(error) === "string") && (
				<ErrorText>
					{error}
				</ErrorText>
			)}
			</ErrorContainer>
			<Container>
				<div style={{maxWidth:200}}>
					<Select placeholder="Select game" onChange={onSelect} style={{marginTop:20,borderRadius:15}}>
						{games.map((game,i)=>{
							return(
								<option value={game.name} key={i}>{game.label}</option>
								);
							})}
					</Select>
				</div>
				<CustomTextField
					InputProps={{ className: classes.textInput }}
					variant="outlined"
					margin="normal"
					value={tirageId}
					name={"Tirage ID"}
					label={"Tirage ID"}
					onChange={(data)=>setTirageId(data.target.value)}
				/>				
				<SubmitButton onClick={() => loading ? '' : sendRetrib(tirageId, tirageName)}>
					{
						loading ? (
							<CircularProgress isIndeterminate color="blue" size='2rem'/>
						) : (
							'Retrib'
						)
					}
				</SubmitButton>
			</Container>
			{infoVerif &&
				<div style={{marginTop: 20}}>
					<p style={{textAlign:"center"}}>Information user</p>
					<table style={{width:"100%"}}>
					<thead>
						<tr>
							<th>Grille Valide</th>
							<th>Nombre de coffre vue</th>
							<th>Nombre de grille joué</th>
							<th>User vérifié</th>
							<th>Date de création du joueur</th>
							<th>User solde</th>
							<th>User credit</th>
							<th>Nombre de demande de virement</th>
							<th style={{minWidth: 150}}>Status iban</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>{infoVerif.isValidGrill?"Oui":"Non"}</td>
							<td>{infoVerif.nbChest}</td>
							<td>{infoVerif.nbGrill}</td>
							<td>{infoVerif.user.isVerified?"Oui":"Non"}</td>
							<td>{convertDate(infoVerif.user.createdAt,"DD-MM-YYYY")}</td>
							<td>{infoVerif.user.solde}</td>
							<td>{infoVerif.user.credit}</td>
							<td>{infoVerif.nbWaitCashout}</td>
							{infoVerif.ibanUser ?
								<td>
									<p>CNI recto : {infoVerif.ibanUser.statusCardIdentity}</p>
									<p>CNI verso : {infoVerif.ibanUser.statusCardIdentityVerso}</p>
									<p>Iban : {infoVerif.ibanUser.statusIban}</p>
									<p>RIB : {infoVerif.ibanUser.statusRib}</p>
								</td>
							:
								<td>Pas d'iban renseigné.</td>
							}
						</tr>
					</tbody>
					</table>
				</div>
			}
			{gameRetrib &&
				<>
				<div style={{marginTop: 20}}>
					<p>GoCash ticket à retribué :</p>
					{gameRetrib.cashManualRetrib.length === 0 ?
						<p>Aucune grilles à retribué.</p>
					:
					<Container>
					<table style={{width:"100%"}}>
						<thead>
							<tr>
								<th>Tirage</th>
								<th>Game Name</th>
								<th>Date de création</th>
								<th>Numéros</th>
								<th>Etoile</th>
								<th>Vérification</th>
								<th>Rétribution</th>
							</tr>
						</thead>
						<tbody>
							{gameRetrib.cashManualRetrib.map((cashToRetrib, i)=> {
								return(
									<tr key={i}>
										<td>{cashToRetrib.tirage}</td>
										<td>{cashToRetrib.cashName}</td>
										<td> {convertDate(cashToRetrib.createdAt,"DD-MM-YYYY")}</td>
										<td>{cashToRetrib.numbers.join(',')}</td>
										<td>{cashToRetrib.stars[0]}</td>
										<td><VerifButton onClick={()=>verifyRetribGoCash("gocash",cashToRetrib.cashName, cashToRetrib._id)}>OK</VerifButton></td>
										<td><VerifButton onClick={()=>sendRetrib(cashToRetrib.tirage, cashToRetrib.cashName)}>OK</VerifButton></td>
									</tr>
								);
							})}
						</tbody>
					</table>
					</Container>
					}
				</div>
				<div style={{marginTop: 20}}>
					<p>Go Mojo ticket à retribué :</p>
					{gameRetrib.gomojoManualRetrib.length === 0 ?
						<p>Aucune grilles à retribué.</p>
					:
					<Container>
						<table style={{width:"100%"}}>
							<thead>
								<tr>
									<th>Tirage</th>
									<th>Date de création</th>
									<th>Numéros</th>
									<th>Etoile</th>
									<th>Vérification</th>
									<th>Rétribution</th>
								</tr>
							</thead>
							<tbody>
								{gameRetrib.gomojoManualRetrib.map((gomojoRetrib, i)=> {
									return(
										<tr key={i}>
											<td>{gomojoRetrib.golotoId}</td>
											<td> {convertDate(gomojoRetrib.createdAt,"DD-MM-YYYY")}</td>
											<td>{gomojoRetrib.grille_numbers.join(',')}</td>
											<td>{gomojoRetrib.grille_stars}</td>
											<td><VerifButton onClick={()=>verifyRetribLoto("goloto",gomojoRetrib._id)}>OK</VerifButton></td>
											<td><VerifButton onClick={()=>sendRetrib(gomojoRetrib.golotoId, 'goloto')}>OK</VerifButton></td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</Container>
					}
				</div>
				</>
			}
		</div>
	);
}

export default Retrib;