import styled, {  } from 'styled-components';
import {
	Box,
	Text
} from "@chakra-ui/core";


/* STYLED COMPONENTS */
export const Container = styled(Box)`
	margin-top:20px;
`;

export const TitleText = styled(Text)`
	font-size:17px;
`;

export const NoDataText = styled(Text)`
	font-size:15px;
	color:red;
`;