import React, { useState, component } from 'react';
import Switch from "react-switch";
import MaterialTable from 'material-table';
import * as apiIban from '../../services/iban';
import * as apiRedcheck from '../../services/redcheck';
import filterValidate from './filterValidate';
import moment from "moment";

const fieldUpdate=["statusRib","statusIban","statusCardIdentity","statusCardIdentityVerso","statusRedcheck","cardIdentityURL","cardIdentityVersoURL","ribURL"];
const fieldUpdateRedcheck=["resultIbanOCR","statusIbanOCR","statusRibOCR","statusCardIdentityOCR","resultCardIdentityOCR","statusCardIdentityVersoOCR","resultCardIdentityVersoOCR"];
const subFilter = [];
const documents={
    IBAN: {
        name: 'IBAN',
        url: 'ribURL',
        ocr: 'resultIbanOCR',
        status: 'statusIbanOCR',
        _status: 'statusIban',
        ocr_check : ['IBAN','rawtext'],
        data_check : ['iban'],
        allowed: ['RIB_FR'],
        verso_not_required: {},
    },
    RIB:{
        name: 'RIB',
        url: 'ribURL',
        ocr: 'resultIbanOCR',
        status: 'statusRibOCR',
        _status: 'statusRib',
        ocr_check : ['NAME', 'rawtext'],
        data_check : ['lastname', 'firstname'],
        allowed: ['RIB_FR'],
        verso_not_required: {},
    },
    ID:{
        name: 'ID',
        url: 'cardIdentityURL',
        status: 'statusCardIdentityOCR',
        ocr: 'resultCardIdentityOCR',
        _status: 'statusCardIdentity',
        ocr_check : ['lastname', 'firstname', 'birthname','rawtext'],
        data_check : ['lastname', 'firstname'],
        allowed: ['CNI','CNI2','PASSPORT','LICENCE','TITRE'],
        verso_not_required: {CNI2: 'statusCardIdentityVerso', PASSPORT: 'statusCardIdentityVerso', LICENCE: 'statusCardIdentityVerso', TITRE: 'statusCardIdentityVerso'},
    },
    ID2:{
        name: 'ID2',
        url: 'cardIdentityVersoURL',
        status: 'statusCardIdentityVersoOCR',
        ocr: 'resultCardIdentityVersoOCR',
        _status: 'statusCardIdentityVerso',
        ocr_check : ['document'],
        data_check : ['document'],
        allowed:  ['CNI_VERSO','TITRE_VERSO'],
        verso_not_required: {CNI2: 'statusCardIdentityVerso', PASSPORT: 'statusCardIdentityVerso', LICENCE: 'statusCardIdentityVerso', TITRE: 'statusCardIdentityVerso'},
    }};
    
function Redcheck(){
    const [filter, setFilter] = useState('pending');
    const [action, setAction] = useState();
	const [subfilter, setSubFilter] = useState(false);
    const [update, setUpdate] = useState(false);
	const [DEBUG_, setDebug] = useState(false);
    const [cpt, setCpt] = useState(0);
    const [cpt_, setCpt_] = useState(0);
    const [cpt__, setCpt__] = useState(0);
	let DEBUG = false;
    let tableRef = React.createRef();
    const [tableData] = useState({
        columns: [
            {
                title: 'Nom',
                field: 'firstname'
            },
            {
                title: 'Prénom',
                field: 'lastname'
            },
            {
                title: 'IBAN',
                field: 'iban',
                editable: 'never'
            },
            {
                title: 'RIB URL',
                field: 'ribURL',
                editable: 'never',
                render:rowData => 
                <>
                {(rowData.ribURL
                    ? <a href={rowData.ribURL} target="_blank" rel="noopener noreferrer" title={displayJSON(rowData.resultIbanOCR)}>{(rowData.ribURL.split('.').pop() === 'pdf' ? 'PDF' : <img src={rowData.ribURL} width='100px' heigth='100px'></img>)}</a>
                    : ''
                )}
                {(rowData.ribURL_ && rowData.SYNC ? 'SYNC' : rowData.ribURL_ && rowData.SEARCH ? 'SEARCH' : '')}
                {(rowData.ribURL_
                    ? <a href={rowData.ribURL_} target="_blank" rel="noopener noreferrer">{(rowData.ribURL_.split('.').pop() === 'pdf' ? 'PDF' : <img src={rowData.ribURL_} width='100px' heigth='100px'></img>)}</a>
                    : ''
                )}
                </>
            },
            {
                title: 'IBAN STATUS',
                field: 'statusIbanOCR',
                render:rowData => (rowData.statusIban === 'validate') ? rowData.statusIban : (rowData.statusIbanOCR)? !/match|not_match|unknown/.test(rowData.statusIbanOCR) ? rowData.statusIbanOCR : <>{rowData.statusIbanOCR}<button style={{backgroundColor:'#006600', padding: 10, borderRadius: 10, opacity: 1, color:'#FFFFFF'}} disabled={false} onClick={() => new Promise(async () => {
                    rowData = await humanValidation('statusIban', rowData);
                    setUpdate(true);
                })}>Valider</button></>:null
            },
            {
                title: 'RIB STATUS',
                field: 'statusIbanOCR',
                render:rowData => (rowData.statusRib === 'validate') ? rowData.statusRib : (rowData.statusRibOCR)? !/match|not_match|unknown/.test(rowData.statusRibOCR) ? rowData.statusRibOCR : <>{rowData.statusRibOCR}<button style={{backgroundColor:'#006600', padding: 10, borderRadius: 10, opacity: 1, color:'#FFFFFF'}} disabled={false} onClick={() => new Promise(async () => {
                    rowData = await humanValidation('statusRib', rowData);
                    setUpdate(true);
                })}>Valider</button></>:null
            },
            {
                title: 'ID URL',
                field: 'cardIdentityURL',
                editable: 'never',
                render:rowData => 
                <>
                {(rowData.cardIdentityURL
                    ? <a href={rowData.cardIdentityURL} target="_blank" rel="noopener noreferrer" title={displayJSON(rowData.resultCardIdentityOCR)}>{(rowData.cardIdentityURL.split('.').pop() === 'pdf' ? 'PDF' : <img src={rowData.cardIdentityURL} width='100px' heigth='100px'></img>)}</a>
                    : ''
                )}
                 {(rowData.cardIdentityURL_ && rowData.SYNC ? 'SYNC' : rowData.cardIdentityURL_ && rowData.SEARCH ? 'SEARCH' : '')}
                {(rowData.cardIdentityURL_
                    ? <a href={rowData.cardIdentityURL_} target="_blank" rel="noopener noreferrer">{(rowData.cardIdentityURL_.split('.').pop() === 'pdf' ? 'PDF' : <img src={rowData.cardIdentityURL_} width='100px' heigth='100px'></img>)}</a>
                    : ''
                )}
                </>
            },
            {
                title: 'ID STATUS',
                field: 'statusCardIdentityOCR',
                editable: 'never',
                render:rowData => (rowData.statusCardIdentity === 'validate') ? rowData.statusCardIdentity : (rowData.statusCardIdentityOCR)? !/match|not_match|unknown/.test(rowData.statusCardIdentityOCR) ? rowData.statusCardIdentityOCR : <>{rowData.statusCardIdentityOCR}<button style={{backgroundColor:'#006600', padding: 10, borderRadius: 10, opacity: 1, color:'#FFFFFF'}} disabled={false} onClick={() => new Promise(async () => {
                    rowData = await humanValidation('statusCardIdentity', rowData);
                    setUpdate(true);
                })}>Valider</button></>:null
            },
            {
                title: 'ID Verso URL',
                field: 'cardIdentityVersoURL',
                editable: 'never',
                render:rowData => 
                <>
                {(rowData.cardIdentityVersoURL
                    ? <a href={rowData.cardIdentityVersoURL} target="_blank" rel="noopener noreferrer" title={displayJSON(rowData.resultCardIdentityVersoOCR)}>{(rowData.cardIdentityVersoURL.split('.').pop() === 'pdf' ? 'PDF' : <img src={rowData.cardIdentityVersoURL} width='100px' heigth='100px'></img>)}</a>
                    : ''
                )}
                 {(rowData.cardIdentityVersoURL_ && rowData.SYNC ? 'SYNC' : rowData.cardIdentityVersoURL_ && rowData.SEARCH ? 'SEARCH' : '')}
                {(rowData.cardIdentityVersoURL_
                    ? <a href={rowData.cardIdentityVersoURL_} target="_blank" rel="noopener noreferrer">{(rowData.cardIdentityVersoURL_.split('.').pop() === 'pdf' ? 'PDF' : <img src={rowData.cardIdentityVersoURL_} width='100px' heigth='100px'></img>)}</a>
                    : ''
                )}
                </>
            },
            {
                title: 'ID Verso STATUS',
                field: 'CardIdentityResultOCR',
                editable: 'never',
                render:rowData =>(rowData.statusCardIdentityVerso === 'validate') ? rowData.statusCardIdentityVerso : (rowData.statusCardIdentityVersoOCR)? !/match|not_match|unknown/.test(rowData.statusCardIdentityVersoOCR) ? rowData.statusCardIdentityVersoOCR : <>{rowData.statusCardIdentityVersoOCR}<button style={{backgroundColor:'#006600', padding: 10, borderRadius: 10, opacity: 1, color:'#FFFFFF'}} disabled={false} onClick={() => new Promise(async () => {
                    rowData = await humanValidation('statusCardIdentityVerso', rowData);
                    setUpdate(true);
                })}>Valider</button></>:null
            },
            {
                title: "Date de modification",
                field: 'updatedAt',
                editable: 'never',
                render: rowData=><p>{convertDate(rowData.updatedAt,"DD/MM/YYYY")}</p>
            },
            {
                title: "REDCHECK",
                editable: 'never',
                render:rowData => 
                <>
                {(rowData.SEARCH
                    ? <button style={{backgroundColor:'#C8C8C8', padding: 20, marginBottom: 10, borderRadius: 20, opacity: (rowData.SEARCH ? 1 : 0 )}} disabled={false} onClick={() => new Promise(async () => {
                        setUpdate(false);
                        rowData = await resyncDocument(rowData);
                        setUpdate(true);
                    })}>Search</button>
                    : ''
                )}
                {(rowData.SYNC
                    ? <button style={{backgroundColor:'#C8C8C8', padding: 20, marginBottom: 10, borderRadius: 20, opacity: (rowData.SYNC ? 1 : 0 )}} disabled={false} onClick={() => new Promise(async () => {
                        setUpdate(false);
                        rowData = await resyncDocument(rowData);
                        setUpdate(true);
                    })}>Sync</button>
                    : ''
                )}
                {(rowData.SWITCH
                    ?  <button style={{backgroundColor:'#C8C8C8', padding: 20, marginBottom: 10, borderRadius: 20, opacity: (rowData.SWITCH ? 1 : 0 )}} disabled={false} onClick={() => new Promise(async () => {
                        setUpdate(false);
                        rowData = await resyncDocument(rowData);
                        setUpdate(true);
                    })}>Switch</button>
                    : ''
                )}
                {(rowData.filter !== 'done' || rowData.DEBUG 
                    ? <button style={{backgroundColor:(rowData.statusRedcheck ?  '#C8C8C8' : '#FF0000'), padding: 20, borderRadius: 20, opacity: (rowData.filter !== 'done' || rowData.DEBUG ?  1 : 0)}} disabled={false} onClick={() => new Promise(async () => {
                        setUpdate(false);
                        rowData = await updateRedcheck(rowData);
                        setUpdate(true);
                    })}>RedCheck</button>
                    : ''
                )}
                </>
            },
        ]
    });

    const displayJSON = (json) => {
        if (json){
            let json_txt='';
            json = (typeof json === 'object' ? json : JSON.parse(json));
            if (json['ocr']){
                Object.keys(json.ocr).forEach((key) => {
                    json_txt += (json.ocr[key] !== 'UNDEFINED' ? key +': '+json.ocr[key]+'\n' : '');
                });
            } else return JSON.stringify(json);
            return json_txt;
        }
    }

    const formatObjectJSON = (json) => {
        return typeof json === 'object' ? json : JSON.parse(json);
    }

    const convertDate = (date, format) => {
        if (date === null) {return;}
        moment.locale("fr");
        return moment(date).format(format);
    }

    const OcrUploadFile = async (file, document) => {
        const data = {fileName:file};
        const response = await apiRedcheck.processRedcheck(data);
        return response;
    }

    const normalizeString = (str) => {
		return str.trim().toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }

	const returnKeyValue = (keys, values) => {
		const key = [];
		if (keys !== null) {keys.map((k) => { if (typeof values[k] === 'string' && values[k] !== 'UNDEFINED' && values[k] !== '' ) key[k] = normalizeString(values[k]);});}
		else return null;
		return key;
	}

    const getMatch = (o, d) => {
        
        if (o !== null) {
			let ocr_txt = '';
			let result = {};
			let match = { result_length: 0 };

			Object.keys(o).map((s) => ocr_txt += o[s] );

			if (DEBUG)console.log("OCR_TXT :",ocr_txt);

            Object.keys(d).map((w) => {
				
				if (DEBUG)console.log('With Space',w,d[w],':',ocr_txt.includes(d[w]));
				
				if (ocr_txt.includes(d[w]))result[w] = d[w];
				else {
					if (DEBUG)console.log('Without Space',w,d[w],':',ocr_txt.replace(/\s+/g, '').includes(d[w].replace(/\s+/g, '')));	
					if (ocr_txt.replace(/\s+/g, '').includes(d[w].replace(/\s+/g, ''))) result[w] = d[w];
					else {
						//Check match with split space
						let result_ = [];
						const d_ = d[w].split(' ');
						if (d_.length > 0) {
							d_.map((w_) => {
								if (DEBUG)console.log('Split Spaces',w,w_,':',ocr_txt.includes(w_));
								if (ocr_txt.includes(w_)) result_.push(w_); 
							});
						}
						if ( d_.length - result_.length === 0 ) result[w] = d[w];
						else match = { d_length: d_.length, result_length: result_.length, score: ((result_.length*100)/d_.length).toFixed(0) };
						if (DEBUG)console.log('match:', match);
					}
				}
			});
			return (Object.keys(result).length === Object.keys(d).length) ? 'validate' : (Object.keys(result).length > 0 ? 'match:'+Object.keys(result).length+'/'+Object.keys(d).length : match.result_length > 0  ? 'match:'+match.result_length+'/'+match.d_length+'\n score:'+match.score+'%' : 'not_match');

		} else if (o === d) return 'validate';

		return 'not_match';
    }          

    /*const getMatchDocument = (d_) => {
        let d = false;
        Object.keys(documents).forEach(async (key) => {
            if (documents[key].allowed.includes(d_)) { 
                d = documents[key];
                return;
            }
        });
        return d;
    }

    const switch_ = (d, to, datas) => {
        let _datas = { data:datas.data, newData:datas.newData, redcheckData:datas.redcheckData };
        if (to){
			if (DEBUG) console.log('Switch:', d.name, '<->', to.name, datas.data[to._status] !== 'validate' || (datas.data.filter === 'done' && datas.data.action === 'force'));
			if (datas.data[to._status] !== 'validate' || datas.data.action === 'force') {
				keysSwitch.forEach((key) => {
					const tk = [[d[key], to[key]],[to[key], d[key]]];
					Object.keys(tk).forEach((e) => {
						if (Object.keys(datas.data).includes(tk[e][0])){
							if (fieldUpdate.includes(tk[e][0]))_datas.newData[tk[e][1]] = datas.data[tk[e][0]];
							if (fieldUpdateRedcheck.includes(tk[e][0]))_datas.redcheckData[tk[e][1]] = datas.data[tk[e][0]];
						}
					});
				});
				d = to;
				datas = _datas;
			}
        }
        return { datas, d };
    }*/

    const switch_ID = async (data) => {
        const newData = { 
            cardIdentityURL: data.cardIdentityVersoURL,
            cardIdentityVersoURL: data.cardIdentityURL,
            publicIdIdentity : data.cardIdentityURL.split('/').pop().split('.')[0],
            publicIdIdentityVerso : data.cardIdentityVersoURL.split('/').pop().split('.')[0],
        }
        await apiIban.updateStatus(data._id, newData);
        data['cardIdentityURL'] = newData['cardIdentityURL'];
        data['cardIdentityVersoURL'] = newData['cardIdentityVersoURL'];
        return data;
    }

    /*const checkDocumentName = (d, to,datas) => {
        let res = { datas, d };
        if (to !== 'UNKNOWN'){
            if (!d.allowed.includes(to)) {
                res = switch_(d, getMatchDocument(to), datas);
            }
        }
		res.datas.data['document'] = to;
        return res;
    }

	const getDataOcr = async (datas, d) => {
		const isCache = datas.data.action === 'force' || datas.data.action === 'redcheck' ? false : datas.data[d.ocr] ? 'data' : datas.redcheckData[d.ocr] ? 'redcheckData' : false;
		if (DEBUG) console.log('isCacheOCR:', isCache);
        return !isCache ? await OcrUploadFile(datas.data[d.url],d.name) : { code:200, data: formatObjectJSON(isCache === 'data' ? datas.data[d.ocr] : datas.redcheckData[d.ocr]) };
	}*/

	const isCheckDocument = (datas, documents, key) => {
		return (datas.data[documents[key]._status] !== 'validate' || DEBUG)
	}

	const isMandatory = (datas, type) => {
		
		let mandatory = true;

		// VERSO 
		if (type === 'ID2'){
			const d = documents[type];
			const status = datas.data['statusCardIdentity'];
			if (datas.data['resultCardIdentityOCR']){
				let ocr = formatObjectJSON(datas.data['resultCardIdentityOCR']);

				if (ocr['document'] && d.verso_not_required(ocr['document'])) {
					datas.redcheckData['statusCardIdentityVersoOCR'] = 'not_mandatory';
					if (status === 'validate' && datas.data['statusCardIdentityVerso'] !== 'validate') datas.newData['statusCardIdentityVerso'] = 'validate';
					mandatory = false;
					if (DEBUG) console.log("VERSO not mandatory : validate");
				}
			}
		}
		return { datas, mandatory };
	}

    const checkDocument = async (datas, type) => {
        let d = documents[type];
        datas.redcheckData[d.status] = 'required';
		const isM = isMandatory (datas, type);
        datas = isM.datas;

        if ( datas.data[d.url] && isM.mandatory ) {
            
            datas.redcheckData[d.status] = 'unknown document';
            
            const r = await OcrUploadFile(datas.data[d.url],d.name);

            if (r.code === 200) {
                const ocr = r.data.ocr;
                datas.redcheckData[d.ocr] = JSON.stringify(r.data);
                if (d.allowed.includes(ocr.document)) datas.redcheckData[d.status]= getMatch(returnKeyValue(d.ocr_check, ocr), returnKeyValue(d.data_check, datas.data));
                else datas.redcheckData[d.status] = 'found '+ocr.document+' not_match';
				if (DEBUG) console.log(d.status, datas.redcheckData[d.status]);
                // VERSO REQUIRED ?
                if (d.verso_not_required[ocr.document] && (datas.data[d._status] === 'validate' || datas.redcheckData[d.status] === 'validate'))datas.newData[d.verso_not_required[ocr.document]] = 'validate'; 
                if (datas.redcheckData[d.status] === 'validate')datas.newData[d._status] = datas.redcheckData[d.status];
            }
        }
		if (DEBUG) console.log(d.status,':', datas.redcheckData[d.status]);
        return datas;
    }

    const humanValidation = async (status, data) => {
        try{
            setUpdate(false);
            data[status] = 'validate';
            data[status+'OCR']='validate';
            const newData = {};
            const redcheckData = {};
            newData[status] = 'validate';
            redcheckData[status+'OCR'] = 'validate';
            await apiIban.updateStatus(data._id, newData);
            await apiRedcheck.updateRedcheck(data.userId, redcheckData);
            return data;
        }catch (e){
            console.log(e)
        }
    }

    const updateRedcheck = async (data) => {
        try{
			DEBUG = data.DEBUG;
            // Get documents key value
            if (data['userId']){
                let datas = {data, newData: {statusRedcheck: true}, redcheckData:{}};
                await Promise.all(Object.keys(documents).map(async (key) => {
                    if (isCheckDocument(datas, documents, key))datas = await checkDocument(datas, key);
                }));
                data = await updateData(datas);
            } else {
                //alert("Cet utilisateur n\'existe pas dans la table User");
                console.log("user unknown", data);
            }
            return data;
        }catch (e){
            console.log(e)
        }
    }

    const updateData = async (datas) => {
        try{
            for (const key in datas.newData) {
                if (fieldUpdate.includes(key)) {
                    if (datas.newData[key] === datas.data[key]) delete datas.newData[key];
                    else datas.data[key] = datas.newData[key];
                } else delete datas.newData[key];
            }
            for (const key in datas.redcheckData) {
                if (fieldUpdateRedcheck.includes(key)) {
                    if (datas.redcheckData[key] === datas.data[key]) delete datas.redcheckData[key];
                    else datas.data[key] = datas.redcheckData[key];
                } else delete datas.redcheckData[key];
            }
            if (Object.keys(datas.newData).length > 0) await apiIban.updateStatus(datas.data._id, datas.newData);
            if (Object.keys(datas.redcheckData).length > 0) await apiRedcheck.updateRedcheck(datas.data.userId, datas.redcheckData);
            
            return datas.data;
        } catch(e){
            console.log(e);
        }
    }

    const updateState = (state) => {
		setSubFilter(false);
        setFilter(state);
        tableRef.current.onQueryChange();
    }

    const updateAction = (state) => {
        setAction(state);
		tableRef.current.onQueryChange();
    }

	const updateSubFilter = (state) => {
		if (subFilter.includes(state)) {
			subFilter.splice(subFilter.indexOf(state),1);
		} else {
			subFilter.push(state);
		}
		
		state = state === subfilter ? false : state;
		setSubFilter(state);
        tableRef.current.onQueryChange();
    }

	const isURLSYNC = (data) =>  {
        let cp = 0;
        let cp_s = 0;
        let cp_d = 0;

        let searchURL = false;
        Object.keys(data).map((k) => {
        if (!searchURL && k.includes('URL') && data[k] !== ''){
            const docId = data[k].split('/').pop().split('.')[0];
            searchURL = data[k].replace(docId, '#docId#');
            }
        });                     

        if (data['publicIdIdentity'] && (data['cardIdentityURL'] === '' || data['cardIdentityURL'] === null )){
            data['cardIdentityURL_'] = searchURL ? searchURL.replace('#docId#', data['publicIdIdentity']) : data['publicIdIdentity'];
            cp_d++;
        }
        if (data['publicIdIdentityVerso'] && (data['cardIdentityVersoURL'] === '' || data['cardIdentityVersoURL'] === null )){
            data['cardIdentityVersoURL_'] = searchURL ? searchURL.replace('#docId#', data['publicIdIdentityVerso']) : data['publicIdIdentityVerso'];
            cp_d++;
        }
        if (data['publicIdRib'] && (data['ribURL'] === '' || data['ribURL'] === null )){
            data['ribURL_'] = searchURL ? searchURL.replace('#docId#', data['publicIdRib']) : data['publicIdRib'];
            cp_d++;
        }

        if (data['cardIdentityVersoURL'] && data['resultCardIdentityVersoOCR']){
            const ocr = formatObjectJSON(data['resultCardIdentityVersoOCR']);
            const document = ocr.ocr.document;

            if (!documents.ID2.allowed.includes(document)){
                //console.log("document is not ID VERSO");
                cp_s++;
            }
        }
        if (data['cardIdentityURL'] && data['resultCardIdentityOCR']){
            const ocr = formatObjectJSON(data['resultCardIdentityOCR']);
            const document = ocr.ocr.document;

            if (!documents.ID.allowed.includes(document)){
                //console.log("document is not ID RECTO");
                cp_s++;
            }
        }
        if (data['ribURL'] && data['resultIbanOCR']){
            const ocr = formatObjectJSON(data['resultIbanOCR']);
            const document = ocr.ocr.document;

            if (!documents.RIB.allowed.includes(document)){
                //console.log("document is not ID VERSO");
                cp_s++;
            }
        }
    
        if (data['cardIdentityURL'] && data['publicIdIdentity'] && !data['cardIdentityURL'].includes(data['publicIdIdentity'])) {		
            data['cardIdentityURL_'] = data['cardIdentityURL'].replace(data['cardIdentityURL'].split('/').pop().split('.')[0],  data['publicIdIdentity']);
            cp++;
		}
		if (data['cardIdentityVersoURL'] && data['publicIdIdentityVerso'] && !data['cardIdentityVersoURL'].includes(data['publicIdIdentityVerso'])) {
			data['cardIdentityVersoURL_'] = data['cardIdentityVersoURL'].replace(data['cardIdentityVersoURL'].split('/').pop().split('.')[0],  data['publicIdIdentityVerso']);
            cp++;
		}
        if (data['ribURL'] && data['publicIdRib'] && !data['ribURL'].includes(data['publicIdRib'])) {
			data['ribURL_'] = data['ribURL'].replace(data['ribURL'].split('/').pop().split('.')[0],  data['publicIdRib']);
            cp++;
		}
        data['cpt'] = cp;
        data['cpt_'] = cp_s;
        data['cpt__'] = cp_d;
        data['SYNC'] = cp > 0 ? true : false;
        data['SWITCH'] = cp_s > 0 ? true : false;
        data['SEARCH'] = cp_d > 0 ? true : false;
		return data;
	}

    const resyncDocument = async (data) =>  {
       try{
        let newData = {};
        let redcheckData = {};
        let history = '';
            if (data.SYNC){
                Object.keys(data).map((k) => {
                    if (k.includes('URL') && k.includes('_') ){
                        const key = k.slice(0,-1);
                        k = (key === 'cardIdentityURL' ? 'publicIdIdentity' : key === 'cardIdentityVersoURL' ? 'publicIdIdentityVerso': 'ribURL' ? 'publicIdRib' : false);
                        if (k) {
                            const d = new Date();
                            if (data.SWITCH) {
                                newData[key] = data[key+'_'];
                                history = history+d.toDateString() + '-resync '+key+':'+data[key]+'->'+newData[key]+'\n';
                                data[key] = newData[key];
                            } else {
                                newData[k] = data[key].split('/').pop().split('.')[0];
                                history = history+d.toDateString() + '-resync '+k+':'+data[k]+'->'+newData[k]+'\n';
                                data[k] = newData[k];
                            }
                            data[key+'_'] = null;
                        }
                    }
                });
                redcheckData['history'] = redcheckData['history'] ? redcheckData['history'] + history : history;

                await apiIban.updateStatus(data._id, newData);
                await apiRedcheck.updateRedcheck(data.userId, redcheckData);
                data.SYNC = false;
            }  else if (data.SWITCH){
                
                const data_ = await updateRedcheck(data);
                if (data_.SWITCH) {
                    data = await switch_ID(data_);
                    data =  await updateRedcheck(data);
                } else data = data_;
                data.SWITCH = false;
            }
            if (data.SEARCH){
                Object.keys(data).map((k) => {
                    if (k.includes('URL') && k.includes('_') ){
                        alert('replace ', k.replace('_',''), ':', data[k]);
                   }
                });
            }
            return data;
        
        }catch (e){
            console.log(e)
        }
	}

    const getRedcheckDocuments = async (resolve,query)=>{
        try{
            let c = 0;
            let c_ = 0;
            let c__ = 0;
            if (filter) {
                let sort={field:"updatedAt",value:-1};
                if (query.orderBy) {
                    sort.field = query.orderBy.field;
                    sort.value = (query.orderDirection === "asc")?1:-1;
                }
                const data = {nbPage:query.page,pageSize:query.pageSize,fieldSort:sort.field,valueSort:sort.value,search:query.search,filter:filterValidate(filter,subFilter)}
                
                const response = await apiIban.getIBANWithFilter(data);

                await Promise.all(response.ibans.map(async (iban) => {  
                    const r = iban['userId'] ? await apiRedcheck.getRedcheck({ userId: iban.userId }) : null;
                    if (r && r['redcheckDocuments']){
                        if (r.redcheckDocuments['history']) iban['history'] = r.redcheckDocuments['history'];
                        Object.keys(documents).forEach((key) => {
                            const d = documents[key];
                            if (r.redcheckDocuments[d.status]) iban[d.status] = r.redcheckDocuments[d.status];
                            if (r.redcheckDocuments[d.ocr]) iban[d.ocr] = r.redcheckDocuments[d.ocr];
                        });
                    }
                    
                    iban['filter'] = filter;
                    iban['action'] = action;
					iban['subfilter'] = subfilter;
					iban['DEBUG'] = DEBUG_;
                    if (action === 'redcheck')response.ibans[iban] = await updateRedcheck(iban);

					if (DEBUG_) {
                        iban = isURLSYNC(iban);
                        c = c + iban.cpt;
                        c_ = c_ + iban.cpt_;
                        c__ = c__ + iban.cpt__;
                    }
                    
                }));

                if (action === 'redcheck') setAction(false);
                if (DEBUG_) {
                    setCpt(c);
                    setCpt_(c_);
                    setCpt__(c__);
                    console.log("data", response.ibans);
                }
                resolve({
                    data: response.ibans,
                    page:response.page,
                    totalCount: response.total,
                });
            }
        } catch (e){
            console.log(e)
        }
    }

	const debugMODE = (state) => {
		setDebug(state);
		tableRef.current.onQueryChange();
	}

    return(
        <div>
            <div style={{display:'flex', flexDirection: 'row', width: '100%', justifyContent:'space-around', marginBottom: 20}}>
                <button style={{backgroundColor: filter === 'done' ? '#006600' :'#C8C8C8', padding: 20, borderRadius: 20, opacity: filter === 'done' ? 1 : 0.5}} disabled={filter === 'done'} onClick={()=>updateState('done')}>VALIDÉS</button>
                <button style={{backgroundColor: filter === 'pending' ? '#006600' :'#C8C8C8', padding: 20, borderRadius: 20, opacity: filter === 'pending' ? 1 : 0.5}} disabled={filter === 'pending'} onClick={()=>updateState('pending')}>A VÉRIFIER</button>
                <button style={{backgroundColor: filter === 'new'? '#006600' : '#C8C8C8', padding: 20, borderRadius: 20, opacity: filter === 'new' ? 1 : 0.5}} disabled={filter === 'new'} onClick={()=>updateState('new')}>NOUVEAU(X)</button>
               <div style={{display:'flex', flexDirection: 'row', width: '100px', justifyContent:'right',}}><Switch onChange={()=>debugMODE( DEBUG_ ? false : true )} checked={ DEBUG_ ? true : false }/>&nbsp;DebugMode</div>
            </div>
            <div style={{display:'flex', flexDirection: 'row', width: '100%', justifyContent:'space-around', marginBottom: 10}}>
                <div style={{display: 'flex', width: '90%', justifyContent:'center'}}>
                { DEBUG_ ?
					<>
                    <button style={{backgroundColor:'#FF0000', padding: 20, borderRadius: 20, opacity: 1}} disabled={filter === 'done'} onClick={()=>updateAction('redcheck')}>RedCheck Page</button>
                    </>
                    : null
				}
                </div>
            </div> 
            {
                 filter === 'pending' && DEBUG_ ? 
                 <div style={{display:'flex', flexDirection: 'row', width: '100%', justifyContent:'space-around', marginBottom: 20}}>
                     <button style={{backgroundColor: subFilter.includes('iban') ? '#006600' :'#C8C8C8', padding: 20, borderRadius: 20, opacity: 1}} disabled={false} onClick={()=>updateSubFilter('iban')}>iban</button>
                     <button style={{backgroundColor: subFilter.includes('rib') ? '#006600' :'#C8C8C8', padding: 20, borderRadius: 20, opacity: 1}} disabled={false} onClick={()=>updateSubFilter('rib')}>rib</button>
                     <button style={{backgroundColor: subFilter.includes('id') ? '#006600' :'#C8C8C8', padding: 20, borderRadius: 20, opacity: 1}} disabled={false} onClick={()=>updateSubFilter('id')}>id</button>
                     <button style={{backgroundColor: subFilter.includes('id2') ? '#006600' :'#C8C8C8', padding: 20, borderRadius: 20, opacity: 1}} disabled={false} onClick={()=>updateSubFilter('id2')}>id verso</button>
                 </div> : null
            }
            {
                DEBUG_ ? 
                 <div style={{display:'flex', flexDirection: 'row', width: '100%', justifyContent:'left', marginBottom: 10}}>
                    {cpt}&nbsp;document(s) a synchroniser<br/>
                    {cpt_}&nbsp;document(s) a switcher<br/>
                    {cpt__}&nbsp;document(s) a chercher
                </div> : null
            }
            <MaterialTable
                tableRef={tableRef}
                title="Redcheck documents"
                columns={tableData.columns}
                data={query =>
                    new Promise(async (resolve) => {
                        await getRedcheckDocuments(resolve,query);
                    })
                }
                options={{
                    search: true,
					filtering: true,
                    pageSize:10,
                    pageSizeOptions:[5,10,25,50,100,200],
                }}
				parentChildData={(row, rows) => rows.find(a => a.statusRib === row.parentId)}
            />
        </div>
    );
}

export default Redcheck;
