import React, { useState } from 'react';
import {
	ErrorText,
	CustomTextField
} from'../Login/styles';
import {
	useStyles,
	SubmitButton,
	Container,
	ErrorContainer,
} from'./styles';
import { CircularProgress } from "@chakra-ui/core";
import * as apiAdmin from '../../services/admin';
import Papa from 'papaparse';
import moment from "moment";
import 'moment/locale/fr';
function UpdateData(){
	const classes = useStyles();
	const [error, setError] = useState(null);
	const [loading,setLoading] = useState(false);
	const [gain,setGain] = useState(0);
	const [diffDay,setDiffDay] = useState(1);
	const [allEmail,setAllEmail] = useState([]);
	const [statVoucher,setStatVoucher] = useState([]);
	const getStatsQuidol = async () => {
		try {
			if (diffDay < 1) {
				return setError("La valeur doit être supérieur à 0.");
			}
    		let response = await apiAdmin.getStatsVoucher(diffDay);
    		setStatVoucher(response.allDay);
    	} catch(e) {
    		console.log(e)
    	}
	}

	const convertDate = (date, format) => {
        if (date === null) { return; }
        return moment(date).format(format);
    }

    const updateGainUsers = async () => {
    	if (gain.length === 0) {
    		setError("Gain non remplit");
    		return;
    	}
    	if (allEmail.length === 0) {
    		setError("Email non importé");
    		return;
    	}
    	setLoading(true);
    	try {
    		await apiAdmin.updateUsersGain({emails:allEmail,gain:gain});
    		setLoading(false);
    		setError("");
    	} catch(e) {
    		setLoading(false);
    		console.log(e);
    		setError(e.message);
    	}
    }
    
    const readFile = async (e) => {
		e.preventDefault();
		const reader = new FileReader();
		reader.onload = async (e) => {
		  const text = e.target.result;
		  Papa.parse(text, {
		    header: true,
		    complete: (e) => readData(e.data)
		  })
		};
		reader.readAsText(e.target.files[0]);
	}
	const readData = (data) => {
		let email = [];
		for (var i = 0; i < data.length; i++) {
			email.push(data[i].email);
		}
		setAllEmail(email);
	}
	return(
		<div style={{overflow:"none",margin:20}}>
			<ErrorContainer>
			{(error && typeof(error) === "string") && (
				<ErrorText>
					{error}
				</ErrorText>
			)}
			</ErrorContainer>
			<h2 style={{textAlign:'center'}}>Mise à jour joueur quidol</h2>
			<Container>
				<input type="file" onChange={readFile} id="csv-analyze" accept=".csv" style={{marginTop:24}}/>
				<CustomTextField
					InputProps={{ className: classes.textInput }}
					variant="outlined"
					margin="normal"
					value={gain}
					name={"gain"}
					label={"Gain"}
					id={"gain"}
					type="number"
					onChange={(data)=>setGain(data.target.value)}
				/>				
				<SubmitButton onClick={() => loading ? '' : updateGainUsers()}>
					{
						loading ? (
							<CircularProgress isIndeterminate color="blue" size='2rem'/>
						) : (
							'Update'
						)
					}
				</SubmitButton>
			</Container>
			<h2 style={{textAlign:'center'}}>Stats inscrit quidol</h2>
			<Container>
				<CustomTextField
					InputProps={{ className: classes.textInput }}
					variant="outlined"
					margin="normal"
					value={diffDay}
					name={"day"}
					label={"Nombre de jour"}
					id={"day"}
					type="number"
					onChange={(data)=>setDiffDay(data.target.value)}
				/>				
				<SubmitButton onClick={() => loading ? '' : getStatsQuidol()}>
					{
						loading ? (
							<CircularProgress isIndeterminate color="blue" size='2rem'/>
						) : (
							'Afficher'
						)
					}
				</SubmitButton>
			</Container>
			<div>
				{statVoucher.map((stat,key)=> {
					return (
						<div style={{marginTop:10,display:"flex",flexDirection:'column',alignItems:'center'}}>
							<p> Date : {convertDate(stat.end,'DD MMM')}</p>
							<p>Joueur non vérifié : {stat.nbNotVerify}</p>
							<p>Joueur actif : {stat.nbSponso}</p>
							<p>Joueur inscrit : {stat.size}</p>
						</div>
					);
				})}
			</div>
		</div>
	);
}

export default UpdateData;