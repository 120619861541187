import React, { useState } from 'react';
import Alert from '@material-ui/lab/Alert';
import { 
  TextField,
  CircularProgress,
  Button,
  Icon
} from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { 
  MainContainer,
  ContainerBox
} from './styles';
import { triggerReminder } from '../../services/mailing';
import * as apiAuth from '../../services/auth';

function SendButton(props) {
  const { onClick, loading } = props;
  return (
    <Button variant="contained"
      color="primary"
      size="large"
      endIcon={!loading && <Icon>send</Icon>}
      onClick={onClick}
      disabled={loading}>
      {loading && <CircularProgress size={14} />}
      {!loading && 'Relancer les utilisateurs non verifiés'}
    </Button>
  );
}

const MailingScreen = () => {
  const [loading, setloading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [err, setErr] = useState(null);
  const [copySuccess, setCopySuccess] = useState('');
  const [textCopie, setTextCopie] = useState('');
  const [email, setEmail] = useState('');

  const updateEmail = (e) => setEmail(e.target.value);
  
  async function handleClick() {
    setloading(true);
    try {
      await triggerReminder();
      setSuccess(true);
      setErr(null);
    } catch (err) {
      console.error(err)
      setErr("Oups... Les messages n'ont pas pu être envoyés (apparement bryan ne sait pas dev)");
    } finally {
      setloading(false);
    }
  }


  const copyToClipboard = (e) => {
    navigator.clipboard.writeText(textCopie);
    setCopySuccess('Copied!');
  };

  const generateLink = async () => {
    try {
      let res = await apiAuth.getLinkVerified(email);
      setTextCopie(res.link);
      setErr(null);
    } catch(e) {
      setErr(e.message);
    }
  }
  return (
    <MainContainer>
      <SendButton
        variant="contained"
        onClick={handleClick}
        loading={loading}
      />
      {success && <Alert severity="success">Les messages ont été envoyés</Alert>}
      {err && <Alert severity="error">{err}</Alert>}

      <ContainerBox>
        <TextField
          required
          label="email"
          variant="filled"
          value={email}
          onChange={updateEmail}
        />
        <Button variant="contained"
          color="primary"
          size="large"
          endIcon={!loading && <Icon>link</Icon>}
          onClick={generateLink}
          disabled={loading}>
          {loading && <CircularProgress size={14} />}
          {!loading && 'Générer le lien'}
        </Button>
        <TextField
          label="Lien"
          variant="filled"
          value={textCopie}
          disabled
        />
        <Button 
          variant="contained"
          color="primary"
          size="large"
          endIcon={copySuccess.length === 0 && <FileCopyIcon/>}
          onClick={copyToClipboard}
          disabled={textCopie.length === 0 ?true:false}>
          {copySuccess.length === 0 ? 'Copier':copySuccess}
        </Button>
      </ContainerBox>
    </MainContainer>
  );
}

export default MailingScreen;