import styled from "styled-components";
import { Container } from "@material-ui/core"
import {
	Box
} from "@chakra-ui/core";

export const MainContainer = styled(Container)`
  text-align: center;
  height: 100%;
`;

export const ContainerBox = styled(Box)`
	margin-top:20px;
	display:flex;
	justify-content:space-evenly;
`;