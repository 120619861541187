import React, { useState } from 'react';
import { CircularProgress } from "@chakra-ui/core";
import JailedUser from './JailedUser';
import WaitingJailed from './WaitingJailed';
import { jailedUserById, unJailedUserById } from '../../services/admin';
import {
	CustomTextField
} from'../Login/styles';
import {
	useStyles,
	SubmitButton,
	Container,
} from'./style';
const Parrainage = () => {
  const classes = useStyles();
  const [buttonActif, setButtonActif] = useState(0);
  const [loading,setLoading] = useState(false);
	const [userId,setUserId] = useState("");
	const [userIdUnJailed,setUserIdUnJailed] = useState("");
  const clickWaiting = () => {
    setButtonActif(0);
  }
  const clickNotLegit = () => {
    setButtonActif(1);
  }
  const jailedUser = 	async () => {
		setLoading(true);
		try {
			await jailedUserById({userId});
			setLoading(false);
			setUserId('');
		} catch(e) {
			alert('Oups une erreur est survenue');
			setLoading(false);
		}
	}
	const unJailedUser = 	async () => {
		setLoading(true);
		try {
			await unJailedUserById({userId: userIdUnJailed,typeUnJailed: 'parrain'});
			setLoading(false);
			setUserIdUnJailed('');
			alert('Joueur unjailed');
		} catch(e) {
			alert('Oups une erreur est survenue');
			setLoading(false);
		}
	}
  return (
    <div>
      <Container>
				<CustomTextField
					InputProps={{ className: classes.textInput }}
					variant="outlined"
					margin="normal"
					value={userId}
					name={"userid"}
					label={"User Id"}
					id={"userid"}
					onChange={(data)=>setUserId(data.target.value)}
				/>				
				<SubmitButton onClick={() => loading ? '' : jailedUser()}>
					{
						loading ? (
							<CircularProgress isIndeterminate color="blue" size='2rem'/>
						) : (
							'Jailed User'
						)
					}
				</SubmitButton>
			</Container>
			<Container>
				<CustomTextField
					InputProps={{ className: classes.textInput }}
					variant="outlined"
					margin="normal"
					value={userIdUnJailed}
					name={"userid"}
					label={"User Id"}
					id={"userid"}
					onChange={(data)=>setUserIdUnJailed(data.target.value)}
				/>				
				<SubmitButton onClick={() => loading ? '' : unJailedUser()}>
					{
						loading ? (
							<CircularProgress isIndeterminate color="blue" size='2rem'/>
						) : (
							'UnJailed User'
						)
					}
				</SubmitButton>
			</Container>
      <div style={{width: '100%', justifyContent: 'space-evenly', alignItems: 'center', display: 'flex', flexDirection: 'row', marginBottom: 50}}>
        <button style={{backgroundColor: buttonActif === 1 ? '#fff' : '#0D47A1', padding: '10px 20px', borderRadius: 20, color: buttonActif === 0 ? '#fff' : '#000', fontSize: 20}} disabled={buttonActif === 0} onClick={clickWaiting}>En Attente</button>
        <button style={{backgroundColor: buttonActif === 0 ? '#fff' : '#0D47A1', padding: '10px 20px', borderRadius: 20, color: buttonActif === 1 ? '#fff' : '#000',  fontSize: 20}} disabled={buttonActif === 1} onClick={clickNotLegit}>Non Legit</button>
      </div>
      {buttonActif === 0 && <WaitingJailed  />}
      {buttonActif === 1 && <JailedUser  />}
    </div>
  );
}
export default Parrainage;