import axios from 'axios';

import * as c from './constant';
import {  handler } from './handler';

export const getStatsVoucher = async (diffDays) => {
	try {
		const res = await axios.get(`${c.ADMIN}/getStatsVoucher/${diffDays}`);
		return res.data
	} catch (e) {
		throw handler(e);
	}
}

export const updateUsersGain = async (payload) => {
	try {
		const res = await axios.post(`${c.ADMIN}/updateGain`,payload);
		return res.data
	} catch (e) {
		throw handler(e);
	}
}


export const getExtractDataCashout = async (id) => {
	try {
		const res = await axios.get(`${c.ADMIN}/extractDataCashout/${id}`);
		return res.data
	} catch (e) {
		throw handler(e);
	}
}

export const getExtractDataAllCashout = async (id) => {
	try {
		const res = await axios.get(`${c.ADMIN}/extractWaitCashout`);
		return res.data
	} catch (e) {
		throw handler(e);
	}
}

export const getExtractSegmentDay = async (day) => {
	try {
		const res = await axios.get(`${c.ADMIN}/extractSegmentDay/${day}`);
		return res.data
	} catch (e) {
		throw handler(e);
	}
}
export const getAllGrillNotRetrib = async () => {
	try {
		const res = await axios.get(`${c.ADMIN}/getAllGrillNotRetrib`);
		return res.data
	} catch (e) {
		throw handler(e);
	}
}

export const verifyManualRetrib = async (data) => {
	try {
		const res = await axios.post(`${c.ADMIN}/verifyManualRetrib`,data);
		return res.data
	} catch (e) {
		throw handler(e);
	}
}

export const deleteUser = async (data) => {
	return axios.post(`${c.ADMIN}/deleteUserByAdmin`,data);
}

export const getParrainJailed = async (data) => {
	return axios.post(`${c.ADMIN}/getParrainJailed`, data);
}

export const jailedParrain = async (data) => {
	return axios.post(`${c.ADMIN}/jailedParrain`, data);
}

export const removeJailedParrain = async (data) => {
	return axios.post(`${c.ADMIN}/removeJailedParrain`, data);
}

export const getListSponsor = async (data) => {
	return axios.post(`${c.ADMIN}/searchSponsorFilleul`, data);
}

export const jailedUserById = async (data) => {
	return axios.post(`${c.ADMIN}/jailedUserById`, data);
}

export const unJailedUserById = async (data) => {
	return axios.post(`${c.ADMIN}/unjailedUser`, data);
}

export const getJailedUserSignaled = async (data) => {
	return axios.post(`${c.ADMIN}/getJailedUserSignaled`, data);
}

export const removeJailedUserSignaled = async (data) => {
	return axios.post(`${c.ADMIN}/removeJailedUserSignaled`, data);
}

export const jailedSignaledUser = async (data) => {
	return axios.post(`${c.ADMIN}/jailedSignaledUser`, data);
}

export const showMoreInfoUserSignaled = async (data) => {
	return axios.post(`${c.ADMIN}/showMoreInfoUserSignaled`, data);
}

export const getAllJailedUserSignaled = async (data) => {
	return axios.post(`${c.ADMIN}/getAllJailedUserSignaled`, data);
}
