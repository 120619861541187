import React, {useMemo, useReducer, useContext} from 'react';
import axios from "axios";
import reducer, {initialState, LOGGED_IN, LOGGED_OUT} from "./reducer";

import * as apiAuth from '../services/auth';

export const TOKEN_KEY = 'token';
export const USER_KEY = 'user';

export const keys = [TOKEN_KEY, USER_KEY];

export const setAuthorization = (token) => {
	if (!token)
		delete axios.defaults.headers.common["Authorization"];
	else
		axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};


export const getStorageData = async () => {
	try {
		const token = localStorage.getItem(TOKEN_KEY);
		const user = localStorage.getItem(USER_KEY);

		if (token !== null && user !== null)
			return {token, user: JSON.parse(user)};
		else
			return null;
	} catch (e) {
		throw new Error(e);
	}
}

export const setStorageData = async (data) => {
	try {
		if (!data) {
			localStorage.removeItem(TOKEN_KEY);
			localStorage.removeItem(USER_KEY);
		} else {
			const { token, user } = data;
			localStorage.setItem(TOKEN_KEY, token);
			localStorage.setItem(USER_KEY, JSON.stringify({_id: user._id}));
		}
	} catch (e) {
		throw new Error(e);
	}
}

const AuthContext = React.createContext();
const AuthProvider = (props) => {
	const [state, dispatch] = useReducer(reducer, initialState || {});

	const getAuthState = async () => {
		try {
			let data = await getStorageData();
			if (data) {
				data = await handleLogin(data,true);
			} else {
				await handleLogout();
			}
			return (data);
		} catch (e) {
			throw new Error(e);
		}
	}

	const handleLogin = async (data,isJwt) => {
		try {
			let newData;

			let dataStore = await getStorageData();
			data.token = (dataStore !== null) ? dataStore.token : null;
			if (isJwt && dataStore) {
				setAuthorization(dataStore.token);
				newData = await apiAuth.loginjwt(data);
				await setStorageData(dataStore);
			} else {
				newData = await apiAuth.login(data);
				await setStorageData(newData);
				setAuthorization(newData.token);
			}
			dispatch({type: LOGGED_IN, user: newData.user});

			return newData;
		} catch (e) {
			handleLogout();
			throw e;
		}
	}

	const handleLogout = async () => {
		try {
			const data = await getStorageData();
			if (data) {
				const {user} = data;
				try {
					await apiAuth.logout({_id: user._id});
				} catch (e) {}
			}
			await setStorageData();
			setAuthorization(null);
			dispatch({type: LOGGED_OUT});
		} catch (e) {
			throw new Error(e)
		}
	}


	const isLoggedIn = () => {
		return state.isLoggedIn;
	}

	const value = useMemo(() => {
		return { state, getAuthState, handleLogin, handleLogout,isLoggedIn, };
	}, [state]);

	return (
		<AuthContext.Provider value={value}>
			{props.children}
		</AuthContext.Provider>
	);
}

const useAuth = () => useContext(AuthContext);
export { AuthContext, useAuth };
export default AuthProvider;