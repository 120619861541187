import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ThemeProvider, CSSReset, theme } from "@chakra-ui/core";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";

const customTheme = {
	...theme,
	icons: {
		...theme.icons,
	},
	fonts: {
		body: "Arial"
	}
}
	
// Pass the new theme to the ThemeProvider
ReactDOM.render(<ThemeProvider theme={customTheme}><CSSReset /><App /></ThemeProvider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
