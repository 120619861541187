import React, {useState} from 'react';
import * as apiUser from '../../services/user';
import {
	ButtonSearch,
	ButtonText,
	Container,
	TextCGU,
} from './styles';
import ModalInfo from "../../components/ModalInfo";
const CGU = () => {
	const [showModal,setShowModal] = useState(false);
	const updateAllCGU = async () => {
        try{
            await apiUser.updateAllCGU();
            setShowModal(true);
        } catch(e){
            console.log(e)
        }
    }

	return (
		<Container>
			<TextCGU>Voulez-vous mettre à jour l'ensemble des CGU</TextCGU>
			<ButtonSearch onClick={() => updateAllCGU()}>
				<ButtonText>Oui</ButtonText>
			</ButtonSearch>
			{showModal && (
				<ModalInfo 
					close={()=>setShowModal(false)}
					title="Modification des CGU"
					contenu="Les CGU Go Mojo ont été modifiées."
				/>
			)}
		</Container>
	)
};

export default CGU;