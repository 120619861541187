import axios from 'axios';

import * as c from './constant';
import {  handler } from './handler';

export const getEmailAlert = async () => {
	try {
		const res = await axios.get(`${c.ALERT}`);
		return res.data
	} catch (e) {
		throw handler(e);
	}
}

export const addEmailAlert = async (data) => {
	try {
		const res = await axios.put(`${c.ALERT}`,data);
		return res.data
	} catch (e) {
		throw handler(e);
	}
}

export const removeEmailAlert = async (email) => {
	try {
		const res = await axios.delete(`${c.ALERT}/${email}`);
		return res.data
	} catch (e) {
		throw handler(e);
	}
}