export const LOGGED_IN = 'auth/LOGGED_IN';
export const LOGGED_OUT = 'auth/LOGGED_OUT';
export const TEXT_CHILD_CHANGE = 'TEXT_CHILD_CHANGE';
export const TEXT_CHANGE = 'TEXT_CHANGE';
export const ADD_CONTENT = 'ADD_CONTENT';
export const initialState = {
	isLoggedIn: false,
	user: null
};

const authReducer = (state = initialState, action) => {
	switch (action.type) {
		case LOGGED_IN: {
			let { user } = action;
			return {...state, isLoggedIn: true, user};
		}
		case LOGGED_OUT: {
			return {...state, ...initialState};
		}

		case TEXT_CHANGE: {
            for (let i = 0; i < state.length; i++) {
                if(state[i].name === action.name) 
                    state[i].value = action.text;
            }
            return [...state];
        }

        case TEXT_CHILD_CHANGE: {
            for (let i = 0; i < state[action.key].child.length; i++) {
                if(state[action.key].child[i].name === action.name) 
                    state[action.key].child[i].value = action.text;
            }
            return [...state];
        }

        case ADD_CONTENT: {
        	state.push(action.newGame);
            return [...state];
        }

		default: {
			return state;
		}
	}
};

export default authReducer;