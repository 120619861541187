import React, { useState } from 'react';
import * as apiGoKado from '../../services/gokados';

import { Select, Input } from '@chakra-ui/core';
import {
  ButtonSearch,
  SearchContainer,
  ButtonText,
} from './styles';
import SelectDate from '../../components/SelectDate';

export const correspondance = {
  animaux: { label: 'Animaux', category: 'animaux' },
  assurance: { label: 'Assurance', category: 'assurance' },
  automobile: { label: 'Automobile', category: 'automobile' },
  bebesEtToutPetits: { label: 'Bébés et tout-petits', category: 'bebesEtToutPetits' },
  carrieres: { label: 'Carrières', category: 'carrieres' },
  comparaison: { category: 'comparaison', label: 'Comparaison' },
  cultureEtLoisirs: { category: 'cultureEtLoisirs', label: 'Culture et loisirs' },
  electromenager: { category: 'electromenager', label: 'Electroménager' },
  familleEtParents: { category: 'familleEtParents', label: 'Famille et parents' },
  formeEtSante: { category: 'formeEtSante', label: 'Forme et santé' },
  jeuxVideos: { category: 'jeuxVideos', label: 'Jeux videos' },
  loisirs: { category: 'loisirs', label: 'Loisirs' },
  maisonEtDeco: { category: 'maisonEtDeco', label: 'Maison et déco' },
  jardin: { category: 'jardin', label: 'Jardin' },
  nourritureEtBoissons: { category: 'nourritureEtBoissons', label: 'Nourriture et boissons' },
  piecesAutomobile: { category: 'piecesAutomobile', label: 'Pièces automobiles' },
  rencontreSortie: { category: 'rencontreSortie', label: 'Rencontre/Sortie' },
  shopping: { category: 'shopping', label: 'Shopping' },
  sport: { category: 'sport', label: 'Sport' },
  styleEtMode: { category: 'styleEtMode', label: 'Style et mode' },
  technologieEtInformatique: {
    category: 'technologieEtInformatique',
    label: 'Technologie et Informatique',
  },
  telephone: { category: 'telephone', label: 'Téléphone' },
  voyage: { category: 'voyage', label: 'Voyage' },
  offreDuMoment: { category: 'offreDuMoment', label: 'Offre du moment' },
  depenseTotale: { category: 'depenseTotale', label: 'Dépense totale' },
};



function GameDetail() {
  const [category, setCategory] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [totalTickets, setTotalTickets] = useState(null);
  const [totalSpenses, setTotalSpenses] = useState(null);
  const [totalTicketCode, setTotalTicketCode] = useState(null);
  const [totalTicketLink, setTotalTicketLink] = useState(null);
  const [link, setLink] = useState('');
  const [code, setCode] = useState('');
  const [error, setError] = useState('');

  const onSelect = (e) => setCategory(e.target.value);


  const getByCategoryAndDate = async () => {
    try {
      const data = await apiGoKado.getByCategoryAndDate({
        category: correspondance[category].category,
        start: startDate,
        end: endDate,
      });
      setTotalTickets(data.counts);

    } catch (e) {
      console.log(e);
    }
  };

  const getTotalTicketsByLinkOrCode = async () => {
    console.log('link', link);
    console.log('code', code);
    try {
      const data = await apiGoKado.getTotalTicketsByLinkOrCode({
        type: link ? 'link' : 'code',
        filter: link ? link : code,
        start: startDate,
        end: endDate,
      });
      if (code) {
        console.log('code ???', code);
        setTotalTicketCode(data.counts);
      } else {
        setTotalTicketLink(data.counts);
      }

    } catch (e) {
      console.log(e);
    }
  };

  const apiCall = () => {
		// setIsLoading(true);
    if (link && code) {
      return console.log('Vous ne pouvez pas renseigner lien et code en même temps');
    }
    if (category === 'depenseTotale') {
      getTotalExpenseByDate();
    } else if (link || code) {
      getTotalTicketsByLinkOrCode();
    } else {
      getByCategoryAndDate();
    }
    setCode(null);
		setLink(null);
		// setIsLoading(false);
  };

  const getTotalExpenseByDate = async () => {
    try {
      const data = await apiGoKado.getTotalExpenseByDate({
        start: startDate,
        end: endDate,
      });
      setTotalSpenses(data.totalAmount);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      <SearchContainer>
        <div style={{ marginBottom:'30px' }}>
          <Select
            placeholder="Choisir la catégorie"
            onChange={onSelect}
            style={{ marginTop: 20, borderRadius: 15, marginBottom:'20px' }}
          >
            {Object.keys(correspondance).map((category, idx) => (
              <option key={idx} value={correspondance[category].category}>
                {correspondance[category].label}
              </option>
            ))}
          </Select>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom:'20px' }}>
            <Input
              value={link}
              style={{ width: '400px' }}
              onChange={(e) => {
								console.log("did you called me ???");
                setLink(e.target.value);
              }}
              placeholder="lien"
            />
          </div>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Input
              value={code}
              style={{ width: '400px' }}
              onChange={(e) => {
                setCode(e.target.value);
              }}
              placeholder="code"
            />
          </div>
        </div>
        <SelectDate
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />
        {!isLoading && (
          <ButtonSearch onClick={() => apiCall()}>
            <ButtonText>Search</ButtonText>
          </ButtonSearch>
        )}
      </SearchContainer>
     
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          fontFamily: 'WSMedium',
          fontSize: '1.5rem',
        }}
      >
        <div>Nombre de tickets achetés par catégorie:</div>
        <div>{totalTickets}</div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          fontFamily: 'WSMedium',
          fontSize: '1.5rem',
        }}
      >
        <div>Montant total de balles dépensées:</div>
        <div>{totalSpenses} {totalSpenses ? "balles": ""} </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          fontFamily: 'WSMedium',
          fontSize: '1.5rem',
        }}
      >
        <div>Nombre de ticket achetés pour un lien:</div>
        <div>{totalTicketLink}</div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          fontFamily: 'WSMedium',
          fontSize: '1.5rem',
        }}
      >
        <div>Nombre de ticket achetés pour un code:</div>
        <div>{totalTicketCode}</div>
      </div>
    </div>
  );
}

export default GameDetail;
