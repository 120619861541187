import axios from 'axios';

import { VERIFY_REMINDER } from './constant';
import { handler } from './handler';

export const triggerReminder = async () => {
  try {
    await axios.post(`${VERIFY_REMINDER}`);
  } catch (e) {
    throw handler(e);
  }
}