import axios from 'axios';

import * as c from './constant';
import {  handler } from './handler';

export const getNbOfSession = async () => {
	try {
		const res = await axios.get(`${c.SESSION}/getNbOfSession`);
		return res.data
	} catch (e) {
		throw handler(e);
	}
}

export const getSessionUserInfo = async (data) => {
	try {
		const res = await axios.post(`${c.SESSION}/sessionInfoUser`,data);
		return res.data
	} catch (e) {
		throw handler(e);
	}
}

export const getSessionPeriod = async (data) => {
	try {
		const res = await axios.post(`${c.SESSION}/sessionPeriod`,data);
		return res.data
	} catch (e) {
		throw handler(e);
	}
}