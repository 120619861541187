import React from 'react';
import { useHistory } from "react-router-dom";

import { useAuth } from "../../contextes/provider";
import { HeaderContainer, HomeLink, HeaderLink, Link, LinkText } from './styles';
import HomeIcon from '@material-ui/icons/Home';
import PeopleIcon from '@material-ui/icons/People';
import PersonIcon from '@material-ui/icons/Person';
import GamesIcon from '@material-ui/icons/Games';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import FolderIcon from '@material-ui/icons/Folder';
import NotificationsIcon from '@material-ui/icons/Notifications';
import BlockIcon from '@material-ui/icons/Block';
import MailIcon from '@material-ui/icons/Mail';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import GetAppIcon from '@material-ui/icons/GetApp';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';
import ReportIcon from '@material-ui/icons/Report';
function Header() {
    const history = useHistory();
    const { handleLogout, state } = useAuth();
    const user = state.user;
    const logoutRedirect = async () => {
        try {
            await handleLogout();
            history.push('/login');
        } catch (e) {
            console.log(e)
        }
    }
    const goTo = (path) => {
        history.push(`/Home#${path}`);
    }

    return (
        <HeaderContainer>
            <HomeLink onClick={() => goTo("Accueil")}>Mojo Office</HomeLink>
            <HeaderLink>
                <Link onClick={() => goTo("Accueil")}>
                    <HomeIcon />
                    <LinkText>Home</LinkText>
                </Link>
                <Link onClick={() => goTo("Users")}>
                    <PeopleIcon />
                    <LinkText>Users</LinkText>
                </Link>
                <Link onClick={() => goTo("UserDetail")}>
                    <PersonIcon />
                    <LinkText>User Detail</LinkText>
                </Link>
                <Link onClick={() => goTo("InscritDetail")}>
                    <BorderColorIcon />
                    <LinkText>Inscrit détail</LinkText>
                </Link>
                <Link onClick={() => goTo("GameDetail")}>
                    <EqualizerIcon />
                    <LinkText>Game Detail</LinkText>
                </Link>
                <Link onClick={() => goTo("GameHistorique")}>
                    <GamesIcon />
                    <LinkText>Game Historique</LinkText>
                </Link>
                {user.role !== "members" && (
                    <Link onClick={() => goTo("Sponsor")}>
                        <SupervisorAccountIcon />
                        <LinkText>Sponsor</LinkText>
                    </Link>
                )}
                {user.role !== "members" && (
                    <Link onClick={() => goTo("Cashout")}>
                        <EuroSymbolIcon />
                        <LinkText>Cashout</LinkText>
                    </Link>
                )}
                <Link onClick={() => goTo("CGU")}>
                    <LibraryBooksIcon />
                    <LinkText>CGU Update</LinkText>
                </Link>
                {user.role !== "members" && (
                <Link onClick={() => goTo("Signalement")}>
                    <ReportIcon />
                    <LinkText>Signalement</LinkText>
                </Link>
                )}
                {user.role !== "members" && (
                <Link onClick={() => goTo("Parrainage")}>
                    <PersonIcon />
                    <LinkText>Parrainage</LinkText>
                </Link>
                )}
                {user.role !== "members" && (
                <Link onClick={() => goTo("UsersAdmin")}>
                    <SupervisorAccountIcon />
                    <LinkText>Users Admin</LinkText>
                </Link>
                )}
                {user.role !== "members" && (
                <Link onClick={() => goTo("UserDelete")}>
                    <DeleteIcon />
                    <LinkText>Users Delete</LinkText>
                </Link>
                )}
                <Link onClick={() => goTo("Alert")}>
                    <NotificationsIcon />
                    <LinkText>Alerte</LinkText>
                </Link>
                {user.role !== "members" && (
                <Link onClick={() => goTo("UsersBan")}>
                    <BlockIcon />
                    <LinkText>Users Ban</LinkText>
                </Link>
                )}
                {user.role !== "members" && (
                <Link onClick={() => goTo("Mail")}>
                    <MailIcon />
                    <LinkText>Mailing</LinkText>
                </Link>
                )}
                {user.role !== "members" && (
                <Link onClick={() => goTo("Extract")}>
                    <GetAppIcon />
                    <LinkText>Extract</LinkText>
                </Link>
                )}
                {user.role !== "members" && (
                <Link onClick={() => goTo("Retrib")}>
                    <CheckIcon />
                    <LinkText>Retrib</LinkText>
                </Link>
                )}
                {user.role !== "members" && (
                <Link onClick={() => goTo("GoKado")}>
                    <CardGiftcardIcon />
                    <LinkText>Go Kado</LinkText>
                </Link>
                )}
                {user.role !== "members" && (
                <Link onClick={() => goTo("GoKadoDetails")}>
                    <CardGiftcardIcon />
                    <LinkText>Go Kado Details</LinkText>
                </Link>
                )}
                <Link onClick={() => logoutRedirect()}>
                    <ExitToAppIcon />
                    <LinkText>Déconnexion</LinkText>
                </Link>
            </HeaderLink>
        </HeaderContainer>
    );

}
export default Header;