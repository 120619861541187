import axios from 'axios';

import * as c from './constant';
import {  handler } from './handler';

export const getUsersBanByPage = async (page,pageSize,field,sort) => {
	try {
		const res = await axios.get(`${c.BAN}/${page}/${pageSize}/${field}/${sort}`);
		return res.data
	} catch (e) {
		throw handler(e);
	}
}

export const removeBanUser = async (id) => {
	try {
		const res = await axios.delete(`${c.BAN}/${id}`);
		return res.data
	} catch (e) {
		throw handler(e);
	}
}