import axios from 'axios';
import * as c from './constant';
import {  handler } from './handler';

export const processRedcheck = async (data) => {
	try {
		const res = await axios.post(`${c.REDCHECK}/processRedcheck`,data);
		return res.data
	} catch (e) {
		throw handler(e);
	}
}

export const updateRedcheck = async (id, data) => {
	try {
		data['userId'] = id;
		const redcheckDocuments = data;
		const res = await axios.post(`${c.REDCHECK}/updateRedcheck`,redcheckDocuments);
		return res.data
	} catch (e) {
		throw handler(e);
	}
}

export const getRedcheck = async (data) => {
	try {
		const res = await axios.post(`${c.REDCHECK}/getRedcheck`,data);
		return res.data
	} catch (e) {
		throw handler(e);
	}
}
