import React, { useState } from 'react';
import {
  Button,
  Editable,
  Image,
  EditablePreview,
  EditableInput,
  Checkbox,
  Select,
} from '@chakra-ui/core';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

import { correspondance } from '../../index';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const convertDate = (date, format) => {
  if (date === null) {
    return;
  }
  moment.locale('fr');
  return moment(date).format(format);
};

const isExpired = ( expire) => {
  let day = new Date(new Date())
  day.setHours(0, 0, 0, 0);
  return new Date(expire).getTime() > day.getTime();
}

export default function Card({
  logo,
  type,
  code,
  category,
  link,
  expire,
  createdAt,
  UpdateGoKado,
  deleteGoKado,
  id,
  index,
  title,
  text,
  modaleImage,
  isChecked,
  expirationDate,
  codeLink,
}) {
  const [isOnline, setIsOnline] = useState(isChecked);

  const classes = useStyles();

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        border: '2px solid black',
        borderRadius: '10px',
        backgroundColor: '#ffffff',
        width: '31%',
        marginRight: '5px',
        marginBottom: '5px',
        padding: '10px',
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Image objectFit="cover" height="11vmin" pt="10px" src={logo} alt="Logo" />
      </div>
      <div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontFamily: 'WSMedium',
              whiteSpace: 'nowrap',
            }}
          >
            CATEGORIE :
          </div>

          <Select
            onChange={(e) =>
              UpdateGoKado(id, {
                category: correspondance[e.target.value].category,
                label: correspondance[e.target.value].label,
              })
            }
          >
            {Object.keys(correspondance).map((categ, idx) => (
              <option
                selected={category == correspondance[categ].label}
                key={idx}
                value={correspondance[categ].category}
              >
                {correspondance[categ].label}
              </option>
            ))}
          </Select>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontFamily: 'WSMedium',
              whiteSpace: 'nowrap',
            }}
          >
            TYPE :
          </div>

          <Editable
            fontFamily="WorkSans"
            flex="1"
            onSubmit={(value) => UpdateGoKado(id, { type: value })}
            defaultValue={type}
            overflow="auto"
          >
            <EditablePreview />
            <EditableInput />
          </Editable>
        </div>
        {
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontFamily: 'WSMedium',
                whiteSpace: 'nowrap',
              }}
            >
              CODE :
            </div>
            <Editable
              fontFamily="WorkSans"
              flex="1"
              onSubmit={(value) => UpdateGoKado(id, { code: value })}
              defaultValue={code}
              overflow="auto"
            >
              <EditablePreview />
              <EditableInput />
            </Editable>
          </div>
        }
        {link && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '10px',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontFamily: 'WSMedium',
                whiteSpace: 'nowrap',
              }}
            >
              LIEN :
            </div>
            <Editable
              fontFamily="WSMedium"
              whiteSpace="nowrap"
              flex="1"
              onSubmit={(value) => UpdateGoKado(id, { link: value })}
              defaultValue={link}
              overflow="auto"
            >
              <EditablePreview />
              <EditableInput />
            </Editable>
          </div>
        )}
        {codeLink && (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontFamily: 'WSMedium',
                whiteSpace: 'nowrap',
              }}
            >
              LIEN CODE :
            </div>
            <Editable
              fontFamily="WSMedium"
              whiteSpace="nowrap"
              flex="1"
              onSubmit={(value) => UpdateGoKado(id, { codeLink: value })}
              defaultValue={codeLink}
              overflow="auto"
            >
              <EditablePreview />
              <EditableInput />
            </Editable>
          </div>
        )}
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontFamily: 'WSMedium',
              whiteSpace: 'nowrap',
            }}
          >
            expiré :
          </div>
          <Editable
            isDisabled
            fontFamily="WorkSans"
            flex="1"
            defaultValue={isExpired(expirationDate) ? 'offre non expirée' : 'offre expirée'}
            overflow="auto"
            style={{color: isExpired(expirationDate) ? 'green': 'red'}}
          >
            <EditablePreview />
            <EditableInput />
          </Editable>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontFamily: 'WSMedium',
              whiteSpace: 'nowrap',
            }}
          >
            Date de création :
          </div>
          <Editable
            isDisabled
            fontFamily="WorkSans"
            flex="1"
            defaultValue={convertDate(createdAt, 'DD/MM/YYYY')}
            overflow="auto"
          >
            <EditablePreview />
            <EditableInput />
          </Editable>
        </div>
        <form className={classes.container} noValidate>
        <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontFamily: 'WSMedium',
              whiteSpace: 'nowrap',
            }}
          >
            Date d'expiration :
          </div>
          <TextField
            id={createdAt}
            type="date"
            onChange={e => UpdateGoKado(id, {
              expirationDate: new Date(
                e.target.value
              ),
            })}
            defaultValue={convertDate(expirationDate, 'YYYY-MM-DD')}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </form>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontFamily: 'WSMedium',
              whiteSpace: 'nowrap',
              whiteSpace: 'nowrap',
            }}
          >
            Logo :
          </div>
          <Editable
            fontFamily="WorkSans"
            whiteSpace="nowrap"
            flex="1"
            onSubmit={(value) => UpdateGoKado(id, { logo: value })}
            defaultValue={logo}
            overflow="auto"
          >
            <EditablePreview />
            <EditableInput />
          </Editable>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontFamily: 'WSMedium',
              whiteSpace: 'nowrap',
              whiteSpace: 'nowrap',
            }}
          >
            Image Modale :
          </div>
          <Editable
            fontFamily="WorkSans"
            whiteSpace="nowrap"
            flex="1"
            onSubmit={(value) => UpdateGoKado(id, { modaleImage: value })}
            defaultValue={modaleImage}
            overflow="auto"
          >
            <EditablePreview />
            <EditableInput />
          </Editable>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontFamily: 'WSMedium',
              whiteSpace: 'nowrap',
              whiteSpace: 'nowrap',
            }}
          >
            Titre :
          </div>
          <Editable
            fontFamily="WorkSans"
            whiteSpace="nowrap"
            flex="1"
            onSubmit={(value) => UpdateGoKado(id, { title: value })}
            defaultValue={title}
            overflow="auto"
          >
            <EditablePreview />
            <EditableInput />
          </Editable>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontFamily: 'WSMedium',
              whiteSpace: 'nowrap',
              whiteSpace: 'nowrap',
            }}
          >
            Texte :
          </div>
          <Editable
            fontFamily="WorkSans"
            whiteSpace="nowrap"
            flex="1"
            onSubmit={(value) => UpdateGoKado(id, { text: value })}
            defaultValue={text}
            overflow="auto"
          >
            <EditablePreview />
            <EditableInput />
          </Editable>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontFamily: 'WSMedium',
              whiteSpace: 'nowrap',
              flex: '1',
            }}
          >
            <Checkbox
              onChange={() => {
                UpdateGoKado(id, { online: !isChecked });
                setIsOnline((prev) => !prev);
              }}
              isChecked={isOnline}
            >
              Mise en ligne
            </Checkbox>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
          }}
        >
          <Button
            onClick={() => deleteGoKado(id, index)}
            width="100%"
            variantColor="red"
            variant="outline"
            mt="10px"
          >
            SUPPRIMER LA CARTE
          </Button>
        </div>
      </div>
    </div>
  );
}
