import axios from 'axios';

import * as c from './constant';
import {  handler } from './handler';

export const createGoKado = async (data) => {
	try {
		const res = await axios.post(`${c.GO_KADO}/create`, data);
		return res.data
	} catch (e) {
		throw handler(e);
	}
}

export const getByCategoryAndDate = async (data) => {
	try {
		const res = await axios.post(`${c.GO_KADO}/admin/populate`, data);
		return res.data
	} catch (e) {
		throw handler(e);
	}
}
export const getTotalExpenseByDate = async (data) => {
	try {
		const res = await axios.post(`${c.GO_KADO}/admin/expenses`, data);
		return res.data
	} catch (e) {
		throw handler(e);
	}
}
export const getTotalTicketsByLinkOrCode = async (data) => {
	try {
		const res = await axios.post(`${c.GO_KADO}/admin/ticketsByLinkOrCode`, data);
		return res.data
	} catch (e) {
		throw handler(e);
	}
}


export const getAllGoKado = async (skip, online) => {
	try {
		const res = await axios.get(`${c.GO_KADO}/admin/${skip}/${online}`);
		return res.data
	} catch (e) {
		throw handler(e);
	}
}


export const updateGoKado = async (id, data) => {
	try {
		const res = await axios.put(`${c.GO_KADO}/update/${id}`,data);
		return res.data
	} catch (e) {
		throw handler(e);
	}
}
export const deleteGoKado = async (id) => {
	try {
		const res = await axios.delete(`${c.GO_KADO}/delete/${id}`);
		return res.data
	} catch (e) {
		throw handler(e);
	}
}
