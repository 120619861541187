import styled, {  } from 'styled-components';
import {
	Box,
} from "@chakra-ui/core";


/* STYLED COMPONENTS */
export const HeaderContainer = styled(Box)`
	margin: 0;
  	padding: 0;
	background-color: #22577A;
	color: #fff;
	display:flex;
	flex-direction:column;
	overflow:auto;
	position:fixed;
	height:100%;
	width:150px;
	z-index:15;
`;

export const HomeLink = styled(Box)`
	cursor:pointer;
	padding: 20px 10px;
`;

export const HeaderLink = styled(Box)`
	display:flex;
	flex-direction:column;
`;
export const Link = styled(Box)`
	padding: 10px 10px;
	display:flex;
	cursor:pointer;
`;

export const LinkText = styled(Box)`
	font-size:13px;
	margin:auto 10px;
`;