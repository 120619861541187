import React, {useState } from 'react';
import * as apiStat from '../../services/globalgamestat';

import {
	Select,
} from "@chakra-ui/core";
// import { MDBDataTable } from 'mdbreact';
import {
	ButtonSearch,
	SearchContainer,
	ButtonText,
	Loader,
	ContainerDonut,
	ContainerChild,
} from'./styles';
import {
    Grid
} from '@material-ui/core';
import LineChart from '../../components/LineChart';
import BarChart from '../../components/BarChart';
import {optionsLine} from './options';
import {optionsDonut,optionsArea,optionsProgress} from "../Dashboard/options";
import moment from "moment";
import SelectDate from '../../components/SelectDate';

const gameNames=["keep","scope","battle","power","goloto","gocash","golucky","pop","loco","land","mojowin","go50","go1000","viking","chess","newyear","jungle","fairground","space",'slot','seventies'];

function GameDetail(){
	const [gameName,setGameName] = useState("");
	const [isLoading,setIsLoading] = useState(false);  	
  	const [lineChart,setLineChart] = useState(null);
  	const [donutLooseWin,setDonutLooseWin] = useState(null);
  	const [startDate, setStartDate] = useState(null);
  	const [endDate, setEndDate] = useState(null);
  	const [globalStatsGraph,setGlobalStatsGraph] = useState(null);
  	const [tdlGraph,setTdlGraph] = useState(null);
	const onSelect = (e) => setGameName(e.target.value);

	const getTaux = (mise,credit,rationSolde) => {
    	return ((credit+rationSolde)/mise)*100;
	}
    const getUserGameDetail = async ()=>{
    	if (gameName.length === 0 || !startDate || !endDate) {return;}
    	if (startDate.getTime() >= endDate.getTime()) {return;}
        try{
        	setIsLoading(true);
        	let data = {start:startDate,end:endDate,gameName:gameName};
            const response = await apiStat.getStatsGame(data);

            let historiqueGame = response.globalGameStat;
            let historiqueGamePeriod = response.globalGameStatPeriod;
            // let historiqueGameGeneral = response.globalGameStatGeneral
            let tauxCredit = [];
            let labels = [];

            for (var i = 0; i < historiqueGame.length; i++) {
            	if (historiqueGame[i].mise === 0) {continue;}
            	const taux = getTaux(historiqueGame[i].mise,historiqueGame[i].credit,historiqueGame[i].soldeConvertWithCPM)
            	tauxCredit.push(taux.toFixed(0));
            	labels.push(convertDate(historiqueGame[i].day,"DD MMM"));
            }
          //   if (response.globalGameDayStat && response.globalGameDayStat.mise > 0) {
	         //    let taux = getTaux(response.globalGameDayStat.mise,response.globalGameDayStat.credit,response.globalGameDayStat.solde)
	        	// tauxCredit.push(taux.toFixed(0));
	        	// labels.push(convertDate(new Date(),"DD MMM"));
          //   }

            let optionsDetail = JSON.parse(optionsLine);
            optionsDetail.labels = labels;
            setLineChart({options:optionsDetail,series:getSeries(tauxCredit)})
            const ratioSolde = historiqueGamePeriod.soldeConvertWithCPM;
            const miseGen = historiqueGamePeriod.mise ;
            const creditGen = historiqueGamePeriod.credit ;
            const TRJGen = (miseGen === 0)?0:Number((((creditGen+ratioSolde)/miseGen)*100).toFixed(2));

            createProgessData(TRJGen);
            
            setDonutLooseWin(setDonutGraph(historiqueGamePeriod.nbWin,historiqueGamePeriod.nbLoose));
            createStatsActif(historiqueGame,gameName);
            setIsLoading(false);
        } catch (e){
            console.log(e)
            setIsLoading(false);
            // setError(e.message);
        }
    }

    const createProgessData = (credit,solde) => {
        let statPercent = [];
        statPercent.push({colorStart:"#0ECD80",colorEnd:"#0ECD80",name:"TRJ",value:credit});
        
        let progressData = [];
        for (var i = 0; i < statPercent.length; i++) {
            let options = JSON.parse(optionsProgress);
            options.title.text = statPercent[i].name;
            options.subtitle.text = `${statPercent[i].value}%`;
            options.colors = [statPercent[i].colorStart];
            options.fill.gradient.gradientToColors = [statPercent[i].colorEnd];
            progressData.push({options,series:{name:statPercent[i].name,data:[statPercent[i].value]}});
        }
        setTdlGraph(progressData);
    }

    const convertDate = (date, format) => {
		if (date === null) {return;}
		moment.locale("fr");
		return moment(date).format(format);
	}
    const capitalize = (s) => {
	  	if (typeof s !== 'string') return ''
	  	return s.charAt(0).toUpperCase() + s.slice(1)
	}

	const getSeries = (trj)=> {
		let series= [{
			name: 'TRJ',
			type: 'line',
			data: trj
        }];
        return series;
	}

	const setDonutGraph = (nbWin,nbLoose) => {
		let series = [nbWin,nbLoose];
        let options = JSON.parse(optionsDonut);
        options.chart.height = 200;
        options.title.text = "Parties jouées";
        options.labels = ['Victoire', 'Défaite'];
        options.colors = ["#4299E1", "#E53E3E"];
        return {options:options,series:series};
	}

	const createStatsActif = (data,name) => {
        const statsType = {
            total:{text:"Partie jouées",total:0,series:[]},
            nbWin:{text:"Victoires",total:0,series:[]},
            nbLoose:{text:"Défaites",total:0,series:[]},
            mise:{text:"Balles misées",total:0,series:[]},
            credit:{text:"Balles gagnées",total:0,series:[]},
        };
        if (["goloto","golucky","gocash","go50","go1000","mojowin","viking", "chess","newyear","jungle","fairground",'slot','seventies','space'].includes(name)) {
        	statsType.solde = {text:"Euros gagnées",total:0,series:[]}
        }
        let statsGraph = [];
        let labels = [];
        for (var i = 0; i < data.length; i++) {
            for (const property in data[i]) {
                if (statsType[property]) {
                    statsType[property].total += data[i][property];
                    statsType[property].series.push(data[i][property]);
                }
            }
            labels.push(convertDate(data[i].day,"DD MMM"));
        }
        labels.push(convertDate(new Date(),"DD MMM"));
        if (labels.length > 31) {
            labels = labels.slice(labels.length-31);
        }
        for (const property in statsType){
            let options = JSON.parse(optionsArea);
            // statsType[property].series.push(day[property])
            // statsType[property].total +=day[property];
            options.title.text = (property === "solde")? Number(statsType[property].total.toFixed(2)):statsType[property].total;
            options.subtitle.text = statsType[property].text;
            options.labels = labels;
            statsGraph.push({options:options,series:[{name:"value",data:(statsType[property].series.length < 31)?statsType[property].series:statsType[property].series.slice(statsType[property].series.length-31)}]});
        }
        setGlobalStatsGraph(statsGraph);
    }

	return(
		<div>
			
			<SearchContainer>
				<div style={{}}>
				<Select placeholder="Select game" onChange={onSelect} style={{marginTop:20,borderRadius:15}}>
					{gameNames.map((gameName,i)=>{
						return(
				  			<option value={gameName} key={i}>{capitalize(gameName)}</option>
						);
					})}
				</Select>
				</div>
				<SelectDate startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate}/>
				{!isLoading&&(
					<ButtonSearch onClick={() => getUserGameDetail()}>
						<ButtonText>Search</ButtonText>
					</ButtonSearch>
				)}
			</SearchContainer>
			
			<Grid container>
				{donutLooseWin && (
	                <ContainerDonut>
	                    <LineChart dataChart={donutLooseWin} />
	                </ContainerDonut>
	            )}
	            <React.Fragment>
			        {tdlGraph && (
			           	<ContainerDonut>
			                <BarChart dataChart={tdlGraph} height={70}/>
			            </ContainerDonut>
			        )}
		        </React.Fragment>
	        </Grid>
	        <Grid container>
	        	{globalStatsGraph && (
	                <React.Fragment>
	                {globalStatsGraph.map((statsGraph,key)=> {
	                    return (
	                        <ContainerChild key={key}>
	                            <LineChart dataChart={statsGraph}/>
	                        </ContainerChild>
	                    );
	                })}
	                </React.Fragment>
	            )}
	        </Grid>
			{isLoading &&(<Loader /> )}
			{lineChart&&(
				<LineChart dataChart={lineChart}/>
			)}
		</div>
	);
}

export default GameDetail;