import React, { useState, useEffect } from 'react';
import { Button, Checkbox } from '@chakra-ui/core';
import AddCart from './components/AddCart';
import DisplayCart from './components/DisplayCart';

import * as apiGoKado from '../../services/gokados';

export const correspondance = {
  animaux: { label: 'Animaux', category: 'animaux' },
  assurance: { label: 'Assurance', category: 'assurance' },
  automobile: { label: 'Automobile', category: 'automobile' },
  bebesEtToutPetits: { label: 'Bébés et tout-petits', category: 'bebesEtToutPetits' },
  carrieres: { label: 'Carrières', category: 'carrieres' },
  comparaison: { category: 'comparaison', label: 'Comparaison' },
  cultureEtLoisirs: { category: 'cultureEtLoisirs', label: 'Culture et loisirs' },
  electromenager: { category: 'electromenager', label: 'Electroménager' },
  familleEtParents: { category: 'familleEtParents', label: 'Famille et parents' },
  formeEtSante: { category: 'formeEtSante', label: 'Forme et santé' },
  jeuxVideos: { category: 'jeuxVideos', label: 'Jeux videos' },
  loisirs: { category: 'loisirs', label: 'Loisirs' },
  maisonEtDeco: { category: 'maisonEtDeco', label: 'Maison et déco' },
  jardin: { category: 'jardin', label: 'Jardin' },
  nourritureEtBoissons: { category: 'nourritureEtBoissons', label: 'Nourriture et boissons' },
  piecesAutomobile: { category: 'piecesAutomobile', label: 'Pièces automobiles' },
  rencontreSortie: { category: 'rencontreSortie', label: 'Rencontre/Sortie' },
  shopping: { category: 'shopping', label: 'Shopping' },
  sport: { category: 'sport', label: 'Sport' },
  styleEtMode: { category: 'styleEtMode', label: 'Style et mode' },
  technologieEtInformatique: {
    category: 'technologieEtInformatique',
    label: 'Technologie et Informatique',
  },
  telephone: { category: 'telephone', label: 'Téléphone' },
  voyage: { category: 'voyage', label: 'Voyage' },
  offreDuMoment: { category: 'offreDuMoment', label: 'Offre du moment' },
};

export default function GoKado() {
  const [categoryCode, setCategoryCode] = useState('');
  const [categoryLink, setCategoryLink] = useState('');
  const [typeCode, setTypeCode] = useState('code');
  const [typeLink, setTypeLink] = useState('link');
  const [code, setCode] = useState('');
  const [link, setLink] = useState('');
  const [logoCode, setLogoCode] = useState('');
  const [logoLink, setLogoLink] = useState('');
  const [modaleImageCode, setModalImageCode] = useState('');
  const [modaleImageLink, setModalImageLink] = useState('');
  const [titleCode, setTitleCode] = useState('');
  const [titleLink, setTitleLink] = useState('');
  const [textCode, setTextCode] = useState('');
  const [textLink, setTextLink] = useState('');
  const [goKadoList, setGoKadiList] = useState([]);
  const [skip, setSkip] = useState(0);
  const [showButton, setShowButton] = useState(false);
  const [count, setCount] = useState(0);
  const [expirationDateCode, setExpirationDateCode] = useState(null);
  const [expirationDateLink, setExpirationDateLink] = useState(null);
  const [codeLink, setCodeLink] = useState('');
  const [isOnline, setIsOnline] = useState(false);

  const addCode = () => {
    createGoKadoCode();
    setCode('');
    setLogoCode('');
    setTextCode('');
    setTitleCode('');
    setModalImageCode('');
    setCodeLink('');
    setExpirationDateCode(null);
  };
  const addLink = () => {
    createGoKadoLink();
    setLink('');
    setLogoLink('');
    setTitleLink('');
    setTextLink('');
    setModalImageLink('');
    setExpirationDateLink(null);
  };

  useEffect(() => {
    if(isOnline) {
      GetAllGoKado(0,true)

    } else {
      GetAllGoKado(0);
    }
  }, [isOnline]);

  const createGoKadoCode = async () => {
    try {
      const data = await apiGoKado.createGoKado({
        label: correspondance[categoryCode].label,
        text: textCode,
        type: typeCode,
        logo: logoCode,
        title: titleCode,
        modaleImage: modaleImageCode,
        code,
        expirationDate: expirationDateCode,
        codeLink,
        category: correspondance[categoryCode].category,
      });

      setGoKadiList([data.result, ...goKadoList]);
    } catch (e) {
      console.log(e);
    }
  };
  const createGoKadoLink = async () => {
    try {
      const data = await apiGoKado.createGoKado({
        label: correspondance[categoryLink].label,
        text: textLink,
        type: typeLink,
        logo: logoLink,
        title: titleLink,
        modaleImage: modaleImageLink,
        link,
        expirationDate: expirationDateLink,
        category: correspondance[categoryLink].category,
      });
      setGoKadiList([data.result, ...goKadoList]);
    } catch (e) {
      console.log(e);
    }
  };
  const GetAllGoKado = async (num, online) => {
    try {
      setShowButton(false);
      const data = await apiGoKado.getAllGoKado(num, online);
      if(num ==0) {
        setGoKadiList([ ...data.result]);

      } else {
        setGoKadiList([...goKadoList, ...data.result]);

      }
      setSkip((prev) => prev + 9);
      setCount(data.count);
    } catch (e) {
      console.log(e);
    }
  };

  const UpdateGoKado = async (id, data) => {
    try {
      const res = await apiGoKado.updateGoKado(id, data);
    } catch (e) {
      console.log(e);
    }
  };
  const deleteGoKado = async (id, index) => {
    try {
      const newData = goKadoList.filter((elt, idx) => idx !== index);
      setGoKadiList(newData);
      const res = await apiGoKado.deleteGoKado(id);
    } catch (e) {
      console.log(e);
    }
  };

  const handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      setShowButton(true);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
          width: '100%',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div style={{ fontFamily: 'WSMedium' }}>AJOUTER UN PARTENAIRE : CODE</div>
          <AddCart
            setType={setTypeCode}
            setCode={setCode}
            setLink={setLink}
            setLogo={setLogoCode}
            setText={setTextCode}
            setTitle={setTitleCode}
            setModalImage={setModalImageCode}
            setCategory={setCategoryCode}
            add={addCode}
            codeLink={codeLink}
            setCodeLink={setCodeLink}
            type={typeCode}
            code={code}
            link={link}
            logo={logoCode}
            title={titleCode}
            text={textCode}
            modaleImage={modaleImageCode}
            setExpirationDate={setExpirationDateCode}
            expirationDate={expirationDateCode}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div style={{ fontFamily: 'WSMedium' }}>AJOUTER UN PARTENAIRE : LIEN</div>
          <AddCart
            setType={setTypeLink}
            setCode={setCode}
            setLink={setLink}
            setLogo={setLogoLink}
            setText={setTextLink}
            setTitle={setTitleLink}
            setModalImage={setModalImageLink}
            setCategory={setCategoryLink}
            add={addLink}
            type={typeLink}
            code={code}
            link={link}
            logo={logoLink}
            title={titleLink}
            text={textLink}
            modaleImage={modaleImageLink}
            setExpirationDate={setExpirationDateLink}
            expirationDate={expirationDateLink}
          />
        </div>
      </div>
      <div><Checkbox onChange={() => {setIsOnline(prev => !prev)}}  defaultIsChecked={isOnline}  >Afficher les offres actuellement en ligne</Checkbox></div>
      <div
        onScroll={handleScroll}
        style={{
          width: '100%',
          display: 'flex',
          flexWrap: 'wrap',
          overflowY: 'auto',
          maxHeight: '75vh',
          justifyContent: 'center',
        }}
      >
       
        {goKadoList.map((goKado, id) => (
          <DisplayCart
            key={`${id}${goKado.createdAt}`}
            category={goKado.label}
            expirationDate={goKado.expirationDate}
            text={goKado.text}
            type={goKado.type}
            code={goKado.code}
            link={goKado.link}
            codeLink={goKado.codeLink}
            logo={goKado.logo}
            title={goKado.title}
            modaleImage={goKado.modaleImage}
            isChecked={goKado.online}
            expire={goKado.expire}
            createdAt={goKado.createdAt}
            id={goKado._id}
            UpdateGoKado={UpdateGoKado}
            deleteGoKado={deleteGoKado}
            index={id}
          />
        ))}
      </div>

      {showButton && count !== goKadoList.length && (
        <div
          style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '20px' }}
        >
          <Button
            onClick={() => isOnline ? GetAllGoKado(skip,true):GetAllGoKado(skip) }
            width="20%"
            variantColor="green"
            variant="outline"
          >
            CHARGER PLUS
          </Button>
        </div>
      )}
    </div>
  );
}
