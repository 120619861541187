import styled, {  } from 'styled-components';
import {
	Box,
	Text
} from "@chakra-ui/core";


/* STYLED COMPONENTS */
export const Container = styled(Box)`
	display:flex;
	justify-content:space-around;
	margin-top:20px;
`;

export const ContainerChild = styled(Box)`
	width:200px;
	margin-right:20px;
	margin-top:10px;
`;

export const ContainerDonut = styled(Box)`
	width:350px;
	margin-right:20px;
	margin-top:0px;
`;

export const ButtonSearch = styled(Box)`
	padding: 10px 10px;
	margin-top:20px;
	margin-left:10px;
	border-radius:10px;
	cursor:pointer;
	background-image: linear-gradient(135deg, #0093FF 0%, #00DEFF 100%);
`;
export const ButtonText = styled(Text)`
	font-family: WorkSans;
	text-transform: uppercase;
	color:#fff;
	align-items:center;
`;

export const ContainerSearch = styled(Box)`
	display:flex;
	min-width:350px;
	max-width:600px;
	margin-top:0px;
	justify-content:space-between;
`;