import styled, {  } from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import {
	Box,
} from "@chakra-ui/core";


/* STYLED COMPONENTS */
export const Container = styled(Box)`
	margin-top:20px;
	display:flex;
	justify-content: center;
  margin-bottom:20px;
`;

export const useStyles = makeStyles(theme => ({
	textInput: {
		height: "5vh",
		borderRadius: 15,
		maxWidth:200,
	},
}));

export const SubmitButton = styled(Box)`
	height: 5vh;
	padding:0px 2rem;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 20px;
	margin:15px;
	background: linear-gradient(135deg, #0093FF 0%, #00DEFF 100%);
	font-family: 'HVD';
	font-size: 0.9rem;
	color: #fff;
	cursor: pointer;
	text-transform:uppercase;
`;

export const ErrorContainer = styled(Box)`
	margin:auto;
	max-width:200px;
	display:flex;
`;

export const ContainerEmail = styled(Box)`
	display:flex;
`;