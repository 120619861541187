import React, { useState } from 'react';
import { CircularProgress,Checkbox } from "@chakra-ui/core";

import { useHistory } from "react-router-dom";

import { useAuth } from "../../contextes/provider";


import {
	useStyles,
	ErrorText,
	LoginTitle,
	LoginButton,
	MainContainer,
	FormContainer,
	LoginContainer,
	CustomTextField,
	TextFormCheckbox,
	EyePasswordImage,
	EyePasswordButton,
	RememberMeContainer,
	PasswordInputContainer,
	RememberMeTextContainer,
} from'./styles';
const eyeIcon = require('../../assets/oeil-password.png');

function LoginScreen() {
	const classes = useStyles();
	const history = useHistory();
	const { handleLogin } = useAuth();
	const [isRemembered, setRemembered] = useState(false);
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [secure, setSecure] = useState(true);
	const handleCheckBox = () => setRemembered(!isRemembered);
	const handlePassword = (e) => setPassword(e.target.value);
	const handleEmail = (e) => setEmail(e.target.value);

	const connection = async () => {
		if (!password.length)
			return setError({'password': 'Mot de passe requis'});
		if (!email.length)
			return setError({'email': 'Email requis'});
		try {
			setLoading(true);
			
			const response = await handleLogin({email: email, password: password},isRemembered,false);
			const username = (response.user.username !== null && response.user.username !== undefined);

			setLoading(false);
			if (username) {
				history.replace('/Home');
			}
		} catch (e) {
			setLoading(false);
			let mess = "";
			if (e !== undefined) {
				mess = e.message;
				if (e.name !== "Error") {
					mess = "Erreur de connexion, veuillez réessayer plus tard";
				} else {
					try {
						mess = JSON.parse(e.message)
					} catch {}
				}
			}
			setError(mess);
			
		}
	}

	return (
		<MainContainer>
			<FormContainer bg="#fff" p="2rem" m="auto">
				<LoginContainer width={{base: "auto", md: "350px"}}>
					<LoginTitle fontFamily="WSBold">
						Connectez-vous&nbsp;!
					</LoginTitle>
					{(error && typeof(error) === "string") && (
						<ErrorText>
							{error}
						</ErrorText>
					)}
					<CustomTextField
						InputProps={{ className: classes.textInput }}
						variant="outlined"
						margin="normal"
						required
						fullWidth
						name="email"
						label="Email"
						type="email"
						id="email"
						autoComplete="email"
						onChange={handleEmail}
						error={(error && !!error.email)}
						helperText={(error && !!error.email) ? error.email : ''}
					/>

					<PasswordInputContainer>
						<CustomTextField
							InputProps={{ className: classes.textInput }}
							variant="outlined"
							margin="normal"
							required
							fullWidth
							name="password"
							label="Mot de passe"
							type={secure ? "password" : "text"}
							id="password"
							autoComplete="current-password"
							onChange={handlePassword}
							error={(error && !!error.password)}
							helperText={(error && !!error.password) ? error.password : ''}
						/>

						<EyePasswordButton onClick={() => { setSecure(!secure); }}>
							<EyePasswordImage src={eyeIcon} />
						</EyePasswordButton>
					</PasswordInputContainer>

					<RememberMeContainer>
						<Checkbox
							className={classes.root}
							disableRipple
							color="default"
							checkedIcon={<span className={(classes.icon, classes.checkedIcon)} />}
							icon={<span className={classes.icon} />}
							inputProps={{ 'aria-label': 'decorative checkbox' }}
							onChange={handleCheckBox}
							checked={isRemembered}
							id="remember"
						/>

						<RememberMeTextContainer>
							<TextFormCheckbox htmlFor="remember" onClick={handleCheckBox}>
								Se souvenir de moi
							</TextFormCheckbox>
						</RememberMeTextContainer>
					</RememberMeContainer>

					<LoginButton onClick={() => loading ? '' : connection()}>
						{
							loading ? (
								<CircularProgress isIndeterminate color="blue" size='2rem'/>
							) : (
								'CONNEXION'
							)
						}
					</LoginButton>
				</LoginContainer>
			</FormContainer>
		</MainContainer>
	);
}

export default LoginScreen;