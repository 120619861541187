import axios from 'axios';

import * as c from './constant';
import {  handler } from './handler';

export const getAllWaitCashout = async (page,pageSize,field,sort) => {
	try {
		const res = await axios.get(`${c.WAITCASHOUT}/getAll/${page}/${pageSize}/${field}/${sort}`);
		return res.data
	} catch (e) {
		throw handler(e);
	}
}

export const getAverage = async () => {
	try {
		const res = await axios.get(`${c.WAITCASHOUT}/average`);
		return res.data
	} catch (e) {
		throw handler(e);
	}
}

export const sendWaitCashout = async (body) => {
	try {
		return await axios.post(`${c.WAITCASHOUT}/updateSendCashout`,body);
	} catch (e) {
		throw handler(e);
	}
}