import React, {useState } from 'react';
import * as apiHistorique from '../../services/historique';
import moment from "moment";
import MaterialTable from 'material-table';
import {
	SearchContainer,
	ErrorContainer,
	ButtonSearch,
	ButtonText
} from'./styles';
import {
	Select,
} from "@chakra-ui/core";
import {
	ErrorText,
} from'../Login/styles';
import SelectDate from '../../components/SelectDate';
const gameNames=["keep","scope","battle","power","goloto","gocash","golucky","pop","loco","land","mojowin","go50","go1000","viking","chess","newyear","jungle","fairground","space",'slot','seventies'];
function HistoriqueGame(){
	let tableRef = React.createRef();
	const [showData, setShowData] = useState(false);
	const [gameName,setGameName] = useState("");
	// const [period,setPeriod] = useState("");
	const [startDate, setStartDate] = useState(null);
  	const [endDate, setEndDate] = useState(null);
	const onSelect = (e) => {
		setGameName(e.target.value);
	}
	const [error, setError] = useState(null);
	const [tableData] = useState({
		columns: [
			{
				title: 'Joueur',
				field: 'username',
			},
			{
				title: 'Balles',
				field: 'credit',
			},
			{
				title: 'Euros',
				field: 'solde',
			},
			{
				title: 'Mise',
				field: 'mise',
			},
			{
				title: 'Joué',
				field: 'status',
				type: 'boolean'
			},
			{
				title: "Date joué",
				field: 'createdAt',
				render: rowData=><p>{convertDate(rowData.createdAt,"DD/MM/YYYY")}</p>
			},
		]
	});
	
    const getGameHistorique = async (resolve,query)=>{
        try{
        	let sort={field:"createdAt",value:1};
        	if (query.orderBy) {
        		sort.field = query.orderBy.field;
        		sort.value = (query.orderDirection === "asc")?1:-1;
        	}
        	let data = {start:startDate,end:endDate,gameNames:(gameName.length === 0)?gameNames:[gameName],nbPage:query.page,pageSize:query.pageSize,fieldSort:sort.field,valueSort:sort.value};
            const response = await apiHistorique.getGameHistorique(data);
            // console.log(response.historiqueGame);
            resolve({
				data: response.historiqueGame,
				page:response.page,
				totalCount: response.total,
            });
        } catch (e){
            // console.log(e)
            setError(e.message);
        }
    }
    const convertDate = (date, format) => {
		if (date === null) {return;}
		moment.locale("fr");
		return moment(date).format(format);
	}
    const capitalize = (s) => {
	  	if (typeof s !== 'string') return ''
	  	return s.charAt(0).toUpperCase() + s.slice(1)
	}
	const updateData = ()=> {
		if(!startDate || !endDate) {
    		setError("Vous devez choisir une date début et de fin.");
    		return;
    	}
    	if (startDate.getTime() >= endDate.getTime()) {
    		setError("Vous devez choisir une date début supérieur à celle de fin.");
    		return;
    	}
		if (showData) {
			if (tableRef.current) {
				tableRef.current.onQueryChange();
				setError(null);
			}
		} else {
			setShowData(true);
			setError(null);
		}
	}
	
	return(
		<div>
			<div>
				<ErrorContainer>
				{(error && typeof(error) === "string") && (
					<ErrorText>
						{error}
					</ErrorText>
				)}
				</ErrorContainer>
				<SearchContainer>
					<SelectDate startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate}/>
					<div style={{width:200}}>
					<Select placeholder="Select game" onChange={onSelect} style={{marginTop:20,borderRadius:15}}>
						{gameNames.map((gameName,i)=>{
							return(
					  			<option value={gameName} key={i}>{capitalize(gameName)}</option>
							);
						})}
					</Select>
					</div>
					<ButtonSearch onClick={() => updateData()}>
						<ButtonText>Search</ButtonText>
					</ButtonSearch>
				</SearchContainer>
			</div>
			{showData &&(
				<div style={{overflow:"none",margin:50}}>
					<MaterialTable
						tableRef={tableRef}
						title="Historique Game"
						columns={tableData.columns}
						data={query =>
				          	new Promise((resolve, reject) => {
				            	getGameHistorique(resolve,query);
				          	})
				        }
				        options={{
				          	search: false,
				          	pageSize:10,
				          	pageSizeOptions:[5,10,25,50,100]
				        }}
					/>
				</div>
			)}
		</div>
	);
}

export default HistoriqueGame;