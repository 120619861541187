import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { useAuth } from "./contextes/provider";

const PrivateRoute = ({ component: Component }) => {
  const {isLoggedIn} = useAuth();
  return (
    <Route
      render={props =>
        isLoggedIn() ? (
          <Component {...props}/>
        ) : (
          <Redirect to={{ pathname: '/login', state: { from: props.path } }} />
        )
      }
    />
  )
}

export default PrivateRoute