import React, {useState,useEffect} from 'react';
import * as apiUser from '../../services/user';
import {
    ContainerSearch,
    ButtonSearch,
    ButtonText,
    ContainerChild,
} from'../Dashboard/styles';
import {
    Container,
    TitleText,
    NoDataText
} from './styles';
import {
    Grid
} from '@material-ui/core';
import SelectDate from '../../components/SelectDate';
import LineChart from '../../components/LineChart';
import moment from "moment";
import {optionsArea} from '../Dashboard/options';
const InscritDetail = () => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [inscritVerifStatsGraph,setInscritVerifStatsGraph] = useState([]);
    const [inscritNonVerifStatsGraph,setInscritNonVerifStatsGraph] = useState([]);
    
    const [inscritSponsoVerifStatsGraph,setInscritSponsoVerifStatsGraph] = useState([]);
    const [inscritSponsoNotVerifStatsGraph,setInscritSponsoNotVerifStatsGraph] = useState([]);

    const [originRegisterVerified,setOriginRegisterVerified] = useState([]);
    const [originRegisterNotVerified,setOriginRegisterNotVerified] = useState([]);

    const [urlRegisterVerified,setUrlRegisterVerified] = useState([]);
    const [urlRegisterNotVerified,setUrlRegisterNotVerified] = useState([]);

    const convertDate = (date, format) => {
        if (date === null) {return;}
        return moment(date).format(format);
    }
    const getDetail = async (start,end) => {
        try {
            if (!start && !end) {
                return;
            }
            const response = await apiUser.getGlobalInscritStats(start,end);
            newStats(response.globalStat);
        } catch(e) {
            console.log(e);
        }
    }

    const capitalize = (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    const typeDevice = ["browser","mobile","ios","android"]
    let compareMobile = ["mobile","tablet","wearable"];
    const getValueByType = (data,isSponsored) => {
        let stats = {
            browser:{total:0,sponsored:0},
            mobile:{total:0,sponsored:0},
            ios:{total:0,sponsored:0},
            android:{total:0,sponsored:0},
        };
        if (!data) return null;
        for (var i = 0; i < data.length; i++) {
            let typeInscrit = data[i].typeInscrit;
            if(!typeDevice.includes(typeInscrit)){
                if(compareMobile.includes(typeInscrit)){
                    typeInscrit = "mobile";
                } else {
                    typeInscrit = "browser";
                }
            }
            stats[typeInscrit].total += data[i].nbInscrit;
            stats[typeInscrit].sponsored += data[i].nbSponsored;
        }
        return stats;
    }
    const getCumulData = (data) => {
        let total = 0;
        for (const key in data) {
            total += data[key].total;
        }
        return total;
    }
    const getCumulSponso = (data) => {
        let total = 0;
        for (const key in data) {
            total += data[key].sponsored;
        }
        return total;
    }
    
    let configRegister = JSON.stringify({
        browser:{text:"Desktop",total:0,series:[],labels:[]},
        mobile:{text:"Web Mobile",total:0,series:[],labels:[]},
        ios:{text:"iOS",total:0,series:[],labels:[]},
        android:{text:"Android",total:0,series:[],labels:[]},
    });

    const updateData = (data,newStats,day) => {
        if (!newStats) return data;
        for(const key in data){
            if (!data[key]) continue;
            data[key].total += newStats[key].total;
            data[key].series.push(newStats[key].total);
            data[key].labels.push(day);
        }

        return data;
    }
    

    const updateDataOriginRegister = (data,stats,day) => {
        // let haveData = false
        if (!stats) return data;
        let dayRegister = {};
        for (var i = 0; i < stats.length; i++) {
            if (!stats[i].originRegister) continue;
            // if (stats[i].originRegister.length > 0) {haveData = true;}
            for (var j = 0; j < stats[i].originRegister.length; j++) {
                let key = stats[i].originRegister[j].name;
                let nbRegister = stats[i].originRegister[j].nbRegister;
                if (dayRegister[key]) {
                    dayRegister[key] += nbRegister;
                } else {
                    dayRegister[key] = nbRegister;
                }
            }
        }
        for(const property in dayRegister){
            if (data[property]) {
                data[property].total += dayRegister[property];
                data[property].series.push(dayRegister[property]);
                data[property].labels.push(day);
            } else {
                data[property] = {text:capitalize(property),total:dayRegister[property],series:[dayRegister[property]],labels:[day]};
            }
        }
        return data;
    }

    const updateDataUrlRegister = (data,stats,day) => {
        // let haveData = false
        if (!stats) return data;
        let dayRegister = {};
        for (var i = 0; i < stats.length; i++) {
            if (!stats[i].urlRegister) continue;
            // if (stats[i].urlRegister.length > 0) {haveData = true;}
            for (var j = 0; j < stats[i].urlRegister.length; j++) {
                let key = stats[i].urlRegister[j].name;
                let nbRegister = stats[i].urlRegister[j].nbRegister;
                if (dayRegister[key]) {
                    dayRegister[key] += nbRegister;
                } else {
                    dayRegister[key] = nbRegister;
                }
            }
        }
        for(const property in dayRegister){
            if (data[property]) {
                data[property].total += dayRegister[property];
                data[property].series.push(dayRegister[property]);
                data[property].labels.push(day);
            } else {
                data[property] = {text:capitalize(property),total:dayRegister[property],series:[dayRegister[property]],labels:[day]};
            }
        }
        return data;
    }

    const updateDataSponsored = (data,sponsored,day) => {
        if(!sponsored) return data;
        for(const key in data){
            let total = sponsored[key].sponsored;
            data[key].total += total;
            data[key].series.push(total);
            data[key].labels.push(day);
        }
        return data;
    }

    const updateStats = (stats,playerStats,day) => {
        let totalPlay = getCumulData(playerStats);
        stats.total += totalPlay;
        stats.series.push(totalPlay);
        stats.data = updateData(stats.data,playerStats,day);
        stats.labels.push(day);
        return stats;
    }

    const updateStatsSponso = (stats,playerStats,day) => {
        let totalPlay = getCumulSponso(playerStats);
        stats.total += totalPlay;
        stats.series.push(totalPlay);
        stats.data = updateDataSponsored(stats.data,playerStats,day);
        stats.labels.push(day);
        return stats;
    }

    let ignoreStatsGraph = ["originRegisterVerified","originRegisterNotVerified","urlRegisterVerified","urlRegisterNotVerified"];
    const newStats = async (data) => {
        let config = {
            statsInscritVerif:{text:"Total",series:[],labels:[],total:0,data:JSON.parse(configRegister)},
            statsInscritNotVerif:{text:"Total",series:[],labels:[],total:0,data:JSON.parse(configRegister)},
            statsSponsoVerif:{text:"Total",series:[],labels:[],total:0,data:JSON.parse(configRegister)},
            statsSponsoNotVerif:{text:"Total",series:[],labels:[],total:0,data:JSON.parse(configRegister)},
            originRegisterVerified:{data:{}},
            originRegisterNotVerified:{data:{}},
            urlRegisterVerified:{data:{}},
            urlRegisterNotVerified:{data:{}},
        };

        let graphInscrit = {};
        for (var i = 0; i < data.length; i++) {
            let playerStatsVerified = getValueByType(data[i]["playerStatsVerified"]);
            let playerStatsNotVerified = getValueByType(data[i]["playerStatsNotVerified"]);
            let day = convertDate(data[i].day,"DD MMM");
            config.statsInscritVerif = updateStats(config.statsInscritVerif,playerStatsVerified,day);
            config.statsInscritNotVerif = updateStats(config.statsInscritNotVerif,playerStatsNotVerified,day);
            
            config.statsSponsoVerif = updateStatsSponso(config.statsSponsoVerif,playerStatsVerified,day);
            config.statsInscritNotVerif = updateStatsSponso(config.statsInscritNotVerif,playerStatsNotVerified,day);

            config.originRegisterVerified.data = updateDataOriginRegister(config.originRegisterVerified.data,data[i]["playerStatsVerified"],day);
            config.originRegisterNotVerified.data = updateDataOriginRegister(config.originRegisterNotVerified.data,data[i]["playerStatsNotVerified"],day);

            config.urlRegisterVerified.data = updateDataUrlRegister(config.urlRegisterVerified.data,data[i]["playerStatsVerified"],day);
            config.urlRegisterNotVerified.data = updateDataUrlRegister(config.urlRegisterNotVerified.data,data[i]["playerStatsNotVerified"],day);
        }
        
        for (const key in config){
            let graph = [];
            if (!ignoreStatsGraph.includes(key)) {
                graph.push(newGraph(config[key],config[key].name));
            }
            for (const property in config[key].data){
                graph.push(newGraph(config[key].data[property],property));
            }
            graphInscrit[key] = graph;
        }
        
        setInscritVerifStatsGraph(graphInscrit.statsInscritVerif);
        setInscritNonVerifStatsGraph(graphInscrit.statsInscritNotVerif);

        setInscritSponsoVerifStatsGraph(graphInscrit.statsSponsoVerif);
        setInscritSponsoNotVerifStatsGraph(graphInscrit.statsSponsoNotVerif);

        setOriginRegisterVerified(graphInscrit.originRegisterVerified);
        setOriginRegisterNotVerified(graphInscrit.originRegisterNotVerified);

        setUrlRegisterVerified(graphInscrit.urlRegisterVerified);
        setUrlRegisterNotVerified(graphInscrit.urlRegisterNotVerified);
    }

    const newGraph = (statsData,key) => {
        let options = JSON.parse(optionsArea);
        options.title.text = statsData.total;
        options.subtitle.text = statsData.text;
        if (statsData.labels.length > 31) {
            statsData.labels = statsData.labels.slice(statsData.labels.length-31);
        }
        options.labels = statsData.labels;
        return {
            name:key,
            options:options,
            series:[
                {
                    name:"value",
                    data:(statsData.series.length < 31)?statsData.series:statsData.series.slice(statsData.series.length-31)
                }
            ]
        };
    }
    useEffect(()=> {
        let end = new Date();
        end.setHours(23,59,59,59);
        getDetail(new Date("1970"),end);
    },[])
    return (
        <div>
            <ContainerSearch>
                <SelectDate startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate}/>
                <ButtonSearch onClick={() => getDetail(startDate,endDate)}>
                    <ButtonText>Search</ButtonText>
                </ButtonSearch>
            </ContainerSearch>

            <ContainerGraph title="Joueurs inscrits verifiés" statsGraph={inscritVerifStatsGraph}/>
            <ContainerGraph title="Joueurs inscrits non verifiés" statsGraph={inscritNonVerifStatsGraph}/>

            <ContainerGraph title="Inscription vérifié avec un code parrain" statsGraph={inscritSponsoVerifStatsGraph}/>
            <ContainerGraph title="Inscription non vérifié avec un code parrain" statsGraph={inscritSponsoNotVerifStatsGraph}/>

            <ContainerGraph title="Origine joueurs verifiées" statsGraph={originRegisterVerified}/>
            <ContainerGraph title="Origine joueurs non verifiées" statsGraph={originRegisterNotVerified}/>

            <ContainerGraph title="Url joueurs verifiées" statsGraph={urlRegisterVerified}/>
            <ContainerGraph title="Url joueurs non verifiées" statsGraph={urlRegisterNotVerified}/>
        </div>
    )
};

const ContainerGraph = ({title,statsGraph}) => {
    if (!statsGraph || statsGraph.length === 0) {
        return (
            <Container>
                <TitleText>{title}</TitleText>
                <NoDataText>Pas de donnée</NoDataText>
            </Container>
        );
    } else {
        return (
            <Container>
                <TitleText>{title}</TitleText>
                <Grid container>
                    {statsGraph.map((graph,key)=> {
                        return (
                            <ContainerChild key={key}>
                                <LineChart dataChart={graph}/>
                            </ContainerChild>
                        );
                    })}
                </Grid>
            </Container>
        );
    }
}

export default InscritDetail;