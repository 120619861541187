import styled from 'styled-components';
import {
	Box,
	Text,
} from "@chakra-ui/core";
/* STYLED COMPONENTS */
export const ButtonSearch = styled(Box)`
	padding: 10px 10px;
	margin-top:17px;
	margin-left:10px;
	border-radius:20px;
	cursor:pointer;
	background-image: linear-gradient(135deg, #0093FF 0%, #00DEFF 100%);
	height:40px;
`;
export const ButtonText = styled(Text)`
	font-family: WorkSans;
	text-transform: uppercase;
	color:#fff;
	align-items:center;
`;
export const SearchContainer = styled(Box)`
	width: 50%;
	margin:auto;
	display:flex;
	justify-content:space-evenly;
`;


