import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Header from "../Header/index";
import { Container } from "./styles";
import Users from '../Users';
import UserDetail from '../UserDetail';
import GameDetail from '../GameDetail';
import GameHistorique from '../GameHistorique';
import Cashout from '../Cashout';
import NotFound from '../NotFound';
import Dashboard from '../Dashboard';
import Parrainage from '../Parrainage';
import Alert from '../Alert';
import UsersBan from '../UsersBan';
import CGU from '../CGU';
import MailingScreen from '../Mail';
import InscritDetail from '../InscritDetail';
import Extract from '../Extract';
import UpdateData from '../UpdateData';
import GoKado from '../GoKado';
import GoKadoDetails from '../GoKadoDetails';
import Retrib from '../Retrib';
import * as apiStat from '../../services/globalgamestat';
import { optionsDonut, optionsArea } from '../Dashboard/options';
import moment from "moment";
import * as apiUser from '../../services/user';
import { useAuth } from "../../contextes/provider";
import UsersAdmin from '../UsersAdmin';
import DeleteUser from '../DeleteUser';
import SponsorUser from '../Sponsor';
import Signalement from '../Signalement';
export const gameNames = {
    battle: "Battle Mojo",
    pop: "Mojo Pop",
    loco: "Mojo Loco",
    golucky: "Go Lucky",
    goloto: "Go Mojo",
    gocash: "Go 100",
    power: "Power Mojo",
    scope: "Mojo Scope",
    keep: "Keep the Mojo",
    land:"Mojo Land",
    go50:"Go 50",
    go1000:"Go 1000",
    viking:"Viking",
    mojowin:"Mojo Win",
    chess:"Mojo Win Chess",
    newyear: "Mojo Win New Year",
    jungle:"Mojo Win Jungle",
    fairground: "Mojo Win Fairground",
    slot: "Mojo Slot",
    space: "Mojo Win Space",
    seventies: "Mojo Win Seventies",
    zeus: "Mojo Win Zeus",
    egypt: "Mojo Win Egypt",
};
const statsTypeStruct = JSON.stringify({
    mise: { text: "Balles misées", total: 0, series: [] },
    credit: { text: "Balles gagnées", total: 0, series: [] },
    solde: { text: "Euros gagnés", total: 0, series: [] },
    playerStatsVerified: { text: "Joueurs inscrits verifié", total: 0, series: [] },
    // playerStatsNotVerified: { text: "Joueurs inscrits non verifié", total: 0, series: [] },
    // playerActif: { text: "Joueurs actifs (jour)", total: 0, series: [] },
    // pubs: { text: "Pubs visionnées", total: 0, series: [] },
    // newSponsor: { text: "Codes parrainages envoyés", total: 0, series: [] },
    newFilleul: { text: "Parrainages acceptés", total: 0, series: [] },
    // session: { text: "Connexions", total: 0, series: [] },
    share: { text: "Partages", total: 0, series: [] },
    total: { text: "Parties jouées", total: 0, series: [] },
    nbWin: { text: "Victoires", total: 0, series: [] },
    nbLoose: { text: "Défaites", total: 0, series: [] },
    // nbGameLv1:{text:"Jeux mojo",total:0,series:[]},
    // nbGameLv2:{text:"Jeux go",total:0,series:[]},
    // durationSession: { text: "Temps session (total)", total: 0, series: [] },
    // durationSessionAvg: { text: "Temps session (moyenne)", total: 0, series: [] },
});
const statsTypeGameStruct = JSON.stringify({
    mise: { text: "Balles misées", total: 0, series: [] },
    credit: { text: "Balles gagnées", total: 0, series: [] },
    solde: { text: "Euros gagnés", total: 0, series: [] },
    total: { text: "Parties jouées", total: 0, series: [] },
    nbWin: { text: "Victoires", total: 0, series: [] },
    nbLoose: { text: "Défaites", total: 0, series: [] },
});
function Home() {
    const history = useHistory();
    const [displayScreen, setDisplayScreen] = useState("ACCUEIL");
    const { state } = useAuth();
    const user = state.user;
    const pageLocation = history.location.hash.substring(1).toLocaleUpperCase();
    useEffect(() => {
        if (pageLocation !== displayScreen) {
            setDisplayScreen(pageLocation);
        } else if (pageLocation.length === 0) {
            setDisplayScreen('ACCUEIL');
            history.replace('/Home#accueil');
        }
    }, [pageLocation]);
    const [statsDate, setStatsDate] = useState({ start: new Date("1970"), end: new Date() });
    const [globalStatsGraph, setGlobalStatsGraph] = useState(null);
    const getGlobalStats = async (start, end) => {
        try {
            const response = await apiUser.getUsersGlobalStats(start, end);
            createStatsActif(response.globalStat, response.globalStatDay);
        } catch (e) {
            console.log(e)
        }
    }

    const getValuePlayerStats = (playerStats) => {
        let total = 0;
        for (var i = 0; i < playerStats.length; i++) {
            total += playerStats[i].nbInscrit;
        }
        return total;
    }
    const createStatsActif = (data) => {
        const statsType = JSON.parse(statsTypeStruct);
        let statsGraph = [];
        let labels = [];
        for (var i = 0; i < data.length; i++) {
            for (const property in data[i]) {
                if (statsType[property]) {
                    if (['playerStatsVerified', 'playerStatsNotVerified'].includes(property)) {
                        let total = getValuePlayerStats(data[i][property]);
                        statsType[property].total += total;
                        statsType[property].series.push(total);
                    } else {
                        statsType[property].total += data[i][property];
                        statsType[property].series.push(data[i][property]);
                    }
                }
            }
            labels.push(convertDate(data[i].day, "DD MMM"));
        }
        if (labels.length > 31) {
            labels = labels.slice(labels.length - 31);
        }
        getInfoGamePlay(statsType.nbWin.total, statsType.nbLoose.total);
        for (const property in statsType) {
            let options = JSON.parse(optionsArea);
            if (property === "solde") {
                options.title.text = Number(statsType[property].total.toFixed(2));
            } else {
                options.title.text = statsType[property].total;
            }
            options.subtitle.text = statsType[property].text;
            options.labels = labels;
            statsGraph.push({ name: property, options: options, series: [{ name: "value", data: (statsType[property].series.length < 31) ? statsType[property].series : statsType[property].series.slice(statsType[property].series.length - 31) }] });
        }

        setGlobalStatsGraph(JSON.stringify(statsGraph));
    }

    const convertDate = (date, format) => {
        if (date === null) { return; }
        return moment(date).format(format);
    }

    const [donutLooseWin, setDonutLooseWin] = useState(null);
    const getInfoGamePlay = async (nbWin, nbLoose) => {
        try {
            let series = [nbWin, nbLoose];
            let options = JSON.parse(optionsDonut);
            options.title.text = "Parties jouées";
            options.labels = ['Victoire', 'Défaite'];
            options.colors = ["#4299E1", "#E53E3E"];
            setDonutLooseWin(JSON.stringify({ options: options, series: series }));
        } catch (e) {
            console.log(e)
        }
    }
    const [infoGameGen, setInfoGameGen] = useState(null);
    const getStatsGameGeneral = async () => {
        try {
            const response = await apiStat.getStatsGameGeneral();
            const statGeneral = response.globalGameStatGeneral
            newStatsGameGeneral(statGeneral);
        } catch (e) {
            console.log(e)
        }
    }

    const newStatsGameGeneral = async (statGeneral) => {
        let statsType = JSON.parse(statsTypeGameStruct)
        let labels = [];
        for (var i = 0; i < statGeneral.length; i++) {
            labels.push(gameNames[statGeneral[i].gameName]);
            for (const property in statGeneral[i]) {
                if (statsType[property]) {
                    statsType[property].total += statGeneral[i][property];
                    statsType[property].series.push(statGeneral[i][property]);
                }
            }
        }
        let colors = ["#673ab7", "#00bcd4", "#009688", "#4caf50", "#ffc107", "#9e9e9e", "#607d8b", "#f44336", "#ffeb3b"];
        let gameGenData = [];
        for (const property in statsType) {
            let options = JSON.parse(optionsDonut);
            options.title.text = statsType[property].text;
            options.labels = labels;
            options.colors = colors;
            gameGenData.push({ options: options, series: statsType[property].series });
        }
        setInfoGameGen(JSON.stringify(gameGenData));
    }
    useEffect(() => {
        let end = new Date();
        end.setHours(23, 59, 59, 59);
        getGlobalStats(new Date("1970"), end);
        getStatsGameGeneral();
    }, [])

    return (
        <React.Fragment>
            <Header />

            <Container>
                {
                    displayScreen === "ACCUEIL" || displayScreen === "" ? (
                        <Dashboard
                            infoGameGen={infoGameGen} setInfoGameGen={setInfoGameGen}
                            globalStatsGraph={globalStatsGraph}
                            donutLooseWin={donutLooseWin}
                            statsTypeStruct={statsTypeStruct}
                            statsTypeGameStruct={statsTypeGameStruct}
                            newStatsGameGeneral={newStatsGameGeneral}
                        />
                    ) :
                        displayScreen === "USERS" ? (<Users />) :
                        displayScreen === "USERDETAIL" ? (<UserDetail />) :
                        displayScreen === "INSCRITDETAIL" ? (<InscritDetail />) :
                        displayScreen === "GAMEDETAIL" ? (<GameDetail />) :
                        displayScreen === "GAMEHISTORIQUE" ? (<GameHistorique />) :
                        displayScreen === "CASHOUT" ? (<Cashout />) :
                        displayScreen === "PARRAINAGE" && user.role !== "members" ? (<Parrainage />) :
                        displayScreen === "ALERT" ? (<Alert />) :
                        displayScreen === "USERSBAN" && user.role !== "members" ? (<UsersBan />) :
                        displayScreen === "CGU" ? (<CGU />) :
                        displayScreen === "USERSADMIN" && user.role !== "members" ? (<UsersAdmin />) :
                        displayScreen === "MAIL" && user.role !== "members" ? (<MailingScreen />) :
                        displayScreen === "GOKADO" && user.role !== "members" ? (<GoKado />) :
                        displayScreen === "GOKADODETAILS" && user.role !== "members" ? (<GoKadoDetails />) :
                        displayScreen === "EXTRACT" && user.role !== "members" ? (<Extract />) :
                        displayScreen === "USERDELETE" ? (<DeleteUser />) :
                        displayScreen === "SPONSOR" ? (<SponsorUser />) :
                        displayScreen === "SIGNALEMENT" ? (<Signalement />) :
                        displayScreen === "RETRIB" && user.role !== "members" ? (<Retrib />) : (<NotFound />)
                }

            </Container>

        </React.Fragment>
    );
}

export default Home;