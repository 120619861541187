const filterValidate = (filter, subfilter) => {
        const set = {
            $and: [{ cardIdentityURL: { $exists: true } },{ ribURL: { $exists: true } }, { iban: { $exists: true } }, { lastname: { $exists: true } }, { firstname: { $exists: true } }, subfilter.includes('id2') ? { $and : [{cardIdentityVersoURL: { $exists: true }}, {cardIdentityVersoURL: { $ne: '' }}, {cardIdentityVersoURL: { $ne: null}} ]} : { cardIdentityURL: { $ne: '' }} ],
            ibanlt : { $lt: [{ $strLenCP: "$iban" }, 27]},
            ibangt : { $gt: [{ $strLenCP: "$iban" }, 27]},
            done: {redcheck: { $eq : true }, isOR: false, ope: { $eq: 'validate' }, isIban: 'ibangt', subfilter : { isOR: false, ope: { $eq: 'validate' }, isIban: 'ibanlt', iban: false, rib: { ribURL : { $eq : ''}}, id: { cardIdentityURL : { $eq : ''}}, id2: { cardIdentityURL : { $eq : ''}} }},
            pending: {redcheck: { $eq : true }, isOR: true, ope : { $ne : 'validate' }, isIban: 'ibangt', subfilter : { isOR: false, ope: { $eq: 'validate' }, isIban: 'ibangt', iban : { $ne : 'validate' }, rib : { $ne : 'validate' }, id : { $ne : 'validate' }, id2: { $ne : 'validate' } } },
            new: {redcheck: { $ne : true }, isOR: true, ope : { $ne : 'validate' }, isIban: 'ibangt' },
        }

        let filterValidate = { $and : set.$and };
        let filterStatus = { id: { statusCardIdentity : {} }, id2: { statusCardIdentityVerso : {} }, iban: { statusIban : {} }, rib: { statusRib : {} } };
        const isSubFilter = subfilter.length > 0 ? true : false;
        let $expr = isSubFilter ? set[set[filter].subfilter.isIban] : set[set[filter].isIban];
        let isOR = isSubFilter ? set[filter].subfilter.isOR : set[filter].isOR;
        let orIndex = 0;

        filterValidate.$and.push({ $expr }, { statusRedcheck: set[filter].redcheck });
        if (isOR) orIndex = filterValidate.$and.push( {$or: []} );

        Object.keys(filterStatus).map( (s) => {
            let status_ = filterStatus[s];
            status_[Object.keys(filterStatus[s])[0]] = (isSubFilter && subfilter.includes(s) )? set[filter].subfilter[s] : isSubFilter ? set[filter].subfilter.ope : set[filter].ope;
            if (isOR)filterValidate.$and[orIndex-1].$or.push(status_);
            else filterValidate.$and.push(status_);
        });
        //filterValidate = {$and: [ { cardIdentityURL: { $exists: true } }, { cardIdentityVersoURL: { $exists: true } }, {$where: "this.cardIdentityURL == this.cardIdentityVersoURL"} ]}
        return filterValidate;
}

export default filterValidate;