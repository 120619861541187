import React from "react";
import Chart from "react-apexcharts";

import styled from 'styled-components';

const DonutChart = ({options,series}) => {
	if (options && series) {
		return (
			<ChartContainer>
				<Chart
					options={options}
					series={series}
					type="donut"
					height={options.chart.height}
				/>
			</ChartContainer>
		);
	} else return null;
}

export default DonutChart;

const ChartContainer = styled.div`
	width: 100%;
	background-color:#fff;
	box-shadow:0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
`